import { Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";

import { useFlag } from "../../utils";
import type { CreateProject_viewer$key } from "../../__generated__/CreateProject_viewer.graphql";
import ProjectDetailsPage from "../Configure/ProjectDetailsPage";

const { Title } = Typography;

const CreateProject = ({
  languages,
  privacyPolicyOptions,
  viewer: viewerKey,
}: {
  languages: any[];
  privacyPolicyOptions: any[];
  viewer: CreateProject_viewer$key;
}) => {
  const ffHasTopNavEnabled = useFlag("hub-top-nav");
  const viewer = useFragment(
    graphql`
      fragment CreateProject_viewer on Viewer {
        user {
          profile {
            tenant {
              ...ProjectDetailsPage_tenant
            }
          }
        }
        ...ProjectDetailsPage_viewer
      }
    `,
    viewerKey
  );

  return (
    <ProjectDetailsPage
      isSettingsPage={false}
      languages={languages}
      privacyPolicyOptions={privacyPolicyOptions}
      study={null}
      tenant={viewer.user?.profile?.tenant || null}
      title={
        <Title level={2} className="create-project-title">
          Create new {ffHasTopNavEnabled ? "schedule" : "project"}
        </Title>
      }
      viewer={viewer}
    />
  );
};

export default CreateProject;
