import { App, Button, Checkbox, Form, Input, InputNumber, Radio, Select, Switch, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import {
  INCENTIVE_FEE_PERCENT,
  MIN_INCENTIVE_POINTS,
  MIN_INCENTIVE_USD,
  PROJECT_INCENTIVE_TYPES,
  RECRUIT_STATUSES,
} from "../../constants";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { getAuthorizationHeader, mutation, useFlag } from "../../utils";
import {
  calculateParticipantCost,
  calculateProjectCost,
  getApiBaseUrl,
  ignoreWheel,
  usdFormatterNeat,
} from "../../utils/misc";
import { RecruitForm_recruit$data } from "../../__generated__/RecruitForm_recruit.graphql";
import { RecruitForm_study$data } from "../../__generated__/RecruitForm_study.graphql";
import { RecruitForm_UpdateRecruitDetails_Mutation } from "../../__generated__/RecruitForm_UpdateRecruitDetails_Mutation.graphql";
import { UpdateRecruitDetailsInput } from "../../__generated__/RecruitNameModal_updateRecruitDetails_Mutation.graphql";
import { ScreenersRecruitIncentiveTypeChoices } from "../../__generated__/RecruitPaymentModal_recruit.graphql";
import { InputPoints } from "../Configure/InputPoints";
import IntercomLink from "../IntercomLink";

import { InputWrapper } from "./InputWrapper";

import { RadioChangeEvent } from "antd/lib/radio";
import axios from "axios";
import { debounce } from "lodash-es";
import eventEmitter from "../../utils/eventEmitter";

const { TextArea } = Input;

type Props = {
  recruit: RecruitForm_recruit$data;
  study: RecruitForm_study$data;
  startRecruit: (paid: boolean, formValues: UpdateRecruitDetailsInput) => void;
};
const RecruitForm = ({ recruit, study, startRecruit }: Props) => {
  const { message } = App.useApp();
  const ffHasRecruitChanges = useFlag("hub-recruit-page-changes-21-01-25");

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [costPerParticipant, setCostPerParticipant] = useState<number>(() => {
    let recruitIncentive = 0;
    if (study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS && recruit.incentivePoints !== null) {
      recruitIncentive = recruit.incentivePoints;
    } else if (recruit.incentive !== null) {
      recruitIncentive = parseFloat(recruit.incentive);
    }

    if (study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL || !!recruitIncentive) {
      return calculateParticipantCost(
        study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL
          ? study.tenant.externalIncentivePerParticipantCostUsdCents / 100
          : recruitIncentive,
        study.incentiveType,
        recruit.type,
        study.tenant.externalIncentivePerParticipantCostUsdCents
      );
    } else {
      return 0;
    }
  });

  const [estimatedTotal, setEstimatedTotal] = useState<number>(() => {
    let recruitIncentive = 0;
    if (study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS && recruit.incentivePoints !== null) {
      recruitIncentive = recruit.incentivePoints;
    } else if (recruit.incentive !== null) {
      recruitIncentive = parseFloat(recruit.incentive);
    }

    if (recruitIncentive && recruit.goal) {
      return calculateProjectCost(
        recruitIncentive,
        study.incentiveType,
        recruit.goal,
        recruit.type!,
        study.tenant.externalIncentivePerParticipantCostUsdCents
      );
    } else {
      return 0;
    }
  });

  const updateRound = (input: UpdateRecruitDetailsInput) =>
    mutation<RecruitForm_UpdateRecruitDetails_Mutation>({
      mutation: graphql`
        mutation RecruitForm_UpdateRecruitDetails_Mutation($input: UpdateRecruitDetailsInput!) {
          updateRecruitDetails(input: $input) {
            recruit {
              id
              name
              description
              goal
              incentive
              incentivePoints
              incentiveType
              remindersEnabled
            }
          }
        }
      `,
      variables: { input },
      optimisticResponse: {
        updateRecruitDetails: {
          recruit: {
            id: recruit.id,
            name: input.name ?? null,
            description: input.description ?? null,
            goal: input.goal ?? null,
            incentive: input.incentive ?? null,
            incentivePoints: input.incentivePoints ?? null,
            incentiveType: (input.incentiveType as ScreenersRecruitIncentiveTypeChoices) ?? null,
            remindersEnabled: input.remindersEnabled ?? false,
          },
        },
      },
    });

  const submitForm = async (values: any) => {
    try {
      setLoading(true);
      if (study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH && values.incentive !== null) {
        // replaces commas with decimal points for locales that use them
        values.incentive = values.incentive.replace(/,/, ".");
      }

      // Remove "no-gender-preference" from the form values
      if (values?.settings?.gender === "no-gender-preference") {
        delete values?.settings?.gender;
      }

      if (values?.settings?.b2b) {
        delete values?.settings?.b2b;
      }

      if (values?.settings) {
        values.settings = JSON.stringify(values?.settings);
      }

      // We only allow reminders for Your panel and List import recruits
      values.remindersEnabled = (
        [ScreenersRecruitTypeChoices.Yp, ScreenersRecruitTypeChoices.Em] as (typeof recruit)["type"][]
      ).includes(recruit.type)
        ? values.remindersEnabled
        : false;
      const recruitData = { recruitId: recruit.id, incentiveType: study.incentiveType, ...values };
      await updateRound(recruitData);

      setLoading(false);
      // For survey link and HubUX panel, which require no further config, we start the recruiting round straight away
      if (
        ([ScreenersRecruitTypeChoices.Wl, ScreenersRecruitTypeChoices.Hp] as (typeof recruit)["type"][]).includes(
          recruit.type
        )
      ) {
        startRecruit(false, recruitData);
      }
      eventEmitter.emit("formSubmitted", recruit.id);
    } catch (err: any) {
      if (err.message) {
        message.error(err.message);
      }
      setLoading(false);
    }
  };

  const calculateCosts = () => {
    const incentiveValue =
      study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
        ? form.getFieldValue("incentivePoints")
        : study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH
        ? parseFloat(form.getFieldValue("incentive"))
        : study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL
        ? study.tenant.externalIncentivePerParticipantCostUsdCents / 100
        : undefined;

    if (incentiveValue === undefined) {
      throw Error("unknown incentive type");
    }

    setEstimatedTotal(
      calculateProjectCost(
        incentiveValue,
        study.incentiveType,
        form.getFieldValue("goal"),
        recruit.type!,
        study.tenant.externalIncentivePerParticipantCostUsdCents
      )
    );

    setCostPerParticipant(
      calculateParticipantCost(
        incentiveValue,
        study.incentiveType,
        recruit.type!,
        study.tenant.externalIncentivePerParticipantCostUsdCents
      )
    );
  };

  const [respondentIOFormOptions, setRespondentIOFormOptions] = useState<any>(() => {});

  const [numOfCountries, setNumOfCountries] = useState<number>(0);

  const [numOfRecruits, setNumOfRecruits] = useState<number>(0);

  useEffect(() => {
    const getRespondentFormOptions = async () => {
      try {
        const response = await axios.request({
          method: "GET",
          url: `${getApiBaseUrl()}/get_ro_choices`,
          headers: getAuthorizationHeader(),
        });
        setRespondentIOFormOptions(response.data);
      } catch (error) {
        console.error("Failed to fetch respondent form options:", error);
      }
    };

    getRespondentFormOptions();
  }, []);

  const b2bOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const [selecteB2BbdOption, setSelectedB2BOption] = useState(false);

  const b2bChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedB2BOption(value);
  };

  const jobsAndIndustriesFetch = async (type: string, searchby: string, name: string) => {
    try {
      const response = await axios.request({
        method: "GET",
        url: `${getApiBaseUrl()}/get_bb_values?type=${type}&searchby=${searchby}&name=${name}&page=1&perPage=100`,
        headers: getAuthorizationHeader(),
      });
      return response.data.items;
    } catch (error) {
      console.error("Failed to fetch jobs and industries:", error);
      return [];
    }
  };
  const [jobsData, setJobsData] = useState([]);

  const handleJobTitleSearch = debounce(async (value: string) => {
    if (value.length < 3) {
      return;
    }
    const data = await jobsAndIndustriesFetch("job-titles", "contains", value);
    setJobsData(data);
  }, 300);

  const [industriesData, setIndustriesData] = useState([]);

  const handleIndustriesSearch = debounce(async (value: string) => {
    const data = await jobsAndIndustriesFetch("industries", "contains", value);
    setIndustriesData(data);
  }, 300);

  const participantCostTooltip = `Participant cost is the incentive you've specified plus a ${Math.trunc(
    INCENTIVE_FEE_PERCENT[recruit.type!] * 100
  )}% fee ($5 minimum)`;

  return recruit.type && recruit.status === RECRUIT_STATUSES.NOT_STARTED ? (
    <>
      {recruit.type === ScreenersRecruitTypeChoices.Yp && (
        <p>Launch your study to your own community that's in the community tab.</p>
      )}
      {recruit.type === ScreenersRecruitTypeChoices.Hp && <p>Hubux panel description here</p>}
      {recruit.type === ScreenersRecruitTypeChoices.Wl && (
        <p>
          Create a link for your survey and share it anywhere you'd like, emails, internally, your website, ads, etc.
        </p>
      )}
      {recruit.type === ScreenersRecruitTypeChoices.Em && (
        <p>Import a list of participants that you want to communicate with via excel or CSV.</p>
      )}
      {recruit.type === ScreenersRecruitTypeChoices.Ro && (
        <StyledDiv>
          <p className="panel-info-text">
            Help show your survey to the right people by filling out the additional Voxpopme Audience targeting
            information below. This will ensure your survey gets the fastest responses possible.
          </p>
        </StyledDiv>
      )}
      <StyledForm>
        {recruit.type === ScreenersRecruitTypeChoices.Yp && !study.tenant.enablePanelView ? (
          <NoPanel>
            <h1>Uhm. Seems like you don’t have a panel.</h1>
            <p>You don’t have a Panel registered.</p>
            <p>
              If you want to create a panel, please <IntercomLink text="chat with us" />.
            </p>
          </NoPanel>
        ) : (
          <Form
            layout="vertical"
            form={form}
            validateTrigger="onSubmit"
            onFinish={submitForm}
            initialValues={{
              name: study.namePublic ? study.namePublic : recruit.name,
              goal: recruit.goal,
              incentive: recruit.incentive ?? study.defaultIncentive,
              incentivePoints: recruit.incentivePoints ?? study.defaultIncentivePoints,
              remindersEnabled: recruit.remindersEnabled,
              description: study.screener?.description || "",
            }}
            className="recruit-form-form"
            requiredMark="optional"
          >
            <div className="recruit-form-text">
              <Form.Item
                name="name"
                label={
                  <div className="input-label form-item-label">
                    {recruit.type === ScreenersRecruitTypeChoices.Ro ? "Public Title" : "Public Name"}{" "}
                    <span className="max-topics">(max 45 characters)</span>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: `Please provide a recruiting round ${
                      recruit.type === ScreenersRecruitTypeChoices.Ro ? "title" : "name"
                    }`,
                  },
                  {
                    max: 45,
                    message: "Name should be less than 45 characters",
                  },
                ]}
              >
                <Input className="recruit-form-input" />
              </Form.Item>
              <Form.Item
                name="description"
                label={
                  <div className="input-label form-item-label">
                    Public Description <span className="max-topics">(max 300 characters)</span>
                  </div>
                }
                rules={[
                  {
                    required: recruit.type === ScreenersRecruitTypeChoices.Ro,
                    message: "Please provide a description",
                  },
                  {
                    max: 300,
                    message: "Description should be less than 300 characters",
                  },
                ]}
              >
                <TextArea rows={4} className="recruit-form-input" />
              </Form.Item>
            </div>
            {recruit.type === ScreenersRecruitTypeChoices.Ro && (
              <div className="recruit-form-options">
                <Form.Item name={["settings", "age"]} label="Age">
                  <Checkbox.Group
                    options={respondentIOFormOptions?.ageGroup?.map((age: any) => ({
                      value: age.code,
                      label: age.value,
                    }))}
                  />
                </Form.Item>
                <Form.Item name={["settings", "gender"]} label="Gender">
                  <Radio.Group>
                    {respondentIOFormOptions?.gender?.map((gender: any) => (
                      <Radio key={gender.weight} value={gender.code}>
                        {gender.value.charAt(0).toUpperCase() + gender.value.slice(1)}
                      </Radio>
                    ))}
                    <Radio key={15} value="no-gender-preference">
                      No gender preference
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name={["settings", "ethnicity"]} label="Ethnicity">
                  <Checkbox.Group
                    options={respondentIOFormOptions?.ethnicity?.map((e: any) => ({
                      value: e.code,
                      label: e.value,
                    }))}
                  />
                </Form.Item>
                <Form.Item label="Do you want to target by job title and industry?" required>
                  <Radio.Group
                    options={b2bOptions}
                    onChange={b2bChange}
                    value={selecteB2BbdOption}
                    optionType="button"
                    buttonStyle="solid"
                  ></Radio.Group>
                </Form.Item>
                {selecteB2BbdOption ? (
                  <>
                    <Form.Item
                      name={["settings", "jobTitles"]}
                      label={
                        <div className="form-item-label">
                          Job Titles <span className="max-topics">(maximum 10)</span>
                        </div>
                      }
                      rules={[
                        {
                          max: 10,
                          type: "array",
                          required: true,
                          message: "Please select up to 10 job titles",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={false}
                        onSearch={handleJobTitleSearch}
                        notFoundContent={null}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        options={(jobsData || []).map((job: any) => ({
                          value: job.value,
                          label: job.name,
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      name={["settings", "professionalIndustries"]}
                      label={
                        <div className="form-item-label">
                          Industries <span className="max-topics">(maximum 5)</span>
                        </div>
                      }
                      rules={[
                        {
                          max: 5,
                          type: "array",
                          required: true,
                          message: "Please select up to 5 industries",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={false}
                        onSearch={handleIndustriesSearch}
                        notFoundContent={null}
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        options={(industriesData || []).map((industry: any) => ({
                          value: industry.value,
                          label: industry.name,
                        }))}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    name={["settings", "topics"]}
                    label={
                      <div className="form-item-label">
                        Topics <span className="max-topics">(maximum 2)</span>
                      </div>
                    }
                    required
                    rules={[
                      {
                        max: 2,
                        type: "array",
                        required: true,
                        message: "Please select up to 2 topics",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      options={respondentIOFormOptions?.topics?.map((topic: any) => ({
                        value: topic.code,
                        label: topic.value,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label as any)?.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name={["settings", "country"]}
                  label="Country"
                  rules={[
                    {
                      max: 25,
                      min: 1,
                      type: "array",
                      required: true,
                      message: "Please select up to 25 countries",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    options={respondentIOFormOptions?.country?.map((country: any) => ({
                      value: country.code,
                      label: country.value,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label as any)?.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={selectedCountries => {
                      setNumOfCountries(selectedCountries.length);
                    }}
                  />
                </Form.Item>
                {numOfCountries > 1 ? (
                  <p
                    style={{
                      padding: "0",
                      marginTop: "-20px",
                      color: "#2d91e6",
                    }}
                  >
                    A separate recruiting round will be created for each country
                  </p>
                ) : null}
              </div>
            )}
            <div className="recruit-form-numbers">
              <Form.Item
                name="goal"
                label={
                  <div className="form-item-label">
                    Recruitmnt Goal {recruit.type === ScreenersRecruitTypeChoices.Ro && "per country"}{" "}
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please provide a goal",
                  },
                ]}
              >
                <Tooltip title="Recruitment goal is the number of approved participants you need. Once a round reaches its goal, it'll stop recruiting.">
                  <InputNumber
                    id="goal"
                    size="large"
                    type="number"
                    min={1}
                    onWheel={ignoreWheel}
                    className="recruit-form-number-input"
                    onChange={goal => {
                      form.setFieldsValue({ goal });
                      calculateCosts();
                      setNumOfRecruits(goal as any);
                    }}
                  />
                </Tooltip>
              </Form.Item>

              {study.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS ? (
                <Form.Item
                  name="incentivePoints"
                  label="Incentive"
                  tooltip={`${MIN_INCENTIVE_POINTS[recruit.type]}pts minimum or 0`}
                  rules={[
                    {
                      required: true,
                      message: "Please provide an incentive",
                    },
                    {
                      validator(_, value) {
                        if (value === 0 || value >= MIN_INCENTIVE_POINTS[recruit.type!]) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error(`${MIN_INCENTIVE_POINTS[recruit.type!]}pts minimum or 0`));
                        }
                      },
                    },
                    {
                      validator(_, value) {
                        if (Number.isInteger(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error("Points must be a whole number"));
                        }
                      },
                    },
                  ]}
                >
                  <div style={{ minWidth: 150, maxWidth: 300 }}>
                    <InputPoints
                      form={form}
                      showCaptions={false}
                      pointsFieldName="incentivePoints"
                      inputClassName="recruit-form-number-input"
                      onChange={calculateCosts}
                      defaultPoints={study.defaultIncentivePoints ?? undefined}
                    />
                  </div>
                </Form.Item>
              ) : study.incentiveType === PROJECT_INCENTIVE_TYPES.CASH ? (
                <Form.Item
                  name="incentive"
                  label="Incentive"
                  tooltip={`${usdFormatterNeat.format(MIN_INCENTIVE_USD[recruit.type])} minimum or 0`}
                  rules={[
                    {
                      required: true,
                      message: "Please provide an incentive",
                    },
                    {
                      validator(_, value) {
                        if (parseFloat(value) === 0 || value >= MIN_INCENTIVE_USD[recruit.type!]) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(`${usdFormatterNeat.format(MIN_INCENTIVE_USD[recruit.type!])} minimum or 0`)
                          );
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    type="number"
                    min={0}
                    precision={2}
                    stringMode={true}
                    onWheel={ignoreWheel}
                    onChange={calculateCosts}
                    className="recruit-form-number-input"
                  />
                </Form.Item>
              ) : study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL ? (
                <InputWrapper title="Base cost" tooltip={participantCostTooltip}>
                  <InputNumber
                    size="large"
                    disabled
                    formatter={value =>
                      usdFormatterNeat.format(value ?? study.tenant.externalIncentivePerParticipantCostUsdCents / 100)
                    }
                    value={study.tenant.externalIncentivePerParticipantCostUsdCents / 100}
                  />
                </InputWrapper>
              ) : null}
              {study.tenant.isSelfSignup ? (
                <InputWrapper title="Cost per participant" tooltip={participantCostTooltip}>
                  <InputNumber
                    size="large"
                    disabled
                    formatter={value => usdFormatterNeat.format(value || 0)}
                    value={costPerParticipant}
                  />
                </InputWrapper>
              ) : null}
              {study.tenant.isSelfSignup ? (
                <InputWrapper title="Estimated total">
                  <InputNumber
                    size="large"
                    disabled
                    formatter={value => usdFormatterNeat.format(value || 0)}
                    value={estimatedTotal}
                  />
                </InputWrapper>
              ) : null}
            </div>
            {recruit.type === ScreenersRecruitTypeChoices.Ro && (
              <div className="recruit-form-number-display">
                <p className="countries-title">Number of countries: </p>
                <p className="countries-number">{numOfCountries}</p>
                <p className="recruits-title">Recruits per country: </p>
                <p className="recruits-number">{numOfRecruits}</p>
                <p className="total-title">Total recruits</p>
                <p className="total-number">{numOfCountries * numOfRecruits}</p>
              </div>
            )}
            <div className="recruit-form-options">
              {(
                [ScreenersRecruitTypeChoices.Yp, ScreenersRecruitTypeChoices.Em] as (typeof recruit)["type"][]
              ).includes(recruit.type) && (
                <Form.Item
                  name="remindersEnabled"
                  label={ffHasRecruitChanges ? "Send Additional Reminders" : "Enable Reminders"}
                  tooltip="Send reminder emails after 24 and 48 hours if the participant has not started the screener"
                  valuePropName="checked"
                  required
                >
                  <Switch />
                </Form.Item>
              )}
            </div>
            <div className="recruit-form-button-container">
              <Form.Item>
                <Button
                  className="recruit-form-submit-button"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  {(
                    [ScreenersRecruitTypeChoices.Wl, ScreenersRecruitTypeChoices.Hp] as (typeof recruit)["type"][]
                  ).includes(recruit.type)
                    ? "Start recruiting round"
                    : `Create recruiting round${numOfCountries > 1 ? "s" : ""}`}
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
      </StyledForm>

      {recruit.type === ScreenersRecruitTypeChoices.Ro && (
        <StyledDiv>
          <p className="panel-info-text">
            Communication customization is not available via the Voxpopme Audience Panel at this time. For information
            on the default messaging for the Voxpopme Audience Panel, contact{" "}
            <a href="mailto:support@voxpopme.com">support@voxpopme.com</a>
          </p>
        </StyledDiv>
      )}
    </>
  ) : null;
};

const StyledDiv = styled.div`
  margin-top: 1rem;
  font-size: 14px;
  color: #333;
  max-width: 900px;

  .panel-info-text {
    margin-bottom: 1rem;
  }
`;

const StyledForm = styled.div`
  margin-top: 3rem;
  font-size: 12px;
  font-weight: 500;
  max-width: 900px;

  .recruit-form-numbers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 16px;

    > * {
      flex: 1;
    }

    .recruit-form-number-input {
      width: 100%;
    }
  }

  .recruit-form-number-display {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: repeat(3, 1fr);
    width: 49%;
    padding: 12px;
    border-radius: 6px;
    background: #ffffff;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 20px;
    border: 1px solid lightgrey;
    cursor: auto;

    .countries-title {
      border-bottom: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      padding: 5px;
      grid-area: 1 / 1 / 2 / 2;
    }

    .countries-number {
      border-bottom: 1px solid lightgrey;
      display: flex;
      justify-content: end;
      padding: 5px;
      grid-area: 1 / 2 / 2 / 3;
    }

    .recruits-title {
      border-bottom: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      padding: 5px;
      grid-area: 2 / 1 / 3 / 2;
    }

    .recruits-number {
      border-bottom: 1px solid lightgrey;
      display: flex;
      justify-content: end;
      padding: 5px;
      grid-area: 2 / 2 / 3 / 3;
    }

    .total-title {
      border-right: 1px solid lightgrey;
      padding: 5px;
      font-weight: 800;
      grid-area: 3 / 1 / 4 / 2;
    }

    .total-number {
      display: flex;
      justify-content: end;
      padding: 5px;
      font-weight: 800;
      grid-area: 3 / 2 / 4 / 3;
    }

    p {
      margin: 0;
    }
  }

  .recruit-form-button-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .recruit-form-submit-button {
      width: 400px;
    }
  }

  .form-item-label {
    .max-topics {
      display: inline-block;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .ant-input-number-input {
    cursor: auto;
  }
`;

const NoPanel = styled.div`
  margin-top: 100px;
  text-align: center;
  font-size: 16px;
`;

export default createFragmentContainer(RecruitForm, {
  study: graphql`
    fragment RecruitForm_study on StudyNode {
      id
      defaultIncentive
      defaultIncentivePoints
      incentiveType
      name
      namePublic
      tenant {
        enablePanelView
        requireStudyPayment
        externalIncentivePerParticipantCostUsdCents
        isSelfSignup
      }
      screener {
        description
      }
    }
  `,
  recruit: graphql`
    fragment RecruitForm_recruit on RecruitNode {
      id
      name
      type
      status
      goal
      incentive
      incentivePoints
      remindersEnabled
    }
  `,
});
