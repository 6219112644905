import { Icon } from "@iconify/react";
import { Button, Modal, Tooltip } from "antd";
import classNames, { type Argument } from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { getAuthorizationHeader } from "../../utils";
import { getApiBaseUrl } from "../../utils/misc";
import Loading from "../Loading";

export const MeetingLinkDetails = ({
  className,
  disabled: _disabled = false,
  style,
  link,
  studyId,
  respondentId,
  showModalOnly = false,
  onClose,
}: {
  link?: string;
  studyId?: string;
  respondentId?: string;
  showModalOnly?: boolean;
  className?: Argument;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkDetails, setLinkDetails] = useState<any>(null);
  const [copied, setCopied] = useState<any>({});
  const [respondentDetails, setRespondentDetails] = useState<any>(null);

  const url = studyId
    ? `${getApiBaseUrl()}/link_analysis?link=${link}&study_id=${studyId}`
    : respondentId
    ? `${getApiBaseUrl()}/respondent_meeting_details?respondent_id=${respondentId}`
    : "";

  const getLinkDetails = async () => {
    try {
      const response = await axios.get(url, { headers: getAuthorizationHeader() });
      if (respondentId) setRespondentDetails(response.data);
      setLinkDetails(studyId ? response.data : response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalOpen = () => {
    setLoading(true);
    setModalVisible(true);
    getLinkDetails().then(() => setLoading(false));
  };

  const handleModalClose = () => {
    setCopied({});
    setModalVisible(false);
    if (onClose) onClose();
  };

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const linkCopiedTimeout = (linkType: string) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setCopied({ [linkType]: true });
    timerRef.current = setTimeout(() => {
      setCopied({ [linkType]: false });
    }, 3000);
  };

  useEffect(() => {
    if (showModalOnly) {
      handleModalOpen();
    }
  }, [showModalOnly]);

  return (
    <>
      {!showModalOnly && (
        <Tooltip
          getPopupContainer={trigger => trigger.parentElement ?? document.body}
          title="Link Details"
          overlayStyle={{ paddingBottom: 4 }}
        >
          <div className={classNames("hub-input-slot-details", className)} style={style}>
            <Button
              disabled={_disabled}
              style={{ padding: 4, marginTop: 4 }}
              type="text"
              onClick={() => handleModalOpen()}
            >
              <Icon icon="mdi:information-outline" height="1.5em" style={{ color: "var(--ant-primary-color)" }} />
            </Button>
          </div>
        </Tooltip>
      )}
      <StyledModal
        closable={true}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Done"
        onCancel={() => handleModalClose()}
        onOk={() => handleModalClose()}
        title=""
        open={modalVisible}
        width={800}
      >
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
            <Loading size="default" />
          </div>
        ) : (
          <div>
            <h2>{linkDetails?.type ? linkDetails?.type : "Meeting Link"}</h2>
            {respondentId && respondentDetails?.name && <h3>Participant: {respondentDetails?.name}</h3>}
            {linkDetails?.is_interview_room ? (
              <>
                {linkDetails &&
                linkDetails["Interview Room"] &&
                Object.keys(linkDetails["Interview Room"]).length > 0 ? (
                  <div className="ir-links-container">
                    <header>
                      Voxpopme Project:{" "}
                      <a href={linkDetails["Interview Room"]["Project Link"]}>
                        {linkDetails["Interview Room"]["Project Name"]}{" "}
                      </a>
                    </header>
                    <div className="link-container">
                      <header>Participant Link: </header>
                      <p>This is the link you should send out to your participants</p>
                      <div className="link-copy-container">
                        <span className="link">
                          <a href={linkDetails["Interview Room"]["Participant Link"]}>
                            {linkDetails["Interview Room"]["Participant Link"]}
                          </a>
                        </span>{" "}
                        <CopyToClipboard
                          text={linkDetails["Interview Room"]["Participant Link"]}
                          onCopy={() => linkCopiedTimeout("participant")}
                        >
                          <Icon
                            icon="mdi:content-copy"
                            height="1.5em"
                            style={{ color: "var(--ant-primary-color)", cursor: "pointer" }}
                          />
                        </CopyToClipboard>
                        {copied?.participant && <p className="success-message">Link copied</p>}
                      </div>
                    </div>
                    <div className="link-container">
                      <header>Moderator Link: </header>
                      <p>The moderators of the interview/focus group should use this link</p>
                      <div className="link-copy-container">
                        <span className="link">
                          <a href={linkDetails["Interview Room"]["Moderator Link"]}>
                            {linkDetails["Interview Room"]["Moderator Link"]}
                          </a>
                        </span>{" "}
                        <CopyToClipboard
                          text={linkDetails["Interview Room"]["Moderator Link"]}
                          onCopy={() => linkCopiedTimeout("moderator")}
                        >
                          <Icon
                            icon="mdi:content-copy"
                            height="1.5em"
                            style={{ color: "var(--ant-primary-color)", cursor: "pointer" }}
                          />
                        </CopyToClipboard>
                        {copied?.moderator && <p className="success-message">Link copied</p>}
                      </div>
                    </div>
                    <div className="link-container">
                      <header>Observer Link: </header>
                      <p>
                        This is the link you should send to anyone that wants to watch the session but not be visible to
                        participants
                      </p>
                      <div className="link-copy-container">
                        <span className="link">
                          <a href={linkDetails["Interview Room"]["Observer Link"]}>
                            {linkDetails["Interview Room"]["Observer Link"]}
                          </a>
                        </span>{" "}
                        <CopyToClipboard
                          text={linkDetails["Interview Room"]["Observer Link"]}
                          onCopy={() => linkCopiedTimeout("observer")}
                        >
                          <Icon
                            icon="mdi:content-copy"
                            height="1.5em"
                            style={{ color: "var(--ant-primary-color)", cursor: "pointer" }}
                          />
                        </CopyToClipboard>
                        {copied?.observer && <p className="success-message">Link copied</p>}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="error-message">
                    Please check that your Hubux account is linked to a Voxpopme account and the interview room is
                    included in that account
                  </p>
                )}
              </>
            ) : (
              <>
                <p className="generic-link-container">
                  <span className="link">
                    <a href={linkDetails?.link}>{linkDetails?.link}</a>
                  </span>{" "}
                  <CopyToClipboard text={linkDetails?.link} onCopy={() => linkCopiedTimeout("generic")}>
                    <Icon
                      icon="mdi:content-copy"
                      height="1.5em"
                      style={{ color: "var(--ant-primary-color)", cursor: "pointer" }}
                    />
                  </CopyToClipboard>
                </p>
                <div className="message-container">
                  {copied.generic && <p className="success-message">Link copied</p>}
                </div>
              </>
            )}
          </div>
        )}
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0;
    border-top: none;
  }

  .generic-link-container {
    display: grid;
    grid-template-columns: auto 22px;
    align-items: center;
    grid-gap: 10px;

    .link {
      border: 1px solid #ccd2e0;
      padding: 4px;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  .ir-links-container {
    header {
      font-weight: 500;
      font-size: 16px;
    }
    .link-container {
      display: flex;
      flex-direction: column;
      justify-contents: center;
      margin: 30px 0;

      header {
        font-weight: 500;
        font-size: 16px;
      }

      p {
        margin: 0;
        margin-bottom: 10px;
      }

      .link-copy-container {
        display: flex;
        align-items: center;
        grid-gap: 10px;
      }
    }

    .link {
      border: 1px solid #ccd2e0;
      padding: 4px 10px;
      border-radius: 4px;
    }
  }

  .message-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .success-message {
    padding: 4px 10px;
    border-radius: 4px;
    background: rgba(92, 184, 92, 0.5);
    color: darkgreen;
    margin-bottom: 0 !important;
  }
`;
