import { Icon } from "@iconify/react";
import { Button, Dropdown, Menu, Popover, Typography } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useRouter } from "found";
import { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { getTenantContext, getUserContext, trackEvent } from "../../utils";
import { StaffLayout_user$data } from "../../__generated__/StaffLayout_user.graphql";
import { SmallLogo } from "../SmallLogo";

const { Text } = Typography;

interface TopNavProps {
  user: StaffLayout_user$data;
}

export const TopNav: FC<TopNavProps> = ({ user }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { router } = useRouter();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePopoverChange = (open: boolean) => {
    setIsPopoverOpen(open);
  };

  const activeProfileId = user.profile?.id ?? "";

  const profileMenuItems: ItemType[] = useMemo(() => {
    const menuItemStyle: CSSProperties = { marginBlock: 6 };

    return [
      ...(user.profiles?.edges
        .map(e => {
          if (!e || !e.node) return null;
          const profile = e.node;
          const isActive = profile.id === activeProfileId;

          const rootStyle: CSSProperties = {
            ...menuItemStyle,
            width: 169,
            overflow: "visible",
            paddingRight: 5,
            marginRight: 42,
            backgroundColor: isActive ? "rgb(52, 28, 76)" : undefined,
            color: isActive ? "white" : undefined,
            cursor: isActive ? "default" : "pointer",
          };

          return {
            key: profile.id,
            style: rootStyle,
            label: (
              <ProfileMenuItem>
                <div className="tenant-name">{profile.tenant.name}</div>

                <div
                  className="settings-icon-container"
                  onClick={evt => {
                    evt.stopPropagation();
                    setIsPopoverOpen(false);
                    router.push(
                      isActive
                        ? "/workspace/settings"
                        : `/switch-profile/${profile.id}?next=${encodeURIComponent("/workspace/settings")}`
                    );
                  }}
                >
                  <div className="settings-icon-wrapper">
                    <Icon height="1.2em" icon="mdi:gear" className="settings-icon" />
                  </div>
                </div>
              </ProfileMenuItem>
            ),
            onClick: () => {
              if (!isActive) {
                setIsPopoverOpen(false);
                router.push(`/switch-profile/${profile.id}`);
              }
            },
          };
        })
        .filter(Boolean) ?? []),

      {
        key: "divider",
        type: "divider",
      },

      {
        key: "log-out",
        icon: <Icon icon="mdi:logout" />,
        label: "Log Out",
        onClick: () => {
          trackEvent("Logging out", {
            ...getUserContext(user as any),
            ...getTenantContext(user.profile?.tenant as any),
          });
          router.push("/logout");
          setIsPopoverOpen(false);
        },
      },
    ];
  }, [user, activeProfileId, router]);

  const navigateToVPM = (path: string) => {
    window.open(`https://voxpopme.com/portal/${path}`, "_self");
  };

  const menuItems = [
    { key: "create", label: "Create", onClick: () => navigateToVPM("project-templates") },
    { key: "projects", label: "Projects", onClick: () => navigateToVPM("projects") },
    { key: "showreels", label: "Showreels", onClick: () => navigateToVPM("lists") },
    { key: "upload", label: "Upload", onClick: () => navigateToVPM("import-v2") },
    { key: "scheduler", label: "Scheduler", onClick: () => router.push("/projects") },
  ];

  const menuItemsMobile = [
    { key: "dashboard", label: "Dashboard", onClick: () => navigateToVPM("dashboard") },
    { key: "create", label: "Create", onClick: () => navigateToVPM("project-templates") },
    { key: "projects", label: "Projects", onClick: () => navigateToVPM("projects") },
    { key: "showreels", label: "Showreels", onClick: () => navigateToVPM("lists") },
    { key: "upload", label: "Upload", onClick: () => navigateToVPM("import-v2") },
    { key: "scheduler", label: "Scheduler", onClick: () => router.push("/projects") },
  ];

  if (user.profile?.tenant?.enableCustomCommunityTab) {
    menuItems.push({
      key: "panel",
      label: "Community",
      onClick: () => router.push(user?.profile?.tenant?.enablePanelView ? "/panel/report" : "/panel-setup"),
    });

    menuItemsMobile.push({
      key: "panel",
      label: "Community",
      onClick: () => router.push(user?.profile?.tenant?.enablePanelView ? "/panel/report" : "/panel-setup"),
    });
  }

  if (!user.profile?.tenant?.hideBillingPage && user.profile?.tenant?.enableBillingTab) {
    menuItems.push({
      key: "billing",
      label: "Billing",
      onClick: () => router.push("/billing"),
    });

    menuItemsMobile.push({
      key: "billing",
      label: "Billing",
      onClick: () => router.push("/billing"),
    });
  }

  return (
    <LayoutWrapper>
      <nav>
        <div className="child-container child-container--menu">
          <div className={`logo ${isMobile ? "hidden" : ""}`} onClick={() => navigateToVPM("dashboard")}>
            <SmallLogo />
          </div>

          {!isMobile ? (
            <div className="menu-btn-container">
              {menuItems.map(({ key, label, onClick }) => {
                const path = window.location.pathname;

                const isActive =
                  (key === "scheduler" && path.includes("projects")) ||
                  (key === "panel" && path.includes("panel")) ||
                  (key === "billing" && path.includes("billing"));

                return (
                  <button key={key} className={`menu-btn ${isActive ? "nav-active" : ""}`} onClick={onClick}>
                    {label}
                  </button>
                );
              })}
            </div>
          ) : (
            <Dropdown
              menu={{
                items: menuItemsMobile.map(({ key, label, onClick }) => ({
                  key,
                  label,
                  onClick,
                })),
              }}
              trigger={["click"]}
              overlayStyle={{ width: "92%" }}
            >
              <Button className="burger-menu" icon={<Icon icon="mdi:menu" />} />
            </Dropdown>
          )}
        </div>

        <div className="child-container">
          <div className="account-container">
            <span className="name">{user.profile?.tenant.name}</span>
          </div>
          <div className="account-container account-container--user">
            <Popover
              trigger="click"
              className="account-dropdown"
              content={<Menu items={profileMenuItems} />}
              open={isPopoverOpen}
              onOpenChange={handlePopoverChange}
              placement="bottom"
              showArrow={false}
              overlayInnerStyle={{ padding: 8 }}
            >
              <div className="user">
                <Text>
                  <Icon icon="mdi:account" width="16" color="#fff" />
                </Text>
              </div>
            </Popover>
          </div>
        </div>
      </nav>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: rgb(52, 28, 76);
    gap: 22px;
    height: 70px;
    align-items: center;
    padding-left: 19px;
  }

  .child-container {
    display: flex;
    height: 100%;
    align-items: center;

    &--menu {
      gap: 22px;
    }
  }

  .logo {
    cursor: pointer;
    width: 50px;
    display: flex;
    align-items: center;
    padding-bottom: 4px;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }

    &.hidden {
      display: none;
    }
  }

  .menu-btn-container {
    display: flex;
    gap: 14px;
    margin-left: 1px;
    padding-top: 6px;
    padding-bottom: 10px;
  }

  .menu-btn {
    font-family: Roboto, sans-serif !important;
    background: none;
    border: none;
    border-radius: 4px;
    padding: 1px 14px 0 16px;
    letter-spacing: -0.2px;
    font-size: 14.3px;
    font-weight: 700;
    height: 42px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
    }
  }

  .nav-active {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .burger-menu {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    padding: 4px 8px 4px 4px;
    border-radius: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
    }

    svg {
      margin-bottom: 3px;
    }
  }

  .account-container {
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(216, 220, 230, 0.4);
    height: 100%;
    padding: 0 20px;
    text-align: center;

    &--user {
      width: 74px;
    }

    .name {
      width: 60px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-right: 6px;
      font-family: Roboto, sans-serif !important;
    }
  }

  .user {
    cursor: pointer;
    padding: 8px 12px 4px;
    color: #fff;
    border-radius: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
    }
  }
`;

const ProfileMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .tenant-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .settings-icon-container {
    cursor: pointer;
    position: absolute;
    right: -39px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.88);
    border-radius: 16px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
