import { UserOutlined } from "@ant-design/icons";
import { App, Button, Form, Input } from "antd";
import axios from "axios";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "found";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { getApiBaseUrl, getFaviconEl } from "../utils/misc";
import { ForgotPasswordPage_customPortal$data } from "../__generated__/ForgotPasswordPage_customPortal.graphql";
import { LoggedOutPage } from "./index";

type Props = {
  customPortal: ForgotPasswordPage_customPortal$data;
};

const ForgotPasswordPage: React.FC<Props> = ({ customPortal }) => {
  const { message } = App.useApp();

  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) {
      faviconEl.href = customPortal?.faviconUrl;
    }
    if (customPortal?.pageTitle) {
      document.title = customPortal?.pageTitle;
    }
  }, [customPortal]);

  const onFinish = async ({ email }: any) => {
    setLoading(true);

    try {
      await axios.post(`${getApiBaseUrl()}/api/password_reset/`, {
        email,
      });
      setPasswordReset(true);
    } catch (e: any) {
      if (e.response?.status === 400) {
        message.error("We couldn't find an account associated with that email. Please try a different e-mail address.");
      } else {
        message.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoggedOutPage
      title={t("forgot-password-page.logged-out-page.request-password-reset", "Request password reset")}
      customLogoUrl={customPortal?.logoImageUrl}
      customColor={customPortal?.customColor}
      customHeaderFontColor={customPortal?.headerFontColor}
      customFooterUrl={customPortal?.footerImageUrl}
      contactEmail={customPortal?.contactEmail}
    >
      {!passwordReset ? (
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item>
            <Trans i18nKey="forgot-password-page.request-email.instructions">
              <p>Enter your email below to start the password reset process.</p>
              <p>
                If you use single sign-on, you'll need to contact your login provider to reset your password instead of
                using this form.
              </p>
            </Trans>
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: t("forgot-password-page.email-required", "Please input your email") }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t("forgot-password-page.email-placeholder", "Email")}
            />
          </Form.Item>
          <Form.Item className="form-item-margin-small">
            <Button type="primary" htmlType="submit" className="login-form-button" disabled={loading} loading={loading}>
              {t("forgot-password-page.reset-password-button", "Reset Password")}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <Trans i18nKey="forgot-password-page.instructions">
            <p>You should receive a password reset email in a few moments.</p>
            <p>You may need to check your spam folder if your email doesn't appear in your inbox.</p>
            <Link to="/login" activeClassName="active">
              Take me back to the login page.
            </Link>
          </Trans>
        </div>
      )}
    </LoggedOutPage>
  );
};

export default createFragmentContainer(ForgotPasswordPage, {
  customPortal: graphql`
    fragment ForgotPasswordPage_customPortal on CustomPortalNode {
      logoImageUrl
      footerImageUrl
      customColor
      headerFontColor
      faviconUrl
      pageTitle
      contactEmail
    }
  `,
});
