export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  JSONString: any;
  UUID: any;
};

/** An enumeration. */
export const enum AccountsOidcProviderTypeChoices {
  /** AWS Cognito */
  Cognito = "COGNITO",
  /** Okta */
  Okta = "OKTA",
}

/** An enumeration. */
export const enum AccountsTenantAccountStandingChoices {
  /** Flagged for Potential Fraud */
  F = "F",
  /** In Good Standing */
  G = "G",
}

export type AddAllStudyPanelRecruitsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToRecruit?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly panelFilters?: InputMaybe<Scalars["GenericScalar"]>;
  readonly randomize?: InputMaybe<Scalars["Boolean"]>;
  readonly recruitId?: InputMaybe<Scalars["String"]>;
  readonly searchTerm?: InputMaybe<Scalars["String"]>;
};

/**
 * Add All PanelRecruits to a study.
 *
 * panel_filters: dict of panelist attribute filters from Panel table
 * exclude_ids: list of panelist ids to exclude
 * count_to_recruit: the max number of panelists to add to the recruiting round
 * search_term: search string
 */
export type AddAllStudyPanelRecruitsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type AddAnswerInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly other: Scalars["Boolean"];
  readonly position?: InputMaybe<Scalars["Int"]>;
  readonly terminate: Scalars["Boolean"];
};

export type AddAnswerPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type AddElementInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly position?: InputMaybe<Scalars["Int"]>;
  readonly screenerId: Scalars["String"];
};

export type AddElementPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type AddPaymentMethodInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly sessionId: Scalars["String"];
};

/** Add a payment method for a tenant based on a Stripe session Id */
export type AddPaymentMethodPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export type AddRowInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly position?: InputMaybe<Scalars["Int"]>;
};

/** Adds a row to an element. */
export type AddRowPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type AddSegmentInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly screenerId: Scalars["String"];
};

export type AddSegmentPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type AddStudyPanelRecruitsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly panelistIds: ReadonlyArray<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Add panelist ids to a recruiting round's meta. */
export type AddStudyPanelRecruitsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type AnswerNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  readonly element: ElementNode;
  readonly exclusive: Scalars["Boolean"];
  readonly gridAnswers: GridAnswerNodeConnection;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly other: Scalars["Boolean"];
  readonly position: Scalars["Int"];
  readonly quota?: Maybe<Scalars["Int"]>;
  readonly rating?: Maybe<Scalars["Int"]>;
  readonly selectedAnswers: ElementResponseAnswerNodeConnection;
  readonly terminate: Scalars["Boolean"];
  readonly terminateCondition?: Maybe<Scalars["GenericScalar"]>;
  readonly text?: Maybe<Scalars["String"]>;
  readonly userSpecified: Scalars["Boolean"];
};

export type AnswerNodeGridAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AnswerNodeSelectedAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AnswerNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AnswerNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `AnswerNode` and its cursor. */
export type AnswerNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AnswerNode>;
};

export type ApproveAllRespondentsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Approve a set of respondents that match provided criteria */
export type ApproveAllRespondentsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type ApproveRespondentInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly respondentId: Scalars["String"];
  readonly studyId: Scalars["String"];
};

export type ApproveRespondentPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type ApproveRespondentsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly respondentIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly studyId: Scalars["String"];
};

/** Approve a list of respondents using respondent_ids or participant_ids */
export type ApproveRespondentsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type AssetNode = Node & {
  readonly automatedEmails: AutomatedEmailNodeConnection;
  readonly created: Scalars["DateTime"];
  readonly elements: ElementNodeConnection;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly name?: Maybe<Scalars["String"]>;
  readonly sizeBytes?: Maybe<Scalars["Int"]>;
  readonly tenant?: Maybe<TenantNode>;
  readonly url: Scalars["String"];
};

export type AssetNodeAutomatedEmailsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_In?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["ID"]>>>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AssetNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetNode` and its cursor. */
export type AssetNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AssetNode>;
};

export type AutomatedEmailAssetInput = {
  readonly name: Scalars["String"];
  readonly sizeBytes: Scalars["Int"];
  readonly url: Scalars["String"];
};

export type AutomatedEmailNode = Node & {
  readonly assets: AssetNodeConnection;
  readonly created: Scalars["DateTime"];
  readonly ctaLabel?: Maybe<Scalars["String"]>;
  readonly ctaUrl?: Maybe<Scalars["String"]>;
  readonly enabled: Scalars["Boolean"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly message: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly replyTo: Scalars["String"];
  readonly study: StudyNode;
  readonly subject: Scalars["String"];
  readonly type: ScreenersAutomatedEmailTypeChoices | "%future added value" | `${ScreenersAutomatedEmailTypeChoices}`;
};

export type AutomatedEmailNodeAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AutomatedEmailNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AutomatedEmailNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `AutomatedEmailNode` and its cursor. */
export type AutomatedEmailNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<AutomatedEmailNode>;
};

export type AvailabilitySlotInput = {
  readonly availabilityBuffer?: InputMaybe<Scalars["Int"]>;
  readonly duration?: InputMaybe<Scalars["Int"]>;
  readonly end?: InputMaybe<Scalars["DateTime"]>;
  readonly id?: InputMaybe<Scalars["String"]>;
  readonly inPerson?: InputMaybe<Scalars["Boolean"]>;
  readonly interviewer?: InputMaybe<Scalars["String"]>;
  readonly meetingDetails?: InputMaybe<Scalars["String"]>;
  readonly meetingLink?: InputMaybe<Scalars["String"]>;
  readonly meetingLocation?: InputMaybe<Scalars["String"]>;
  readonly placesLimit?: InputMaybe<Scalars["Int"]>;
  readonly start?: InputMaybe<Scalars["DateTime"]>;
};

export type CancelStudyInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type CancelStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly success?: Maybe<Scalars["Boolean"]>;
};

export type ChangeElementTypeInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly elementType: Scalars["String"];
};

export type ChangeElementTypePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type ChangeRecruitMetricsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly filterPanelists?: InputMaybe<Scalars["Boolean"]>;
  readonly invitesLimit?: InputMaybe<Scalars["String"]>;
  readonly limitInvites?: InputMaybe<Scalars["Boolean"]>;
  readonly panelFilters?: InputMaybe<Scalars["GenericScalar"]>;
  readonly previousStudyFilterDays?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type ChangeRecruitMetricsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type ChangeRecruitTypeInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
  readonly recruitType: Scalars["String"];
};

export type ChangeRecruitTypePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
};

export type ChangeUsePreviousAnswersElementInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly previousAnswersElementId: Scalars["String"];
};

export type ChangeUsePreviousAnswersElementPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type CharacteristicAnswerInput = {
  readonly exclusive?: InputMaybe<Scalars["Boolean"]>;
  readonly id?: InputMaybe<Scalars["String"]>;
  readonly other?: InputMaybe<Scalars["Boolean"]>;
  readonly position?: InputMaybe<Scalars["Int"]>;
  readonly text?: InputMaybe<Scalars["String"]>;
  readonly userSpecified?: InputMaybe<Scalars["Boolean"]>;
};

export type CharacteristicAnswerNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  readonly exclusive: Scalars["Boolean"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly other: Scalars["Boolean"];
  readonly panelCharacteristic: CharacteristicNode;
  readonly position: Scalars["Int"];
  readonly responses: CharacteristicResponseNodeConnection;
  /** This is the question text respondents see */
  readonly text: Scalars["String"];
  readonly userSpecified: Scalars["Boolean"];
};

export type CharacteristicAnswerNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  panelist?: InputMaybe<Scalars["ID"]>;
};

export type CharacteristicElementMetaInput = {
  readonly randomize?: InputMaybe<Scalars["Boolean"]>;
  readonly responseValidation?: InputMaybe<
    ResponseValidationType | "%future added value" | `${ResponseValidationType}`
  >;
  readonly target?: InputMaybe<Scalars["Int"]>;
  readonly target2?: InputMaybe<Scalars["Int"]>;
  readonly targetTypes?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
};

export type CharacteristicElementMetaNode = {
  readonly randomize?: Maybe<Scalars["Boolean"]>;
  readonly responseValidation?: Maybe<ResponseValidationType | "%future added value" | `${ResponseValidationType}`>;
  readonly target?: Maybe<Scalars["Int"]>;
  readonly target2?: Maybe<Scalars["Int"]>;
  readonly targetTypes?: Maybe<ReadonlyArray<Scalars["String"]>>;
};

export type CharacteristicElementsConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CharacteristicElementsEdge>>;
  readonly overwritableAttributes: ReadonlyArray<Scalars["String"]>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `CharacteristicElements` and its cursor. */
export type CharacteristicElementsEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ElementNode>;
};

export type CharacteristicNode = Node & {
  readonly answers: ReadonlyArray<CharacteristicAnswerNode>;
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  readonly elementMeta: CharacteristicElementMetaNode;
  readonly elements: CharacteristicElementsConnection;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  /** Unique key used to identify characteristic */
  readonly importKey: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly responses: CharacteristicResponseNodeConnection;
  readonly rows: ReadonlyArray<CharacteristicRowNode>;
  /** Shown in table headers and question type dropdowns */
  readonly shortName: Scalars["String"];
  readonly showToPanelists: Scalars["Boolean"];
  readonly tenant?: Maybe<TenantNode>;
  /** This is the question text respondents see */
  readonly text?: Maybe<Scalars["String"]>;
  readonly type: ScreenersCharacteristicTypeChoices | "%future added value" | `${ScreenersCharacteristicTypeChoices}`;
};

export type CharacteristicNodeElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CharacteristicNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  panelist?: InputMaybe<Scalars["ID"]>;
};

export type CharacteristicNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CharacteristicNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `CharacteristicNode` and its cursor. */
export type CharacteristicNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CharacteristicNode>;
};

export type CharacteristicResponseNode = Node & {
  readonly answer?: Maybe<CharacteristicAnswerNode>;
  readonly characteristic: CharacteristicNode;
  readonly created: Scalars["DateTime"];
  readonly customAnswer?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly panelist: PanelistNode;
  readonly row?: Maybe<CharacteristicRowNode>;
  readonly textValue: Scalars["String"];
};

export type CharacteristicResponseNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CharacteristicResponseNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `CharacteristicResponseNode` and its cursor. */
export type CharacteristicResponseNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CharacteristicResponseNode>;
};

export type CharacteristicRowInput = {
  readonly id?: InputMaybe<Scalars["String"]>;
  readonly text?: InputMaybe<Scalars["String"]>;
};

export type CharacteristicRowNode = Node & {
  readonly characteristic: CharacteristicNode;
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  /** Unique key used to identify characteristic */
  readonly importKey: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly position: Scalars["Int"];
  readonly responses: CharacteristicResponseNodeConnection;
  /** This is the question text respondents see */
  readonly text: Scalars["String"];
};

export type CharacteristicRowNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  panelist?: InputMaybe<Scalars["ID"]>;
};

export type CheckPasswordStrengthInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly password: Scalars["String"];
  readonly userInfo?: InputMaybe<Scalars["GenericScalar"]>;
};

export type CheckPasswordStrengthPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly reason?: Maybe<Scalars["String"]>;
  readonly strong?: Maybe<Scalars["Boolean"]>;
};

export type ClearLabelsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

/** Clear labels for all elements on a screener */
export type ClearLabelsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

/** An enumeration. */
export const enum Color {
  Bl = "BL",
  Cn = "CN",
  Gn = "GN",
  Gy = "GY",
  Og = "OG",
  Pl = "PL",
  Rd = "RD",
  Yw = "YW",
}

export type CompleteStudyInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type CompleteStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type ConditionOptionNode = {
  readonly key?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
};

export type CreateAssetInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId?: InputMaybe<Scalars["String"]>;
  readonly url: Scalars["String"];
};

export type CreateAssetPayload = {
  readonly asset?: Maybe<AssetNode>;
  readonly clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateExportInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly filters: Scalars["GenericScalar"];
  readonly id: Scalars["String"];
  readonly isVpm?: InputMaybe<Scalars["Boolean"]>;
  readonly qIdent?: InputMaybe<Scalars["String"]>;
};

export type CreateExportPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly export: ExportNode;
  readonly panel?: Maybe<PanelNode>;
  readonly recruit?: Maybe<RecruitNode>;
  readonly study?: Maybe<StudyNode>;
};

export type CreateRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type CreateRecruitOtpInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

export type CreateRecruitOtpPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly id: Scalars["String"];
  readonly value: Scalars["String"];
};

export type CreateRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruitEdge?: Maybe<RecruitEdge>;
};

export type CreateRemindersTemplateInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly daysBefore?: InputMaybe<Scalars["Int"]>;
  readonly hoursBefore?: InputMaybe<Scalars["Int"]>;
  readonly minsBefore?: InputMaybe<Scalars["Int"]>;
  readonly studyId: Scalars["String"];
};

export type CreateRemindersTemplatePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type CreateStudyInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly namePublic?: InputMaybe<Scalars["String"]>;
};

export type CreateStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type CreateTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly color?: InputMaybe<Color | "%future added value" | `${Color}`>;
  readonly name: Scalars["String"];
};

export type CreateTagPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tag?: Maybe<TagNode>;
};

export type CustomPortalAgreementNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly customPortal: CustomPortalNode;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly text: Scalars["String"];
};

export type CustomPortalAgreementNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CustomPortalAgreementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomPortalAgreementNode` and its cursor. */
export type CustomPortalAgreementNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CustomPortalAgreementNode>;
};

export type CustomPortalNode = Node & {
  /** Used for interactive elements */
  readonly accentColor?: Maybe<Scalars["String"]>;
  /** Used when interactive elements are hovered */
  readonly accentColorLighter?: Maybe<Scalars["String"]>;
  readonly agreements: CustomPortalAgreementNodeConnection;
  readonly contactEmail?: Maybe<Scalars["String"]>;
  readonly created: Scalars["DateTime"];
  readonly customColor?: Maybe<Scalars["String"]>;
  readonly customLandingHostname?: Maybe<Scalars["String"]>;
  readonly fadeBgBottom: Scalars["Boolean"];
  readonly faviconUrl?: Maybe<Scalars["String"]>;
  readonly footerImageUrl?: Maybe<Scalars["String"]>;
  readonly headerFontColor?: Maybe<Scalars["String"]>;
  /** HTML, CSS, JS custom code to replace default header */
  readonly headerMarkup: Scalars["String"];
  readonly headerMarkupEnabled: Scalars["Boolean"];
  readonly heroAssetUrl?: Maybe<Scalars["String"]>;
  readonly heroBackgroundUrl?: Maybe<Scalars["String"]>;
  readonly hidePanelistProfile: Scalars["Boolean"];
  /** When visible, this banner appears atop the portal's homepage, as long as the Panelist has unanswered Characteristics. */
  readonly hidePanelistProfileCompleteBanner: Scalars["Boolean"];
  /** Enable this for an Account <strong>exclusively</strong> using external incentives (meaning no cash, points, or bonus incentives) */
  readonly hidePointsAndRedemption: Scalars["Boolean"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly logoImageUrl?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly pageTitle?: Maybe<Scalars["String"]>;
  readonly privacyPolicyUrl?: Maybe<Scalars["String"]>;
  readonly tenant: TenantNode;
  readonly termsLabel: Scalars["String"];
  readonly termsUrl?: Maybe<Scalars["String"]>;
};

export type CustomPortalNodeAgreementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type DeleteAnswerInput = {
  readonly answerId: Scalars["String"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteAnswerPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type DeleteAssetInput = {
  readonly assetId: Scalars["String"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteAssetPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly ok?: Maybe<Scalars["Boolean"]>;
};

export type DeleteCharacteristicInput = {
  readonly characteristicId: Scalars["String"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteCharacteristicPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export type DeleteElementInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
};

export type DeleteElementPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type DeleteFilterInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly filterId: Scalars["String"];
};

export type DeleteFilterPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
  readonly tenant: TenantNode;
  readonly user?: Maybe<UserNode>;
};

export type DeleteRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Deletes a recruiting round and associated RecruitPersons. Only supported for Recruits that have not been started */
export type DeleteRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type DeleteRemindersTemplateInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly templateId: Scalars["String"];
};

export type DeleteRemindersTemplatePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type DeleteRowInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly rowId: Scalars["String"];
};

/** Delete a specified element's row. */
export type DeleteRowPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type DeleteSegmentInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly segmentId: Scalars["String"];
};

export type DeleteSegmentPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type DeleteStudyAvailabilitySlotInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly reschedule?: InputMaybe<Scalars["Boolean"]>;
  readonly slotId: Scalars["String"];
};

/** Removes StudyAvailabilitySlot and linked Respondent slots, reminders etc */
export type DeleteStudyAvailabilitySlotPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type DeleteTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly id: Scalars["String"];
};

export type DeleteTagPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export type DenyAllRespondentsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Deny a set of respondents that match provided criteria */
export type DenyAllRespondentsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type DenyRespondentInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly respondentId: Scalars["String"];
  readonly studyId: Scalars["String"];
};

export type DenyRespondentPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type DenyRespondentsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly respondentIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly studyId: Scalars["String"];
};

/** Deny a list of respondents by providing respondent or participant ids */
export type DenyRespondentsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type DuplicateElementInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
};

export type DuplicateElementPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type DuplicateStudyInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type DuplicateStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type ElementNode = Node & {
  readonly answers: AnswerNodeConnection;
  readonly asset?: Maybe<AssetNode>;
  readonly characteristic?: Maybe<CharacteristicNode>;
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  readonly gridAnswers?: Maybe<ReadonlyArray<Maybe<GridAnswerNode>>>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly label?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly operators?: Maybe<ReadonlyArray<Maybe<ConditionOptionNode>>>;
  readonly options?: Maybe<ReadonlyArray<Maybe<AnswerNode>>>;
  readonly optionsInputType?: Maybe<Scalars["String"]>;
  readonly position: Scalars["Int"];
  readonly randomize: Scalars["Boolean"];
  readonly respondentAnswer?: Maybe<ReadonlyArray<Maybe<ElementResponseNode>>>;
  readonly response: ElementResponseNodeConnection;
  readonly responseValidation?: Maybe<ResponseValidationType | "%future added value" | `${ResponseValidationType}`>;
  readonly rows: RowNodeConnection;
  readonly screener: ScreenerNode;
  readonly showIfCondition?: Maybe<Scalars["GenericScalar"]>;
  readonly target?: Maybe<Scalars["Int"]>;
  readonly target2?: Maybe<Scalars["Int"]>;
  readonly targetTypes?: Maybe<ReadonlyArray<Scalars["String"]>>;
  readonly text?: Maybe<Scalars["String"]>;
  readonly type: ScreenersElementTypeChoices | "%future added value" | `${ScreenersElementTypeChoices}`;
  readonly usePreviousAnswers?: Maybe<Scalars["JSONString"]>;
};

export type ElementNodeAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ElementNodeRespondentAnswerArgs = {
  respondentId?: InputMaybe<Scalars["String"]>;
};

export type ElementNodeResponseArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ElementNodeRowsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ElementNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ElementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ElementNode` and its cursor. */
export type ElementNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ElementNode>;
};

export type ElementResponseAnswerNode = Node & {
  readonly answer: AnswerNode;
  readonly created: Scalars["DateTime"];
  readonly customAnswer?: Maybe<Scalars["String"]>;
  readonly elementResponse: ElementResponseNode;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly position: Scalars["Int"];
  readonly row?: Maybe<RowNode>;
  readonly status?: Maybe<
    | ScreenersElementResponseAnswerStatusChoices
    | "%future added value"
    | `${ScreenersElementResponseAnswerStatusChoices}`
  >;
};

export type ElementResponseAnswerNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ElementResponseAnswerNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ElementResponseAnswerNode` and its cursor. */
export type ElementResponseAnswerNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ElementResponseAnswerNode>;
};

export type ElementResponseNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  readonly element: ElementNode;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly respondent: RespondentNode;
  readonly row?: Maybe<RowNode>;
  readonly selectedAnswers: ElementResponseAnswerNodeConnection;
  readonly value: Scalars["String"];
};

export type ElementResponseNodeSelectedAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ElementResponseNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ElementResponseNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ElementResponseNode` and its cursor. */
export type ElementResponseNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ElementResponseNode>;
};

export type EmailTemplateConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<EmailTemplateEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `EmailTemplate` and its cursor. */
export type EmailTemplateEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<EmailTemplateNode>;
};

export type EmailTemplateNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly creator?: Maybe<UserNode>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly message: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly name: Scalars["String"];
  readonly replyTo: Scalars["String"];
  readonly subject: Scalars["String"];
  readonly tenant: TenantNode;
};

export type EmailTemplateNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<EmailTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `EmailTemplateNode` and its cursor. */
export type EmailTemplateNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<EmailTemplateNode>;
};

export type Errors = {
  readonly data?: Maybe<Scalars["GenericScalar"]>;
  readonly message?: Maybe<Scalars["String"]>;
};

export type ExportNode = Node & {
  readonly busy: Scalars["Boolean"];
  readonly countCompleted: Scalars["Int"];
  readonly countTotal: Scalars["Int"];
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly idErrored?: Maybe<Scalars["UUID"]>;
  readonly modified: Scalars["DateTime"];
  readonly panel?: Maybe<PanelNode>;
  readonly recruit?: Maybe<RecruitNode>;
  readonly sentOn?: Maybe<Scalars["DateTime"]>;
  readonly sentTo?: Maybe<Scalars["String"]>;
  readonly status: ExportStatus | "%future added value" | `${ExportStatus}`;
  readonly study?: Maybe<StudyNode>;
  readonly to: UserNode;
  readonly type: ExportType | "%future added value" | `${ExportType}`;
};

export type ExportNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ExportNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ExportNode` and its cursor. */
export type ExportNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ExportNode>;
};

/** An enumeration. */
export const enum ExportStatus {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
  Sent = "Sent",
}

/** An enumeration. */
export const enum ExportType {
  Panelist = "Panelist",
  Respondent = "Respondent",
}

export type ExternalIncentiveNode = Node & {
  readonly automatedEmail?: Maybe<AutomatedEmailNode>;
  readonly claimLink?: Maybe<Scalars["String"]>;
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly rewardDescription: Scalars["String"];
  readonly study: StudyNode;
};

export type FieldReportElementNode = {
  readonly gridAnswers?: Maybe<ReadonlyArray<Maybe<FieldReportGridAnswerNode>>>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly labeledText?: Maybe<Scalars["String"]>;
  readonly options?: Maybe<ReadonlyArray<Maybe<FieldReportOptionNode>>>;
  readonly rows?: Maybe<ReadonlyArray<Maybe<FieldReportRowNode>>>;
  readonly text?: Maybe<Scalars["String"]>;
  readonly type?: Maybe<Scalars["String"]>;
};

export type FieldReportGridAnswerNode = {
  readonly answerId?: Maybe<Scalars["String"]>;
  readonly dbId: Scalars["String"];
  readonly goal?: Maybe<Scalars["Int"]>;
  readonly id: Scalars["ID"];
  readonly rowId?: Maybe<Scalars["String"]>;
  readonly terminate?: Maybe<Scalars["Boolean"]>;
};

export type FieldReportNode = {
  readonly elements?: Maybe<ReadonlyArray<Maybe<FieldReportElementNode>>>;
  readonly responseSummary?: Maybe<ResponseSummaryNode>;
  readonly segments?: Maybe<FieldReportSegmentNode>;
};

export type FieldReportOptionNode = {
  readonly dbId: Scalars["String"];
  readonly qualified?: Maybe<Scalars["Int"]>;
  readonly qualifiedTotal?: Maybe<Scalars["Int"]>;
  readonly quota?: Maybe<Scalars["String"]>;
  readonly terminate?: Maybe<Scalars["Boolean"]>;
  readonly text?: Maybe<Scalars["String"]>;
  readonly total?: Maybe<Scalars["Int"]>;
};

export type FieldReportRowNode = {
  readonly dbId: Scalars["String"];
  readonly id: Scalars["ID"];
  readonly labeledText?: Maybe<Scalars["String"]>;
  readonly options?: Maybe<ReadonlyArray<Maybe<FieldReportRowOptionNode>>>;
  readonly text?: Maybe<Scalars["String"]>;
};

export type FieldReportRowOptionNode = {
  readonly dbId: Scalars["String"];
  readonly qualified?: Maybe<Scalars["Int"]>;
  readonly qualifiedTotal?: Maybe<Scalars["Int"]>;
  readonly quota?: Maybe<Scalars["String"]>;
  readonly terminate?: Maybe<Scalars["Boolean"]>;
  readonly text?: Maybe<Scalars["String"]>;
  readonly total?: Maybe<Scalars["Int"]>;
};

export type FieldReportSegmentNode = {
  readonly rows?: Maybe<ReadonlyArray<Maybe<FieldReportSegmentRowNode>>>;
};

export type FieldReportSegmentRowNode = {
  readonly overQuota?: Maybe<Scalars["Int"]>;
  readonly qualified?: Maybe<Scalars["Int"]>;
  readonly quota?: Maybe<Scalars["Int"]>;
  readonly terminants?: Maybe<Scalars["Int"]>;
  readonly terminate?: Maybe<Scalars["Boolean"]>;
  readonly text?: Maybe<Scalars["String"]>;
};

export type FilterNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly filters: Scalars["JSONString"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly name: Scalars["String"];
  readonly study?: Maybe<StudyNode>;
  readonly tenant: TenantNode;
  readonly type: ScreenersFilterTypeChoices | "%future added value" | `${ScreenersFilterTypeChoices}`;
  readonly user?: Maybe<UserNode>;
};

export type FundRecruitInput = {
  readonly amountUsdCents: Scalars["Int"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Deposits specified amount into specified recruit */
export type FundRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruitTransaction?: Maybe<RecruitTransactionNode>;
};

export type GetGivePointsToAllParticipantsCostsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly excludeNonTest?: InputMaybe<Scalars["Boolean"]>;
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly includeTest?: InputMaybe<Scalars["Boolean"]>;
  readonly points: Scalars["Int"];
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Rate a set of participants that match provided criteria */
export type GetGivePointsToAllParticipantsCostsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly costsSummary: GivePointsCostsSummary;
};

export type GetGivePointsToParticipantsCostsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly points: Scalars["Int"];
};

/** Gives points to a list of participants, separate from the standard incentive flow. */
export type GetGivePointsToParticipantsCostsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly costsSummary: GivePointsCostsSummary;
};

export type GivePointsCostsSummary = {
  readonly feesUsdCents: Scalars["Int"];
  readonly nonTestParticipantCount: Scalars["Int"];
  readonly pointsCostUsdCents: Scalars["Int"];
  readonly testParticipantCount: Scalars["Int"];
};

export type GivePointsPaymentNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly description: Scalars["String"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentiveOrders: PanelistBonusOrderNodeConnection;
  readonly modified: Scalars["DateTime"];
  readonly panelist?: Maybe<PanelistNode>;
  readonly participantCount: Scalars["Int"];
  /** Stripe payment ID */
  readonly paymentId?: Maybe<Scalars["String"]>;
  readonly points: Scalars["Int"];
  readonly pointsCost: Scalars["String"];
  readonly pointsCostCurrency:
    | ScreenersGivePointsPaymentPointsCostCurrencyChoices
    | "%future added value"
    | `${ScreenersGivePointsPaymentPointsCostCurrencyChoices}`;
  readonly pointsFees: Scalars["String"];
  readonly pointsFeesCurrency:
    | ScreenersGivePointsPaymentPointsFeesCurrencyChoices
    | "%future added value"
    | `${ScreenersGivePointsPaymentPointsFeesCurrencyChoices}`;
  readonly study?: Maybe<StudyNode>;
};

export type GivePointsPaymentNodeIncentiveOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type GivePointsPaymentNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GivePointsPaymentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `GivePointsPaymentNode` and its cursor. */
export type GivePointsPaymentNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<GivePointsPaymentNode>;
};

export type GivePointsToAllParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly excludeNonTest?: InputMaybe<Scalars["Boolean"]>;
  readonly expectedCostUsdCents: Scalars["Int"];
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly includeTest?: InputMaybe<Scalars["Boolean"]>;
  readonly points: Scalars["Int"];
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Gives points to participants that match provided criteria */
export type GivePointsToAllParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study: StudyNode;
  readonly summary: GivePointsCostsSummary;
};

export type GivePointsToPanelistInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly expectedCostUsdCents: Scalars["Int"];
  readonly panelistId: Scalars["String"];
  readonly points: Scalars["Int"];
};

export type GivePointsToPanelistPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly panelist?: Maybe<PanelistNode>;
};

export type GivePointsToParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly expectedCostUsdCents: Scalars["Int"];
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly points: Scalars["Int"];
  readonly studyId: Scalars["String"];
};

/** Gives points to a list of participants, separate from the standard incentive flow. */
export type GivePointsToParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study: StudyNode;
  readonly summary: GivePointsCostsSummary;
};

export type GlobalCharacteristic = {
  readonly id?: Maybe<Scalars["UUID"]>;
  readonly key?: Maybe<Scalars["String"]>;
  readonly response?: Maybe<Scalars["String"]>;
  readonly type?: Maybe<Scalars["String"]>;
};

export type GridAnswerNode = Node & {
  readonly answer: AnswerNode;
  readonly created: Scalars["DateTime"];
  readonly goal?: Maybe<Scalars["Int"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly row: RowNode;
  readonly terminate?: Maybe<Scalars["Boolean"]>;
};

export type GridAnswerNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GridAnswerNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `GridAnswerNode` and its cursor. */
export type GridAnswerNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<GridAnswerNode>;
};

export type InsertAnswersInput = {
  readonly answers: ReadonlyArray<Scalars["String"]>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly position: Scalars["Int"];
};

export type InsertAnswersPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type InsertRowsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly position: Scalars["Int"];
  readonly rows: ReadonlyArray<Scalars["String"]>;
};

export type InsertRowsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type InterviewerNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly email: Scalars["String"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly study: StudyNode;
  readonly studyAvailabilitySlot?: Maybe<StudyAvailabilitySlotNode>;
};

export type InterviewerNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<InterviewerNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `InterviewerNode` and its cursor. */
export type InterviewerNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<InterviewerNode>;
};

export type LanguageNode = {
  readonly code: Scalars["String"];
  readonly name: Scalars["String"];
};

export type LaunchStudyInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

/** Starts inviting on ALL started recruiting rounds */
export type LaunchStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type MarkRespondentInterviewedInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly respondentId: Scalars["String"];
};

/** Sets a respondent's status to "Interviewed" in cases where scheduling happened outside of HubUX */
export type MarkRespondentInterviewedPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly respondent: RespondentNode;
};

export type MoveAnswerInput = {
  readonly answerId: Scalars["String"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly toPosition?: InputMaybe<Scalars["Int"]>;
};

export type MoveAnswerPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type MoveElementInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly toPosition?: InputMaybe<Scalars["Int"]>;
};

export type MoveElementPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type MoveRowInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly rowId: Scalars["String"];
  readonly toPosition?: InputMaybe<Scalars["Int"]>;
};

/** Moves a row within an element to the specified position. */
export type MoveRowPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type Mutation = {
  /**
   * Add All PanelRecruits to a study.
   *
   * panel_filters: dict of panelist attribute filters from Panel table
   * exclude_ids: list of panelist ids to exclude
   * count_to_recruit: the max number of panelists to add to the recruiting round
   * search_term: search string
   */
  readonly addAllStudyPanelRecruits?: Maybe<AddAllStudyPanelRecruitsPayload>;
  readonly addAnswer?: Maybe<AddAnswerPayload>;
  readonly addElement?: Maybe<AddElementPayload>;
  /** Add a payment method for a tenant based on a Stripe session Id */
  readonly addPaymentMethod?: Maybe<AddPaymentMethodPayload>;
  /** Adds a row to an element. */
  readonly addRow?: Maybe<AddRowPayload>;
  readonly addSegment?: Maybe<AddSegmentPayload>;
  /** Add panelist ids to a recruiting round's meta. */
  readonly addStudyPanelRecruits?: Maybe<AddStudyPanelRecruitsPayload>;
  /** Approve a set of respondents that match provided criteria */
  readonly approveAllRespondents?: Maybe<ApproveAllRespondentsPayload>;
  readonly approveRespondent?: Maybe<ApproveRespondentPayload>;
  /** Approve a list of respondents using respondent_ids or participant_ids */
  readonly approveRespondents?: Maybe<ApproveRespondentsPayload>;
  readonly cancelStudy?: Maybe<CancelStudyPayload>;
  readonly changeElementType?: Maybe<ChangeElementTypePayload>;
  readonly changeRecruitMetrics?: Maybe<ChangeRecruitMetricsPayload>;
  readonly changeRecruitType?: Maybe<ChangeRecruitTypePayload>;
  readonly changeUsePreviousAnswersElement?: Maybe<ChangeUsePreviousAnswersElementPayload>;
  readonly checkPasswordStrength?: Maybe<CheckPasswordStrengthPayload>;
  /** Clear labels for all elements on a screener */
  readonly clearLabels?: Maybe<ClearLabelsPayload>;
  readonly completeStudy?: Maybe<CompleteStudyPayload>;
  readonly createAsset?: Maybe<CreateAssetPayload>;
  readonly createExport: CreateExportPayload;
  readonly createRecruit?: Maybe<CreateRecruitPayload>;
  readonly createRecruitOtp?: Maybe<CreateRecruitOtpPayload>;
  readonly createRemindersTemplate?: Maybe<CreateRemindersTemplatePayload>;
  readonly createStudy?: Maybe<CreateStudyPayload>;
  readonly createTag?: Maybe<CreateTagPayload>;
  readonly deleteAnswer?: Maybe<DeleteAnswerPayload>;
  readonly deleteAsset?: Maybe<DeleteAssetPayload>;
  readonly deleteCharacteristic?: Maybe<DeleteCharacteristicPayload>;
  readonly deleteElement?: Maybe<DeleteElementPayload>;
  readonly deleteFilter?: Maybe<DeleteFilterPayload>;
  /** Deletes a recruiting round and associated RecruitPersons. Only supported for Recruits that have not been started */
  readonly deleteRecruit?: Maybe<DeleteRecruitPayload>;
  readonly deleteRemindersTemplate?: Maybe<DeleteRemindersTemplatePayload>;
  /** Delete a specified element's row. */
  readonly deleteRow?: Maybe<DeleteRowPayload>;
  readonly deleteSegment?: Maybe<DeleteSegmentPayload>;
  /** Removes StudyAvailabilitySlot and linked Respondent slots, reminders etc */
  readonly deleteStudyAvailabilitySlot?: Maybe<DeleteStudyAvailabilitySlotPayload>;
  readonly deleteTag?: Maybe<DeleteTagPayload>;
  /** Deny a set of respondents that match provided criteria */
  readonly denyAllRespondents?: Maybe<DenyAllRespondentsPayload>;
  readonly denyRespondent?: Maybe<DenyRespondentPayload>;
  /** Deny a list of respondents by providing respondent or participant ids */
  readonly denyRespondents?: Maybe<DenyRespondentsPayload>;
  readonly duplicateElement?: Maybe<DuplicateElementPayload>;
  readonly duplicateStudy?: Maybe<DuplicateStudyPayload>;
  /** Deposits specified amount into specified recruit */
  readonly fundRecruit?: Maybe<FundRecruitPayload>;
  /** Rate a set of participants that match provided criteria */
  readonly getGivePointsToAllParticipantsCosts?: Maybe<GetGivePointsToAllParticipantsCostsPayload>;
  /** Gives points to a list of participants, separate from the standard incentive flow. */
  readonly getGivePointsToParticipantsCosts?: Maybe<GetGivePointsToParticipantsCostsPayload>;
  /** Gives points to participants that match provided criteria */
  readonly givePointsToAllParticipants?: Maybe<GivePointsToAllParticipantsPayload>;
  readonly givePointsToPanelist?: Maybe<GivePointsToPanelistPayload>;
  /** Gives points to a list of participants, separate from the standard incentive flow. */
  readonly givePointsToParticipants?: Maybe<GivePointsToParticipantsPayload>;
  readonly insertAnswers?: Maybe<InsertAnswersPayload>;
  readonly insertRows?: Maybe<InsertRowsPayload>;
  /** Starts inviting on ALL started recruiting rounds */
  readonly launchStudy?: Maybe<LaunchStudyPayload>;
  /** Sets a respondent's status to "Interviewed" in cases where scheduling happened outside of HubUX */
  readonly markRespondentInterviewed?: Maybe<MarkRespondentInterviewedPayload>;
  readonly moveAnswer?: Maybe<MoveAnswerPayload>;
  readonly moveElement?: Maybe<MoveElementPayload>;
  /** Moves a row within an element to the specified position. */
  readonly moveRow?: Maybe<MoveRowPayload>;
  readonly obtainSsoToken: ObtainSsoTokenPayload;
  /** Changes Recruit status to Paused which will stop sending invites */
  readonly pauseRecruit?: Maybe<PauseRecruitPayload>;
  readonly payForRecruit?: Maybe<PayForRecruitPayload>;
  /** Rate a set of participants that match provided criteria */
  readonly rateAllParticipants?: Maybe<RateAllParticipantsPayload>;
  /** Rates participation for a list of participants */
  readonly rateParticipants?: Maybe<RateParticipantsPayload>;
  /**
   * Rates participation.
   *
   * If no_show is True, no_show is set to True and rating is set to 0.
   * If rating is 1-5, rating is set and no_show is set to False
   */
  readonly rateParticipation?: Maybe<RateParticipationPayload>;
  readonly redeemRewards?: Maybe<RedeemRewardsPayload>;
  readonly refreshToken?: Maybe<Refresh>;
  /** Registers a new Panelist for accessing the Panelist Portal */
  readonly registerPanelist?: Maybe<RegisterPanelistPayload>;
  /** Registers a new user to use the app */
  readonly registerUser?: Maybe<RegisterUserPayload>;
  /** Remove all PanelRecruits from a study. */
  readonly removeAllStudyPanelRecruits?: Maybe<RemoveAllStudyPanelRecruitsPayload>;
  readonly removePanelist?: Maybe<RemovePanelistPayload>;
  /** Remove PanelRecruits from a study. */
  readonly removeStudyPanelRecruits?: Maybe<RemoveStudyPanelRecruitsPayload>;
  readonly reopenStudy?: Maybe<ReopenStudyPayload>;
  /**
   * Removes respondent from slot and sends email to respondent
   * to schedule another one if reschedule is True
   */
  readonly resetScheduledSlot?: Maybe<ResetScheduledSlotPayload>;
  /** Continues recruits which had been paused */
  readonly resumeRecruit?: Maybe<ResumeRecruitPayload>;
  /** Rewards a participant with cash or points for a completed study */
  readonly rewardAllParticipants?: Maybe<RewardAllParticipantsPayload>;
  /** Rewards a participant with cash or points for a completed study */
  readonly rewardParticipant?: Maybe<RewardParticipantPayload>;
  /** Rewards a list of participants with cash or points for a completed study */
  readonly rewardParticipants?: Maybe<RewardParticipantsPayload>;
  readonly saveAutomatedEmail?: Maybe<SaveAutomatedEmailPayload>;
  readonly saveCharacteristic?: Maybe<SaveCharacteristicPayload>;
  readonly saveEmailTemplate?: Maybe<SaveEmailTemplatePayload>;
  readonly saveFilter?: Maybe<SaveFilterPayload>;
  /** Schedules a set of participants in a given focus group slot */
  readonly scheduleAllFocusGroupParticipants?: Maybe<ScheduleAllFocusGroupParticipantsPayload>;
  /** Adds a list of participants to a focus group slot */
  readonly scheduleFocusGroupParticipants?: Maybe<ScheduleFocusGroupParticipantsPayload>;
  /** Schedules a participant in the given slot */
  readonly scheduleParticipant?: Maybe<ScheduleParticipantPayload>;
  readonly sendStudyEmail?: Maybe<SendStudyEmailPayload>;
  readonly sendTestParticipantInvites?: Maybe<SendTestParticipantInvitesPayload>;
  /**
   * Starting a recruiting round creates the RecruitPerson records. It does not send any invites.
   * Invites are sent when the project is set to Active or periodically by the background worker (tasks.py)
   */
  readonly startRecruit?: Maybe<StartRecruitPayload>;
  readonly switchProfile?: Maybe<SwitchProfilePayload>;
  /** Adds the user object to the response when logging in */
  readonly tokenAuth?: Maybe<ObtainJsonWebToken>;
  readonly updateAnswer?: Maybe<UpdateAnswerPayload>;
  readonly updateElement?: Maybe<UpdateElementPayload>;
  readonly updateGridAnswer?: Maybe<UpdateGridAnswerPayload>;
  readonly updateNote?: Maybe<UpdateNotePayload>;
  readonly updatePanel?: Maybe<UpdatePanelPayload>;
  readonly updatePanelist: UpdatePanelistPayload;
  readonly updatePerson: UpdatePersonPayload;
  readonly updateRecruit?: Maybe<UpdateRecruitPayload>;
  readonly updateRecruitDetails?: Maybe<UpdateRecruitDetailsPayload>;
  /** Updates a reminders template e.g. content, when it sends, if it's enabled */
  readonly updateRemindersTemplate?: Maybe<UpdateRemindersTemplatePayload>;
  /** Update's a specified row's values. */
  readonly updateRow?: Maybe<UpdateRowPayload>;
  readonly updateScreener?: Maybe<UpdateScreenerPayload>;
  readonly updateSegment?: Maybe<UpdateSegmentPayload>;
  readonly updateStudy?: Maybe<UpdateStudyPayload>;
  readonly updateStudySessions?: Maybe<UpdateStudySessionsPayload>;
  readonly updateTag?: Maybe<UpdateTagPayload>;
  readonly updateWorkspaceSettings?: Maybe<UpdateWorkspaceSettingsPayload>;
  readonly verifyToken?: Maybe<Verify>;
};

export type MutationAddAllStudyPanelRecruitsArgs = {
  input: AddAllStudyPanelRecruitsInput;
};

export type MutationAddAnswerArgs = {
  input: AddAnswerInput;
};

export type MutationAddElementArgs = {
  input: AddElementInput;
};

export type MutationAddPaymentMethodArgs = {
  input: AddPaymentMethodInput;
};

export type MutationAddRowArgs = {
  input: AddRowInput;
};

export type MutationAddSegmentArgs = {
  input: AddSegmentInput;
};

export type MutationAddStudyPanelRecruitsArgs = {
  input: AddStudyPanelRecruitsInput;
};

export type MutationApproveAllRespondentsArgs = {
  input: ApproveAllRespondentsInput;
};

export type MutationApproveRespondentArgs = {
  input: ApproveRespondentInput;
};

export type MutationApproveRespondentsArgs = {
  input: ApproveRespondentsInput;
};

export type MutationCancelStudyArgs = {
  input: CancelStudyInput;
};

export type MutationChangeElementTypeArgs = {
  input: ChangeElementTypeInput;
};

export type MutationChangeRecruitMetricsArgs = {
  input: ChangeRecruitMetricsInput;
};

export type MutationChangeRecruitTypeArgs = {
  input: ChangeRecruitTypeInput;
};

export type MutationChangeUsePreviousAnswersElementArgs = {
  input: ChangeUsePreviousAnswersElementInput;
};

export type MutationCheckPasswordStrengthArgs = {
  input: CheckPasswordStrengthInput;
};

export type MutationClearLabelsArgs = {
  input: ClearLabelsInput;
};

export type MutationCompleteStudyArgs = {
  input: CompleteStudyInput;
};

export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};

export type MutationCreateExportArgs = {
  input: CreateExportInput;
};

export type MutationCreateRecruitArgs = {
  input: CreateRecruitInput;
};

export type MutationCreateRecruitOtpArgs = {
  input: CreateRecruitOtpInput;
};

export type MutationCreateRemindersTemplateArgs = {
  input: CreateRemindersTemplateInput;
};

export type MutationCreateStudyArgs = {
  input: CreateStudyInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};

export type MutationDeleteCharacteristicArgs = {
  input: DeleteCharacteristicInput;
};

export type MutationDeleteElementArgs = {
  input: DeleteElementInput;
};

export type MutationDeleteFilterArgs = {
  input: DeleteFilterInput;
};

export type MutationDeleteRecruitArgs = {
  input: DeleteRecruitInput;
};

export type MutationDeleteRemindersTemplateArgs = {
  input: DeleteRemindersTemplateInput;
};

export type MutationDeleteRowArgs = {
  input: DeleteRowInput;
};

export type MutationDeleteSegmentArgs = {
  input: DeleteSegmentInput;
};

export type MutationDeleteStudyAvailabilitySlotArgs = {
  input: DeleteStudyAvailabilitySlotInput;
};

export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationDenyAllRespondentsArgs = {
  input: DenyAllRespondentsInput;
};

export type MutationDenyRespondentArgs = {
  input: DenyRespondentInput;
};

export type MutationDenyRespondentsArgs = {
  input: DenyRespondentsInput;
};

export type MutationDuplicateElementArgs = {
  input: DuplicateElementInput;
};

export type MutationDuplicateStudyArgs = {
  input: DuplicateStudyInput;
};

export type MutationFundRecruitArgs = {
  input: FundRecruitInput;
};

export type MutationGetGivePointsToAllParticipantsCostsArgs = {
  input: GetGivePointsToAllParticipantsCostsInput;
};

export type MutationGetGivePointsToParticipantsCostsArgs = {
  input: GetGivePointsToParticipantsCostsInput;
};

export type MutationGivePointsToAllParticipantsArgs = {
  input: GivePointsToAllParticipantsInput;
};

export type MutationGivePointsToPanelistArgs = {
  input: GivePointsToPanelistInput;
};

export type MutationGivePointsToParticipantsArgs = {
  input: GivePointsToParticipantsInput;
};

export type MutationInsertAnswersArgs = {
  input: InsertAnswersInput;
};

export type MutationInsertRowsArgs = {
  input: InsertRowsInput;
};

export type MutationLaunchStudyArgs = {
  input: LaunchStudyInput;
};

export type MutationMarkRespondentInterviewedArgs = {
  input: MarkRespondentInterviewedInput;
};

export type MutationMoveAnswerArgs = {
  input: MoveAnswerInput;
};

export type MutationMoveElementArgs = {
  input: MoveElementInput;
};

export type MutationMoveRowArgs = {
  input: MoveRowInput;
};

export type MutationObtainSsoTokenArgs = {
  input: ObtainSsoTokenInput;
};

export type MutationPauseRecruitArgs = {
  input: PauseRecruitInput;
};

export type MutationPayForRecruitArgs = {
  input: PayForRecruitInput;
};

export type MutationRateAllParticipantsArgs = {
  input: RateAllParticipantsInput;
};

export type MutationRateParticipantsArgs = {
  input: RateParticipantsInput;
};

export type MutationRateParticipationArgs = {
  input: RateParticipationInput;
};

export type MutationRedeemRewardsArgs = {
  input: RedeemRewardsInput;
};

export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterPanelistArgs = {
  input: RegisterPanelistInput;
};

export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationRemoveAllStudyPanelRecruitsArgs = {
  input: RemoveAllStudyPanelRecruitsInput;
};

export type MutationRemovePanelistArgs = {
  input: RemovePanelistInput;
};

export type MutationRemoveStudyPanelRecruitsArgs = {
  input: RemoveStudyPanelRecruitsInput;
};

export type MutationReopenStudyArgs = {
  input: ReopenStudyInput;
};

export type MutationResetScheduledSlotArgs = {
  input: ResetScheduledSlotInput;
};

export type MutationResumeRecruitArgs = {
  input: ResumeRecruitInput;
};

export type MutationRewardAllParticipantsArgs = {
  input: RewardAllParticipantsInput;
};

export type MutationRewardParticipantArgs = {
  input: RewardParticipantInput;
};

export type MutationRewardParticipantsArgs = {
  input: RewardParticipantsInput;
};

export type MutationSaveAutomatedEmailArgs = {
  input: SaveAutomatedEmailInput;
};

export type MutationSaveCharacteristicArgs = {
  input: SaveCharacteristicInput;
};

export type MutationSaveEmailTemplateArgs = {
  input: SaveEmailTemplateInput;
};

export type MutationSaveFilterArgs = {
  input: SaveFilterInput;
};

export type MutationScheduleAllFocusGroupParticipantsArgs = {
  input: ScheduleAllFocusGroupParticipantsInput;
};

export type MutationScheduleFocusGroupParticipantsArgs = {
  input: ScheduleFocusGroupParticipantsInput;
};

export type MutationScheduleParticipantArgs = {
  input: ScheduleParticipantInput;
};

export type MutationSendStudyEmailArgs = {
  input: SendStudyEmailInput;
};

export type MutationSendTestParticipantInvitesArgs = {
  input: SendTestParticipantInvitesInput;
};

export type MutationStartRecruitArgs = {
  input: StartRecruitInput;
};

export type MutationSwitchProfileArgs = {
  input: SwitchProfileInput;
};

export type MutationTokenAuthArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUpdateAnswerArgs = {
  input: UpdateAnswerInput;
};

export type MutationUpdateElementArgs = {
  input: UpdateElementInput;
};

export type MutationUpdateGridAnswerArgs = {
  input: UpdateGridAnswerInput;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdatePanelArgs = {
  input: UpdatePanelInput;
};

export type MutationUpdatePanelistArgs = {
  input: UpdatePanelistInput;
};

export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};

export type MutationUpdateRecruitArgs = {
  input: UpdateRecruitInput;
};

export type MutationUpdateRecruitDetailsArgs = {
  input: UpdateRecruitDetailsInput;
};

export type MutationUpdateRemindersTemplateArgs = {
  input: UpdateRemindersTemplateInput;
};

export type MutationUpdateRowArgs = {
  input: UpdateRowInput;
};

export type MutationUpdateScreenerArgs = {
  input: UpdateScreenerInput;
};

export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};

export type MutationUpdateStudyArgs = {
  input: UpdateStudyInput;
};

export type MutationUpdateStudySessionsArgs = {
  input: UpdateStudySessionsInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationUpdateWorkspaceSettingsArgs = {
  input: UpdateWorkspaceSettingsInput;
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars["String"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  readonly id: Scalars["ID"];
};

export type NoteNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly modifiedBy?: Maybe<UserNode>;
  readonly panelist?: Maybe<PanelistNode>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly tenant: TenantNode;
  readonly text?: Maybe<Scalars["String"]>;
};

export type NotificationsNode = {
  readonly studiesNeedingApprovals?: Maybe<ReadonlyArray<StudiesNeedingApprovalsNode>>;
  readonly studiesNeedingIncentives?: Maybe<ReadonlyArray<StudiesNeedingIncentivesNode>>;
};

export type OidcProviderNode = Node & {
  readonly authUrl?: Maybe<Scalars["String"]>;
  readonly clientId: Scalars["String"];
  readonly created: Scalars["DateTime"];
  readonly env?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly issuerUrl: Scalars["String"];
  readonly jwksUrl: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly name: Scalars["String"];
  readonly signingKeyJwk?: Maybe<Scalars["JSONString"]>;
  readonly tenant?: Maybe<TenantNode>;
  readonly type: AccountsOidcProviderTypeChoices | "%future added value" | `${AccountsOidcProviderTypeChoices}`;
  readonly userSet: UserNodeConnection;
};

export type OidcProviderNodeUserSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** Adds the user object to the response when logging in */
export type ObtainJsonWebToken = {
  readonly payload: Scalars["GenericScalar"];
  readonly refreshExpiresIn: Scalars["Int"];
  readonly token: Scalars["String"];
  readonly user?: Maybe<UserNode>;
};

export type ObtainSsoTokenInput = {
  readonly callbackUrl?: InputMaybe<Scalars["String"]>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly code?: InputMaybe<Scalars["String"]>;
  readonly env?: InputMaybe<Scalars["String"]>;
};

export type ObtainSsoTokenPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tokenSet: TokenSet;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars["String"]>;
};

export type Panel = {
  readonly characteristic?: Maybe<PanelCharacteristic>;
  readonly health?: Maybe<PanelHealth>;
  readonly healthSummary?: Maybe<PanelHealthSummaryNodeConnection>;
  readonly lastHealthRun?: Maybe<Scalars["DateTime"]>;
  readonly tenantCharacteristics?: Maybe<ReadonlyArray<Maybe<TenantPanelCharacteristic>>>;
  readonly totalPanelists?: Maybe<Scalars["Int"]>;
};

export type PanelCharacteristicArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type PanelHealthSummaryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  since: Scalars["DateTime"];
};

export type PanelAnswer = {
  readonly dId?: Maybe<Scalars["String"]>;
  readonly text?: Maybe<Scalars["String"]>;
};

export type PanelCharacteristic = {
  readonly name?: Maybe<Scalars["String"]>;
  readonly options?: Maybe<ReadonlyArray<Maybe<PanelCharacteristicOption>>>;
  readonly rows?: Maybe<ReadonlyArray<PanelCharacteristicRow>>;
  readonly totalCount?: Maybe<Scalars["Int"]>;
  readonly type?: Maybe<Scalars["String"]>;
};

export type PanelCharacteristicOption = {
  readonly count?: Maybe<Scalars["Int"]>;
  readonly name?: Maybe<Scalars["String"]>;
};

export type PanelCharacteristicRow = {
  readonly name?: Maybe<Scalars["String"]>;
  readonly options?: Maybe<ReadonlyArray<Maybe<PanelCharacteristicRowOption>>>;
};

export type PanelCharacteristicRowOption = {
  readonly count?: Maybe<Scalars["Int"]>;
  readonly name?: Maybe<Scalars["String"]>;
};

export type PanelHealth = {
  readonly approved?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly denied?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly incentivized?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly medianRating?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly noShow?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly overquota?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly qualified?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly rsvpAccepted?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly rsvpDeclined?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly screenersTaken?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly terminated?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly totalPanelists?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
  readonly totalParticipants?: Maybe<ReadonlyArray<Maybe<PanelHealthHistory>>>;
};

export type PanelHealthHistory = {
  readonly count?: Maybe<Scalars["String"]>;
  readonly month?: Maybe<Scalars["Int"]>;
  readonly year?: Maybe<Scalars["Int"]>;
};

export type PanelHealthSummaryNode = Node & {
  readonly activePanelists: Scalars["Int"];
  readonly atRiskPanelists: Scalars["Int"];
  readonly blacklisted: Scalars["Int"];
  readonly created: Scalars["DateTime"];
  readonly datetime: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly inactivePanelists: Scalars["Int"];
  readonly modified: Scalars["DateTime"];
  readonly newPanelists: Scalars["Int"];
  readonly reengagedPanelists: Scalars["Int"];
  readonly subscribed: Scalars["Int"];
  readonly tenant: TenantNode;
  readonly totalPanelists: Scalars["Int"];
  readonly unsubscribed: Scalars["Int"];
};

export type PanelHealthSummaryNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PanelHealthSummaryNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PanelHealthSummaryNode` and its cursor. */
export type PanelHealthSummaryNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PanelHealthSummaryNode>;
};

export type PanelNode = Node & {
  readonly export?: Maybe<ExportNode>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly panelistLayout: ReadonlyArray<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export const enum PanelRecruitFilterEnum {
  MatchedPanel = "MATCHED_PANEL",
  RecruitedPanel = "RECRUITED_PANEL",
}

export type PanelistBonusOrderConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PanelistBonusOrderEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PanelistBonusOrder` and its cursor. */
export type PanelistBonusOrderEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PanelistBonusOrderNode>;
};

export type PanelistBonusOrderNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly executedOn?: Maybe<Scalars["DateTime"]>;
  readonly givePointsPayment?: Maybe<GivePointsPaymentNode>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentiveOrder?: Maybe<RecruitTransactionNode>;
  readonly incentivePoints: Scalars["Int"];
  readonly incentiveType:
    | ScreenersIncentiveOrderIncentiveTypeChoices
    | "%future added value"
    | `${ScreenersIncentiveOrderIncentiveTypeChoices}`;
  readonly incentiveUsdCents: Scalars["Int"];
  readonly incentiveViProgramId?: Maybe<Scalars["String"]>;
  readonly incentiveViSku?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly panelist?: Maybe<PanelistNode>;
  readonly participant?: Maybe<ParticipantNode>;
  readonly placedByTenant: TenantNode;
  readonly placedByUser: UserNode;
  readonly reason:
    | ScreenersIncentiveOrderReasonChoices
    | "%future added value"
    | `${ScreenersIncentiveOrderReasonChoices}`;
  readonly status:
    | ScreenersIncentiveOrderStatusChoices
    | "%future added value"
    | `${ScreenersIncentiveOrderStatusChoices}`;
};

export type PanelistBonusOrderNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PanelistBonusOrderNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PanelistBonusOrderNode` and its cursor. */
export type PanelistBonusOrderNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PanelistBonusOrderNode>;
};

export type PanelistConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PanelistEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `Panelist` and its cursor. */
export type PanelistEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PanelistNode>;
};

export type PanelistMembershipNode = Node & {
  readonly blocked?: Maybe<Scalars["DateTime"]>;
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly joined: Scalars["DateTime"];
  readonly latestCompleteParticipant?: Maybe<ParticipantNode>;
  readonly latestCompleteScreenerDate?: Maybe<Scalars["String"]>;
  readonly latestCompleteScreenerName?: Maybe<Scalars["String"]>;
  readonly latestCompleteScreenerRespondent?: Maybe<RespondentNode>;
  readonly latestRatedParticipant?: Maybe<ParticipantNode>;
  readonly latestStudyDate?: Maybe<Scalars["String"]>;
  readonly latestStudyName?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly panelist: PanelistNode;
  readonly tenant: TenantNode;
};

export type PanelistMembershipNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PanelistMembershipNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PanelistMembershipNode` and its cursor. */
export type PanelistMembershipNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PanelistMembershipNode>;
};

export type PanelistNode = Node & {
  readonly availableRecruits: ReadonlyArray<RecruitNode>;
  readonly completedParticipation?: Maybe<ReadonlyArray<Maybe<ParticipantNode>>>;
  readonly completedStudiesCount?: Maybe<Scalars["Int"]>;
  readonly convertedRewardsBalance?: Maybe<Scalars["Float"]>;
  readonly created: Scalars["DateTime"];
  readonly customPanelistPortal?: Maybe<CustomPortalNode>;
  readonly dId?: Maybe<Scalars["UUID"]>;
  readonly deprecatedLatestCompleteParticipant?: Maybe<ParticipantNode>;
  readonly deprecatedLatestCompleteScreenerRespondent?: Maybe<RespondentNode>;
  readonly givepointspaymentSet: GivePointsPaymentNodeConnection;
  readonly globalCharacteristics: ReadonlyArray<GlobalCharacteristic>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentiveOrders: PanelistBonusOrderNodeConnection;
  readonly joined?: Maybe<Scalars["String"]>;
  readonly membership: PanelistMembershipNodeConnection;
  readonly modified: Scalars["DateTime"];
  readonly panelistCurrencyToPointsRatio?: Maybe<Scalars["Float"]>;
  readonly panelistMembership?: Maybe<PanelistMembershipNode>;
  readonly person?: Maybe<PersonNode>;
  readonly recruitStatus?: Maybe<Scalars["String"]>;
  readonly recruitedToStudy?: Maybe<Scalars["Boolean"]>;
  readonly redeemedRewardsPoints?: Maybe<Scalars["Int"]>;
  readonly responses: CharacteristicResponseNodeConnection;
  readonly rewardsBalance?: Maybe<Scalars["Int"]>;
  readonly selectedResponses: ReadonlyArray<SelectedResponse>;
  readonly studies: StudyNodeConnection;
  readonly studyParticipants?: Maybe<ReadonlyArray<Maybe<ParticipantNode>>>;
  readonly tenantForIncentives?: Maybe<TenantNode>;
  readonly tenantNote?: Maybe<NoteNode>;
  readonly tenants?: Maybe<TenantNodeConnection>;
  readonly totalIncentives?: Maybe<Scalars["Int"]>;
  readonly user?: Maybe<UserNode>;
};

export type PanelistNodeGivepointspaymentSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PanelistNodeIncentiveOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PanelistNodeMembershipArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PanelistNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  panelist?: InputMaybe<Scalars["ID"]>;
};

export type PanelistNodeStudiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PanelistNodeTenantsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PanelistNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PanelistNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PanelistNode` and its cursor. */
export type PanelistNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PanelistNode>;
};

export type ParticipantBonusPointsNode = {
  readonly clearedOn?: Maybe<Scalars["DateTime"]>;
  readonly orderDate: Scalars["DateTime"];
  readonly pointsAmount: Scalars["Int"];
};

export type ParticipantConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ParticipantEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `Participant` and its cursor. */
export type ParticipantEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ParticipantNode>;
};

export type ParticipantNode = Node & {
  readonly bonusPoints: ReadonlyArray<Maybe<ParticipantBonusPointsNode>>;
  readonly completedOn?: Maybe<Scalars["DateTime"]>;
  readonly created: Scalars["DateTime"];
  readonly dId?: Maybe<Scalars["UUID"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly imported: Scalars["Boolean"];
  readonly importedOn?: Maybe<Scalars["DateTime"]>;
  readonly incentiveClearedOn?: Maybe<Scalars["DateTime"]>;
  readonly incentiveOrders: PanelistBonusOrderNodeConnection;
  readonly incentivized: Scalars["Boolean"];
  readonly incentivizedAmount?: Maybe<Scalars["String"]>;
  readonly incentivizedAmountCurrency?: Maybe<
    | ScreenersParticipantIncentivizedAmountCurrencyChoices
    | "%future added value"
    | `${ScreenersParticipantIncentivizedAmountCurrencyChoices}`
  >;
  readonly incentivizedBy?: Maybe<UserNode>;
  readonly incentivizedOn?: Maybe<Scalars["DateTime"]>;
  readonly incentivizedPointAmount?: Maybe<Scalars["Int"]>;
  readonly incentivizedType?: Maybe<
    | ScreenersParticipantIncentivizedTypeChoices
    | "%future added value"
    | `${ScreenersParticipantIncentivizedTypeChoices}`
  >;
  readonly isTest?: Maybe<Scalars["Boolean"]>;
  readonly modified: Scalars["DateTime"];
  readonly noShow: Scalars["Boolean"];
  readonly panelistSet: PanelistNodeConnection;
  readonly panelistmembershipLatestcomplete: PanelistMembershipNodeConnection;
  readonly panelistmembershipLatestrated: PanelistMembershipNodeConnection;
  readonly rating: Scalars["Int"];
  readonly ratingOn?: Maybe<Scalars["DateTime"]>;
  readonly recruitPerson?: Maybe<RecruitPersonNode>;
  readonly respondent: RespondentNode;
  readonly rsvp: ScreenersParticipantRsvpChoices | "%future added value" | `${ScreenersParticipantRsvpChoices}`;
  readonly rsvpOn?: Maybe<Scalars["DateTime"]>;
  readonly study: StudyNode;
};

export type ParticipantNodeIncentiveOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ParticipantNodePanelistSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ParticipantNodePanelistmembershipLatestcompleteArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ParticipantNodePanelistmembershipLatestratedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ParticipantNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ParticipantNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ParticipantNode` and its cursor. */
export type ParticipantNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ParticipantNode>;
};

export type PauseRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Changes Recruit status to Paused which will stop sending invites */
export type PauseRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
};

export type PayForRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

export type PayForRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruitTransaction?: Maybe<RecruitTransactionNode>;
};

export type PersonNode = Node & {
  readonly avatarUrl?: Maybe<Scalars["String"]>;
  readonly country: ScreenersPersonCountryChoices | "%future added value" | `${ScreenersPersonCountryChoices}`;
  readonly created: Scalars["DateTime"];
  readonly email?: Maybe<Scalars["String"]>;
  readonly firstName?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly lastName?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly optIn: Scalars["Boolean"];
  readonly optOutDate?: Maybe<Scalars["DateTime"]>;
  readonly panelist?: Maybe<PanelistNode>;
  readonly panelistDId?: Maybe<Scalars["String"]>;
  readonly phoneNumber?: Maybe<Scalars["String"]>;
  readonly recruitpersonSet: RecruitPersonNodeConnection;
  readonly recruits: RecruitNodeConnection;
  readonly requirePhoneNumber: Scalars["Boolean"];
  readonly respondents: RespondentNodeConnection;
  readonly thirdPartyId?: Maybe<Scalars["String"]>;
  readonly thirdPartySource?: Maybe<Scalars["String"]>;
};

export type PersonNodeRecruitpersonSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PersonNodeRecruitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PersonNodeRespondentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PersonNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PersonNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PersonNode` and its cursor. */
export type PersonNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PersonNode>;
};

export type PortalCardLinkNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly linkText?: Maybe<Scalars["String"]>;
  readonly linkUrl?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly portalCard: PortalCardNode;
  readonly title?: Maybe<Scalars["String"]>;
};

export type PortalCardLinkNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PortalCardLinkNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PortalCardLinkNode` and its cursor. */
export type PortalCardLinkNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PortalCardLinkNode>;
};

export type PortalCardNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly description?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly links: PortalCardLinkNodeConnection;
  readonly modified: Scalars["DateTime"];
  readonly tenant: TenantNode;
  readonly title?: Maybe<Scalars["String"]>;
};

export type PortalCardNodeLinksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PortalCardNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PortalCardNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `PortalCardNode` and its cursor. */
export type PortalCardNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<PortalCardNode>;
};

export type PrivacyPolicyNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly language: Scalars["String"];
  readonly languageCode: Scalars["String"];
  readonly link: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly position: Scalars["Int"];
  readonly studySet: StudyNodeConnection;
};

export type PrivacyPolicyNodeStudySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ProfileNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly isActive: Scalars["Boolean"];
  readonly modified: Scalars["DateTime"];
  readonly tenant: TenantNode;
  readonly user: UserNode;
};

export type ProfileNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ProfileNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfileNode` and its cursor. */
export type ProfileNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ProfileNode>;
};

export type Query = {
  readonly assetUploadUrl: Scalars["String"];
  readonly personAvatarUploadUrl: Scalars["String"];
  readonly ping: Scalars["String"];
  readonly viewer: Viewer;
};

export type QueryAssetUploadUrlArgs = {
  fileName: Scalars["String"];
  fileType: Scalars["String"];
};

export type QueryPersonAvatarUploadUrlArgs = {
  fileName: Scalars["String"];
  fileType: Scalars["String"];
};

export type RateAllParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly noShow?: InputMaybe<Scalars["Boolean"]>;
  readonly rating?: InputMaybe<Scalars["Int"]>;
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Rate a set of participants that match provided criteria */
export type RateAllParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type RateParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly noShow?: InputMaybe<Scalars["Boolean"]>;
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly rating?: InputMaybe<Scalars["Int"]>;
  readonly studyId: Scalars["String"];
};

/** Rates participation for a list of participants */
export type RateParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type RateParticipationInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly noShow?: InputMaybe<Scalars["Boolean"]>;
  readonly participantId: Scalars["String"];
  readonly rating?: InputMaybe<Scalars["Int"]>;
};

/**
 * Rates participation.
 *
 * If no_show is True, no_show is set to True and rating is set to 0.
 * If rating is 1-5, rating is set and no_show is set to False
 */
export type RateParticipationPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly participant?: Maybe<ParticipantNode>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type RecruitEdge = {
  readonly node?: Maybe<RecruitNode>;
};

export type RecruitNode = Node & {
  readonly canHaveVoxpopmeGlobal?: Maybe<Scalars["Boolean"]>;
  readonly completedScreenerCount?: Maybe<Scalars["Int"]>;
  readonly created: Scalars["DateTime"];
  readonly creator?: Maybe<UserNode>;
  readonly dbId: Scalars["String"];
  readonly description?: Maybe<Scalars["String"]>;
  readonly eligiblePanelists?: Maybe<PanelistConnection>;
  readonly export?: Maybe<ExportNode>;
  readonly exports: ExportNodeConnection;
  readonly fee?: Maybe<Scalars["Decimal"]>;
  readonly finishedOn?: Maybe<Scalars["DateTime"]>;
  readonly goal?: Maybe<Scalars["Int"]>;
  readonly goalPaidForCount?: Maybe<Scalars["Int"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentive?: Maybe<Scalars["String"]>;
  readonly incentiveCurrency?: Maybe<
    ScreenersRecruitIncentiveCurrencyChoices | "%future added value" | `${ScreenersRecruitIncentiveCurrencyChoices}`
  >;
  readonly incentivePoints?: Maybe<Scalars["Int"]>;
  readonly incentiveType?: Maybe<
    ScreenersRecruitIncentiveTypeChoices | "%future added value" | `${ScreenersRecruitIncentiveTypeChoices}`
  >;
  readonly incentiveUsd?: Maybe<Scalars["Decimal"]>;
  readonly incentivesClearDate?: Maybe<Scalars["DateTime"]>;
  readonly invitesSentCount?: Maybe<Scalars["Int"]>;
  readonly isTest: Scalars["Boolean"];
  readonly jsonSettings?: Maybe<Scalars["JSONString"]>;
  readonly meta?: Maybe<Scalars["GenericScalar"]>;
  readonly modified: Scalars["DateTime"];
  readonly name?: Maybe<Scalars["String"]>;
  readonly needsReviewCount?: Maybe<Scalars["Int"]>;
  readonly openedScreenerCount?: Maybe<Scalars["Int"]>;
  readonly overNeedsReviewLimit?: Maybe<Scalars["Boolean"]>;
  readonly participantBaseCost?: Maybe<Scalars["String"]>;
  readonly people: PersonNodeConnection;
  readonly progressTowardGoalCount?: Maybe<Scalars["Int"]>;
  readonly qualifiedCount?: Maybe<Scalars["Int"]>;
  readonly recruitPersonCount?: Maybe<Scalars["Int"]>;
  readonly recruitTransactions: RecruitTransactionNodeConnection;
  readonly recruitpersonSet: RecruitPersonNodeConnection;
  readonly remindersEnabled: Scalars["Boolean"];
  readonly respondents: RespondentNodeConnection;
  readonly rewardedCount?: Maybe<Scalars["Int"]>;
  readonly screenerLink: Scalars["String"];
  readonly settings?: Maybe<Scalars["JSONString"]>;
  readonly sid?: Maybe<Scalars["String"]>;
  readonly startDate?: Maybe<Scalars["DateTime"]>;
  readonly startedScreenerCount?: Maybe<Scalars["Int"]>;
  readonly status: ScreenersRecruitStatusChoices | "%future added value" | `${ScreenersRecruitStatusChoices}`;
  readonly study: StudyNode;
  readonly studyIteration: Scalars["Int"];
  readonly thirdPartyId?: Maybe<Scalars["String"]>;
  readonly totalDeposits?: Maybe<Scalars["Decimal"]>;
  readonly totalPayouts?: Maybe<Scalars["Decimal"]>;
  readonly totalRefunds?: Maybe<Scalars["Decimal"]>;
  readonly totalRefundsDue?: Maybe<Scalars["Decimal"]>;
  readonly totalSpend?: Maybe<Scalars["Decimal"]>;
  readonly type?: Maybe<ScreenersRecruitTypeChoices | "%future added value" | `${ScreenersRecruitTypeChoices}`>;
  readonly underGoal?: Maybe<Scalars["Boolean"]>;
  readonly vpmgAge?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly vpmgCountry?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly vpmgEthnicity?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly vpmgGender?: Maybe<Scalars["String"]>;
  readonly vpmgJobtitles?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly vpmgProfessionalindustries?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly vpmgTopics?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly vpmgType?: Maybe<Scalars["String"]>;
};

export type RecruitNodeEligiblePanelistsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  panelFilters?: InputMaybe<Scalars["GenericScalar"]>;
  panelRecruitFilter?: InputMaybe<PanelRecruitFilterEnum>;
  panelSettings?: InputMaybe<Scalars["String"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type RecruitNodeExportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RecruitNodePeopleArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RecruitNodeRecruitTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RecruitNodeRecruitpersonSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RecruitNodeRespondentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RecruitNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RecruitNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RecruitNode` and its cursor. */
export type RecruitNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RecruitNode>;
};

export type RecruitPersonNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly emailOpenedOn?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly invitedOn?: Maybe<Scalars["DateTime"]>;
  readonly modified: Scalars["DateTime"];
  readonly participant: ParticipantNodeConnection;
  readonly person: PersonNode;
  readonly recruit: RecruitNode;
  readonly reminder1Sent?: Maybe<Scalars["DateTime"]>;
  readonly reminder2Sent?: Maybe<Scalars["DateTime"]>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly status:
    | ScreenersRecruitPersonStatusChoices
    | "%future added value"
    | `${ScreenersRecruitPersonStatusChoices}`;
  readonly study: StudyNode;
  readonly studyIteration: Scalars["Int"];
};

export type RecruitPersonNodeParticipantArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RecruitPersonNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RecruitPersonNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RecruitPersonNode` and its cursor. */
export type RecruitPersonNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RecruitPersonNode>;
};

export type RecruitTransactionNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentiveOrder?: Maybe<PanelistBonusOrderNode>;
  readonly modified: Scalars["DateTime"];
  readonly paymentId?: Maybe<Scalars["String"]>;
  readonly recruit: RecruitNode;
  readonly tenant: TenantNode;
  readonly transactionType: TransactionType | "%future added value" | `${TransactionType}`;
  readonly value: Scalars["Decimal"];
  readonly valueCurrency:
    | ScreenersRecruitTransactionValueCurrencyChoices
    | "%future added value"
    | `${ScreenersRecruitTransactionValueCurrencyChoices}`;
};

export type RecruitTransactionNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RecruitTransactionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RecruitTransactionNode` and its cursor. */
export type RecruitTransactionNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RecruitTransactionNode>;
};

export type RedeemRewardsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly points: Scalars["Int"];
  readonly programId: Scalars["String"];
  readonly rewardCardSku: Scalars["String"];
};

export type RedeemRewardsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly panelist?: Maybe<PanelistNode>;
};

export type Refresh = {
  readonly payload: Scalars["GenericScalar"];
  readonly refreshExpiresIn: Scalars["Int"];
  readonly token: Scalars["String"];
};

export type RegisterPanelistInput = {
  readonly agreements: ReadonlyArray<Scalars["String"]>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly customLandingHostname: Scalars["String"];
  readonly email: Scalars["String"];
  readonly firstName: Scalars["String"];
  readonly lastName: Scalars["String"];
  readonly otpValue?: InputMaybe<Scalars["String"]>;
  readonly password: Scalars["String"];
};

/** Registers a new Panelist for accessing the Panelist Portal */
export type RegisterPanelistPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly user?: Maybe<UserNode>;
};

export type RegisterUserInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly email: Scalars["String"];
  readonly emailVerificationCode: Scalars["String"];
  readonly firstName: Scalars["String"];
  readonly lastName: Scalars["String"];
  readonly password: Scalars["String"];
  readonly tenantName: Scalars["String"];
};

/** Registers a new user to use the app */
export type RegisterUserPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant: TenantNode;
  readonly tokenSet?: Maybe<TokenSet>;
  readonly user: UserNode;
};

export type ReminderTemplateVersionsNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly meetingType:
    | ScreenersReminderTemplateVersionMeetingTypeChoices
    | "%future added value"
    | `${ScreenersReminderTemplateVersionMeetingTypeChoices}`;
  readonly message: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly reminderTemplate: RemindersTemplatesNode;
  readonly subject: Scalars["String"];
};

export type ReminderTemplateVersionsNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ReminderTemplateVersionsNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ReminderTemplateVersionsNode` and its cursor. */
export type ReminderTemplateVersionsNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<ReminderTemplateVersionsNode>;
};

export type RemindersNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly date: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly respondent?: Maybe<RespondentNode>;
  readonly status: ScreenersReminderStatusChoices | "%future added value" | `${ScreenersReminderStatusChoices}`;
  readonly studySlot?: Maybe<StudyAvailabilitySlotNode>;
  readonly template: RemindersTemplatesNode;
};

export type RemindersNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RemindersNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RemindersNode` and its cursor. */
export type RemindersNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RemindersNode>;
};

export type RemindersTemplatesNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly daysBefore: Scalars["Int"];
  readonly deprecatedMessage?: Maybe<Scalars["String"]>;
  readonly deprecatedSubject?: Maybe<Scalars["String"]>;
  readonly enabled: Scalars["Boolean"];
  readonly hoursBefore: Scalars["Int"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly inPersonMeeting?: Maybe<ReminderTemplateVersionsNode>;
  readonly minsBefore: Scalars["Int"];
  readonly modified: Scalars["DateTime"];
  readonly reminders: RemindersNodeConnection;
  readonly study: StudyNode;
  readonly versions: ReminderTemplateVersionsNodeConnection;
  readonly virtualMeeting?: Maybe<ReminderTemplateVersionsNode>;
};

export type RemindersTemplatesNodeRemindersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RemindersTemplatesNodeVersionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RemindersTemplatesNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RemindersTemplatesNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RemindersTemplatesNode` and its cursor. */
export type RemindersTemplatesNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RemindersTemplatesNode>;
};

export type RemoveAllStudyPanelRecruitsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly excludeIds: ReadonlyArray<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Remove all PanelRecruits from a study. */
export type RemoveAllStudyPanelRecruitsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Errors>>>;
  readonly study?: Maybe<StudyNode>;
};

export type RemovePanelistInput = {
  readonly block?: InputMaybe<Scalars["Boolean"]>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly panelistId: Scalars["String"];
};

export type RemovePanelistPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly panelist?: Maybe<PanelistNode>;
};

export type RemoveStudyPanelRecruitsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly panelistIds: ReadonlyArray<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Remove PanelRecruits from a study. */
export type RemoveStudyPanelRecruitsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<Errors>>>;
  readonly study?: Maybe<StudyNode>;
};

export type ReopenStudyInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type ReopenStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type ResetScheduledSlotInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly reschedule: Scalars["Boolean"];
  readonly respondentId: Scalars["String"];
  readonly studyId: Scalars["String"];
};

/**
 * Removes respondent from slot and sends email to respondent
 * to schedule another one if reschedule is True
 */
export type ResetScheduledSlotPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type RespondentNode = Node & {
  readonly approvalStatus?: Maybe<
    ScreenersRespondentApprovalStatusChoices | "%future added value" | `${ScreenersRespondentApprovalStatusChoices}`
  >;
  readonly approvedOn?: Maybe<Scalars["DateTime"]>;
  readonly browser?: Maybe<Scalars["String"]>;
  readonly canHaveBonusIncentive?: Maybe<Scalars["Boolean"]>;
  readonly completedOn?: Maybe<Scalars["DateTime"]>;
  readonly created: Scalars["DateTime"];
  readonly dId?: Maybe<Scalars["UUID"]>;
  readonly device?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly ip?: Maybe<Scalars["String"]>;
  readonly masterStatus?: Maybe<
    ScreenersRespondentMasterStatusChoices | "%future added value" | `${ScreenersRespondentMasterStatusChoices}`
  >;
  readonly modified: Scalars["DateTime"];
  readonly note?: Maybe<NoteNode>;
  readonly os?: Maybe<Scalars["String"]>;
  readonly panelistSet: PanelistNodeConnection;
  readonly panelistmembershipLatestcomplete: PanelistMembershipNodeConnection;
  readonly participant?: Maybe<ParticipantNode>;
  readonly person?: Maybe<PersonNode>;
  readonly rQuery?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
  readonly recruitmentSourceId?: Maybe<Scalars["String"]>;
  readonly recruitpersonSet: RecruitPersonNodeConnection;
  readonly referrer?: Maybe<Scalars["String"]>;
  readonly reminders: RemindersNodeConnection;
  readonly responses: ElementResponseNodeConnection;
  readonly rhAnswer?: Maybe<Scalars["String"]>;
  readonly rhResults: Scalars["JSONString"];
  readonly scheduledSlots: ReadonlyArray<RespondentScheduledSlotNode>;
  readonly screener: ScreenerNode;
  readonly screenerResults?: Maybe<Scalars["JSONString"]>;
  readonly segmentResponses: SegmentResponseNodeConnection;
  readonly sourceGroup?: Maybe<Scalars["String"]>;
  readonly startedOn?: Maybe<Scalars["DateTime"]>;
  readonly status?: Maybe<
    ScreenersRespondentStatusChoices | "%future added value" | `${ScreenersRespondentStatusChoices}`
  >;
  readonly tdResults: Scalars["JSONString"];
  readonly thirdPartyId?: Maybe<Scalars["String"]>;
  readonly thirdPartySource?: Maybe<Scalars["String"]>;
  readonly timeZone?: Maybe<Scalars["String"]>;
};

export type RespondentNodePanelistSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RespondentNodePanelistmembershipLatestcompleteArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RespondentNodeRecruitpersonSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RespondentNodeRemindersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RespondentNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RespondentNodeSegmentResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RespondentNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RespondentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RespondentNode` and its cursor. */
export type RespondentNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RespondentNode>;
};

export type RespondentScheduledSlotNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly date: Scalars["Date"];
  readonly end: Scalars["DateTime"];
  readonly eventId?: Maybe<Scalars["UUID"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly interviewed: Scalars["Boolean"];
  readonly meetingBotId?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly respondent?: Maybe<RespondentNode>;
  readonly session?: Maybe<StudySessionNode>;
  readonly start: Scalars["DateTime"];
  readonly study: StudyNode;
  readonly studyAvailabilitySlot?: Maybe<StudyAvailabilitySlotNode>;
};

export type RespondentScheduledSlotNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RespondentScheduledSlotNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RespondentScheduledSlotNode` and its cursor. */
export type RespondentScheduledSlotNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RespondentScheduledSlotNode>;
};

export type ResponseSummaryNode = {
  readonly rsvpAccepted?: Maybe<TotalQualedNode>;
  readonly rsvpDeclined?: Maybe<TotalQualedNode>;
  readonly rsvpMaybe?: Maybe<TotalQualedNode>;
  readonly screenerDropouts?: Maybe<TotalQualedNode>;
  readonly screenerQualified?: Maybe<TotalQualedNode>;
  readonly screenerTermed?: Maybe<TotalQualedNode>;
  readonly totalNeeded?: Maybe<TotalQualedNode>;
  readonly totalStarts?: Maybe<TotalQualedNode>;
  readonly uniqueLinkClicks?: Maybe<TotalQualedNode>;
};

/** An enumeration. */
export const enum ResponseValidationType {
  Al = "AL",
  Am = "AM",
  Be = "BE",
  Ex = "EX",
}

export type ResumeRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/** Continues recruits which had been paused */
export type ResumeRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
};

export type RewardAllParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Rewards a participant with cash or points for a completed study */
export type RewardAllParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type RewardCardNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly faceplateUrl: Scalars["String"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly sku: Scalars["String"];
};

export type RewardCardNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RewardCardNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RewardCardNode` and its cursor. */
export type RewardCardNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RewardCardNode>;
};

export type RewardCardObject = {
  readonly faceplateUrl?: Maybe<Scalars["String"]>;
  readonly maxAmount?: Maybe<Scalars["Float"]>;
  readonly minAmount?: Maybe<Scalars["Float"]>;
  readonly name?: Maybe<Scalars["String"]>;
  readonly programId?: Maybe<Scalars["String"]>;
  readonly sku?: Maybe<Scalars["String"]>;
};

export type RewardParticipantInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantId: Scalars["String"];
};

/** Rewards a participant with cash or points for a completed study */
export type RewardParticipantPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly participant?: Maybe<ParticipantNode>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type RewardParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly studyId: Scalars["String"];
};

/** Rewards a list of participants with cash or points for a completed study */
export type RewardParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type RowNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  readonly element: ElementNode;
  readonly gridAnswers: GridAnswerNodeConnection;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly position: Scalars["Int"];
  readonly responses: ElementResponseNodeConnection;
  readonly rows: ElementResponseAnswerNodeConnection;
  readonly text: Scalars["String"];
};

export type RowNodeGridAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RowNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RowNodeRowsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RowNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RowNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `RowNode` and its cursor. */
export type RowNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<RowNode>;
};

export type SaveAutomatedEmailInput = {
  readonly assets?: InputMaybe<ReadonlyArray<InputMaybe<AutomatedEmailAssetInput>>>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly ctaLabel?: InputMaybe<Scalars["String"]>;
  readonly ctaUrl?: InputMaybe<Scalars["String"]>;
  readonly enabled?: InputMaybe<Scalars["Boolean"]>;
  readonly message?: InputMaybe<Scalars["String"]>;
  readonly replyTo?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
  readonly subject?: InputMaybe<Scalars["String"]>;
  readonly type: Scalars["String"];
};

export type SaveAutomatedEmailPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type SaveCharacteristicInput = {
  readonly answers?: InputMaybe<ReadonlyArray<InputMaybe<CharacteristicAnswerInput>>>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementMeta?: InputMaybe<CharacteristicElementMetaInput>;
  readonly id?: InputMaybe<Scalars["String"]>;
  readonly importKey?: InputMaybe<Scalars["String"]>;
  readonly rows?: InputMaybe<ReadonlyArray<InputMaybe<CharacteristicRowInput>>>;
  readonly shortName?: InputMaybe<Scalars["String"]>;
  readonly showToPanelists?: InputMaybe<Scalars["Boolean"]>;
  readonly text?: InputMaybe<Scalars["String"]>;
  readonly type?: InputMaybe<Scalars["String"]>;
};

export type SaveCharacteristicPayload = {
  readonly characteristic?: Maybe<CharacteristicNode>;
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export type SaveEmailTemplateInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly message?: InputMaybe<Scalars["String"]>;
  readonly name: Scalars["String"];
  readonly replyTo?: InputMaybe<Scalars["String"]>;
  readonly subject?: InputMaybe<Scalars["String"]>;
};

export type SaveEmailTemplatePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export type SaveFilterInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly filters: Scalars["JSONString"];
  readonly name: Scalars["String"];
  readonly studyId?: InputMaybe<Scalars["String"]>;
  readonly type: Scalars["String"];
  readonly userId?: InputMaybe<Scalars["String"]>;
};

export type SaveFilterPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly filter: FilterNode;
  readonly study?: Maybe<StudyNode>;
  readonly tenant: TenantNode;
  readonly user?: Maybe<UserNode>;
};

export type ScheduleAllFocusGroupParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly countToSelect?: InputMaybe<Scalars["Int"]>;
  readonly excludeIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  readonly slotId?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
  readonly tab?: InputMaybe<Scalars["String"]>;
};

/** Schedules a set of participants in a given focus group slot */
export type ScheduleAllFocusGroupParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type ScheduleFocusGroupParticipantsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly slotId?: InputMaybe<Scalars["String"]>;
};

/** Adds a list of participants to a focus group slot */
export type ScheduleFocusGroupParticipantsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type ScheduleParticipantInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly participantId: Scalars["String"];
  readonly slotId: Scalars["String"];
};

/** Schedules a participant in the given slot */
export type ScheduleParticipantPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly respondent?: Maybe<RespondentNode>;
  readonly study?: Maybe<StudyNode>;
};

export type ScreenerElementType = {
  readonly count: Scalars["Int"];
  readonly elementType: Scalars["String"];
};

export type ScreenerNode = Node & {
  readonly created: Scalars["DateTime"];
  readonly description?: Maybe<Scalars["String"]>;
  readonly elementTypeCounts: ReadonlyArray<Maybe<ScreenerElementType>>;
  readonly elements?: Maybe<ElementNodeConnection>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  /** Send over-quota Respondents to this URL */
  readonly overquotaExitLink?: Maybe<Scalars["String"]>;
  readonly quotaExitDescription: Scalars["String"];
  /** Send in-quota Respondents to this URL */
  readonly quotaExitLink?: Maybe<Scalars["String"]>;
  /** In a study with auto-approve enabled, and if the screener has no <strong>quota end-of-survey redirect</strong>, appears at the end of the screener for a Respondent approved within quota. */
  readonly quotaExitTitle: Scalars["String"];
  readonly respondents?: Maybe<RespondentNodeConnection>;
  readonly segments: SegmentNodeConnection;
  readonly study: StudyNode;
  readonly terminateRespondent:
    | ScreenersScreenerTerminateRespondentChoices
    | "%future added value"
    | `${ScreenersScreenerTerminateRespondentChoices}`;
  readonly terminatedExitDescription: Scalars["String"];
  /** Send terminated Respondents to this URL */
  readonly terminatedExitLink?: Maybe<Scalars["String"]>;
  /** If the screener has no <strong>termed end-of-survey redirect</strong>, and if the CustomScreener has no <strong>termed end-of-survey</strong> title, appears when a Respondent is terminated. */
  readonly terminatedExitTitle: Scalars["String"];
  readonly title?: Maybe<Scalars["String"]>;
};

export type ScreenerNodeElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_In?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["ID"]>>>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ScreenerNodeRespondentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ScreenerNodeSegmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** An enumeration. */
export const enum ScreenersAutomatedEmailTypeChoices {
  /** Approved */
  A = "A",
  /** Denied */
  D = "D",
  /** Needs Review */
  Nr = "NR",
  /** Over Quota */
  Oq = "OQ",
  /** Rated */
  Rt = "RT",
  /** Completed */
  Rw = "RW",
  /** Scheduled */
  S = "S",
  /** Termed */
  T = "T",
}

/** An enumeration. */
export const enum ScreenersCharacteristicTypeChoices {
  /** Date */
  Dt = "DT",
  /** Grid */
  Gs = "GS",
  /** Multi Select */
  Ms = "MS",
  /** Number */
  Nm = "NM",
  /** Open End */
  Oe = "OE",
  /** Single Select */
  Ss = "SS",
}

/** An enumeration. */
export const enum ScreenersElementResponseAnswerStatusChoices {
  /** over quota */
  Oq = "OQ",
  /** met quota */
  Q = "Q",
  /** terminated */
  T = "T",
}

/** An enumeration. */
export const enum ScreenersElementTypeChoices {
  /** Audition – Video */
  Av = "AV",
  /** Comment */
  Cm = "CM",
  /** Dropdown Select */
  Ds = "DS",
  /** Date */
  Dt = "DT",
  /** Files */
  Fl = "FL",
  /** Grid */
  Gs = "GS",
  /** Multi Select */
  Ms = "MS",
  /** Number */
  Nm = "NM",
  /** Open End */
  Oe = "OE",
  /** Audition – Text */
  Rh = "RH",
  /** Rank items */
  Rk = "RK",
  /** Single Select */
  Ss = "SS",
  /** Video */
  Vd = "VD",
}

/** An enumeration. */
export const enum ScreenersFilterTypeChoices {
  /** Panelist */
  Pn = "PN",
  /** Participant */
  Pt = "PT",
}

/** An enumeration. */
export const enum ScreenersGivePointsPaymentPointsCostCurrencyChoices {
  /** Andorran Peseta */
  Adp = "ADP",
  /** United Arab Emirates Dirham */
  Aed = "AED",
  /** Afghan Afghani (1927–2002) */
  Afa = "AFA",
  /** Afghan Afghani */
  Afn = "AFN",
  /** Albanian Lek (1946–1965) */
  Alk = "ALK",
  /** Albanian Lek */
  All = "ALL",
  /** Armenian Dram */
  Amd = "AMD",
  /** Netherlands Antillean Guilder */
  Ang = "ANG",
  /** Angolan Kwanza */
  Aoa = "AOA",
  /** Angolan Kwanza (1977–1991) */
  Aok = "AOK",
  /** Angolan New Kwanza (1990–2000) */
  Aon = "AON",
  /** Angolan Readjusted Kwanza (1995–1999) */
  Aor = "AOR",
  /** Argentine Austral */
  Ara = "ARA",
  /** Argentine Peso Ley (1970–1983) */
  Arl = "ARL",
  /** Argentine Peso (1881–1970) */
  Arm = "ARM",
  /** Argentine Peso (1983–1985) */
  Arp = "ARP",
  /** Argentine Peso */
  Ars = "ARS",
  /** Austrian Schilling */
  Ats = "ATS",
  /** Australian Dollar */
  Aud = "AUD",
  /** Aruban Florin */
  Awg = "AWG",
  /** Azerbaijani Manat (1993–2006) */
  Azm = "AZM",
  /** Azerbaijani Manat */
  Azn = "AZN",
  /** Bosnia-Herzegovina Dinar (1992–1994) */
  Bad = "BAD",
  /** Bosnia-Herzegovina Convertible Mark */
  Bam = "BAM",
  /** Bosnia-Herzegovina New Dinar (1994–1997) */
  Ban = "BAN",
  /** Barbadian Dollar */
  Bbd = "BBD",
  /** Bangladeshi Taka */
  Bdt = "BDT",
  /** Belgian Franc (convertible) */
  Bec = "BEC",
  /** Belgian Franc */
  Bef = "BEF",
  /** Belgian Franc (financial) */
  Bel = "BEL",
  /** Bulgarian Hard Lev */
  Bgl = "BGL",
  /** Bulgarian Socialist Lev */
  Bgm = "BGM",
  /** Bulgarian Lev */
  Bgn = "BGN",
  /** Bulgarian Lev (1879–1952) */
  Bgo = "BGO",
  /** Bahraini Dinar */
  Bhd = "BHD",
  /** Burundian Franc */
  Bif = "BIF",
  /** Bermudan Dollar */
  Bmd = "BMD",
  /** Brunei Dollar */
  Bnd = "BND",
  /** Bolivian Boliviano */
  Bob = "BOB",
  /** Bolivian Boliviano (1863–1963) */
  Bol = "BOL",
  /** Bolivian Peso */
  Bop = "BOP",
  /** Bolivian Mvdol */
  Bov = "BOV",
  /** Brazilian New Cruzeiro (1967–1986) */
  Brb = "BRB",
  /** Brazilian Cruzado (1986–1989) */
  Brc = "BRC",
  /** Brazilian Cruzeiro (1990–1993) */
  Bre = "BRE",
  /** Brazilian Real */
  Brl = "BRL",
  /** Brazilian New Cruzado (1989–1990) */
  Brn = "BRN",
  /** Brazilian Cruzeiro (1993–1994) */
  Brr = "BRR",
  /** Brazilian Cruzeiro (1942–1967) */
  Brz = "BRZ",
  /** Bahamian Dollar */
  Bsd = "BSD",
  /** Bhutanese Ngultrum */
  Btn = "BTN",
  /** Burmese Kyat */
  Buk = "BUK",
  /** Botswanan Pula */
  Bwp = "BWP",
  /** Belarusian Ruble (1994–1999) */
  Byb = "BYB",
  /** Belarusian Ruble */
  Byn = "BYN",
  /** Belarusian Ruble (2000–2016) */
  Byr = "BYR",
  /** Belize Dollar */
  Bzd = "BZD",
  /** Canadian Dollar */
  Cad = "CAD",
  /** Congolese Franc */
  Cdf = "CDF",
  /** WIR Euro */
  Che = "CHE",
  /** Swiss Franc */
  Chf = "CHF",
  /** WIR Franc */
  Chw = "CHW",
  /** Chilean Escudo */
  Cle = "CLE",
  /** Chilean Unit of Account (UF) */
  Clf = "CLF",
  /** Chilean Peso */
  Clp = "CLP",
  /** Chinese Yuan (offshore) */
  Cnh = "CNH",
  /** Chinese People’s Bank Dollar */
  Cnx = "CNX",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Colombian Peso */
  Cop = "COP",
  /** Colombian Real Value Unit */
  Cou = "COU",
  /** Costa Rican Colón */
  Crc = "CRC",
  /** Serbian Dinar (2002–2006) */
  Csd = "CSD",
  /** Czechoslovak Hard Koruna */
  Csk = "CSK",
  /** Cuban Convertible Peso */
  Cuc = "CUC",
  /** Cuban Peso */
  Cup = "CUP",
  /** Cape Verdean Escudo */
  Cve = "CVE",
  /** Cypriot Pound */
  Cyp = "CYP",
  /** Czech Koruna */
  Czk = "CZK",
  /** East German Mark */
  Ddm = "DDM",
  /** German Mark */
  Dem = "DEM",
  /** Djiboutian Franc */
  Djf = "DJF",
  /** Danish Krone */
  Dkk = "DKK",
  /** Dominican Peso */
  Dop = "DOP",
  /** Algerian Dinar */
  Dzd = "DZD",
  /** Ecuadorian Sucre */
  Ecs = "ECS",
  /** Ecuadorian Unit of Constant Value */
  Ecv = "ECV",
  /** Estonian Kroon */
  Eek = "EEK",
  /** Egyptian Pound */
  Egp = "EGP",
  /** Eritrean Nakfa */
  Ern = "ERN",
  /** Spanish Peseta (A account) */
  Esa = "ESA",
  /** Spanish Peseta (convertible account) */
  Esb = "ESB",
  /** Spanish Peseta */
  Esp = "ESP",
  /** Ethiopian Birr */
  Etb = "ETB",
  /** Euro */
  Eur = "EUR",
  /** Finnish Markka */
  Fim = "FIM",
  /** Fijian Dollar */
  Fjd = "FJD",
  /** Falkland Islands Pound */
  Fkp = "FKP",
  /** French Franc */
  Frf = "FRF",
  /** British Pound */
  Gbp = "GBP",
  /** Georgian Kupon Larit */
  Gek = "GEK",
  /** Georgian Lari */
  Gel = "GEL",
  /** Ghanaian Cedi (1979–2007) */
  Ghc = "GHC",
  /** Ghanaian Cedi */
  Ghs = "GHS",
  /** Gibraltar Pound */
  Gip = "GIP",
  /** Gambian Dalasi */
  Gmd = "GMD",
  /** Guinean Franc */
  Gnf = "GNF",
  /** Guinean Syli */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele */
  Gqe = "GQE",
  /** Greek Drachma */
  Grd = "GRD",
  /** Guatemalan Quetzal */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo */
  Gwe = "GWE",
  /** Guinea-Bissau Peso */
  Gwp = "GWP",
  /** Guyanaese Dollar */
  Gyd = "GYD",
  /** Hong Kong Dollar */
  Hkd = "HKD",
  /** Honduran Lempira */
  Hnl = "HNL",
  /** Croatian Dinar */
  Hrd = "HRD",
  /** Croatian Kuna */
  Hrk = "HRK",
  /** Haitian Gourde */
  Htg = "HTG",
  /** Hungarian Forint */
  Huf = "HUF",
  /** Indonesian Rupiah */
  Idr = "IDR",
  /** Irish Pound */
  Iep = "IEP",
  /** Israeli Pound */
  Ilp = "ILP",
  /** Israeli Shekel (1980–1985) */
  Ilr = "ILR",
  /** Israeli New Shekel */
  Ils = "ILS",
  /** IMP */
  Imp = "IMP",
  /** Indian Rupee */
  Inr = "INR",
  /** Iraqi Dinar */
  Iqd = "IQD",
  /** Iranian Rial */
  Irr = "IRR",
  /** Icelandic Króna (1918–1981) */
  Isj = "ISJ",
  /** Icelandic Króna */
  Isk = "ISK",
  /** Italian Lira */
  Itl = "ITL",
  /** Jamaican Dollar */
  Jmd = "JMD",
  /** Jordanian Dinar */
  Jod = "JOD",
  /** Japanese Yen */
  Jpy = "JPY",
  /** Kenyan Shilling */
  Kes = "KES",
  /** Kyrgystani Som */
  Kgs = "KGS",
  /** Cambodian Riel */
  Khr = "KHR",
  /** Comorian Franc */
  Kmf = "KMF",
  /** North Korean Won */
  Kpw = "KPW",
  /** South Korean Hwan (1953–1962) */
  Krh = "KRH",
  /** South Korean Won (1945–1953) */
  Kro = "KRO",
  /** South Korean Won */
  Krw = "KRW",
  /** Kuwaiti Dinar */
  Kwd = "KWD",
  /** Cayman Islands Dollar */
  Kyd = "KYD",
  /** Kazakhstani Tenge */
  Kzt = "KZT",
  /** Laotian Kip */
  Lak = "LAK",
  /** Lebanese Pound */
  Lbp = "LBP",
  /** Sri Lankan Rupee */
  Lkr = "LKR",
  /** Liberian Dollar */
  Lrd = "LRD",
  /** Lesotho Loti */
  Lsl = "LSL",
  /** Lithuanian Litas */
  Ltl = "LTL",
  /** Lithuanian Talonas */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc */
  Luc = "LUC",
  /** Luxembourgian Franc */
  Luf = "LUF",
  /** Luxembourg Financial Franc */
  Lul = "LUL",
  /** Latvian Lats */
  Lvl = "LVL",
  /** Latvian Ruble */
  Lvr = "LVR",
  /** Libyan Dinar */
  Lyd = "LYD",
  /** Moroccan Dirham */
  Mad = "MAD",
  /** Moroccan Franc */
  Maf = "MAF",
  /** Monegasque Franc */
  Mcf = "MCF",
  /** Moldovan Cupon */
  Mdc = "MDC",
  /** Moldovan Leu */
  Mdl = "MDL",
  /** Malagasy Ariary */
  Mga = "MGA",
  /** Malagasy Franc */
  Mgf = "MGF",
  /** Macedonian Denar */
  Mkd = "MKD",
  /** Macedonian Denar (1992–1993) */
  Mkn = "MKN",
  /** Malian Franc */
  Mlf = "MLF",
  /** Myanmar Kyat */
  Mmk = "MMK",
  /** Mongolian Tugrik */
  Mnt = "MNT",
  /** Macanese Pataca */
  Mop = "MOP",
  /** Mauritanian Ouguiya (1973–2017) */
  Mro = "MRO",
  /** Mauritanian Ouguiya */
  Mru = "MRU",
  /** Maltese Lira */
  Mtl = "MTL",
  /** Maltese Pound */
  Mtp = "MTP",
  /** Mauritian Rupee */
  Mur = "MUR",
  /** Maldivian Rupee (1947–1981) */
  Mvp = "MVP",
  /** Maldivian Rufiyaa */
  Mvr = "MVR",
  /** Malawian Kwacha */
  Mwk = "MWK",
  /** Mexican Peso */
  Mxn = "MXN",
  /** Mexican Silver Peso (1861–1992) */
  Mxp = "MXP",
  /** Mexican Investment Unit */
  Mxv = "MXV",
  /** Malaysian Ringgit */
  Myr = "MYR",
  /** Mozambican Escudo */
  Mze = "MZE",
  /** Mozambican Metical (1980–2006) */
  Mzm = "MZM",
  /** Mozambican Metical */
  Mzn = "MZN",
  /** Namibian Dollar */
  Nad = "NAD",
  /** Nigerian Naira */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (1988–1991) */
  Nic = "NIC",
  /** Nicaraguan Córdoba */
  Nio = "NIO",
  /** Dutch Guilder */
  Nlg = "NLG",
  /** Norwegian Krone */
  Nok = "NOK",
  /** Nepalese Rupee */
  Npr = "NPR",
  /** New Zealand Dollar */
  Nzd = "NZD",
  /** Omani Rial */
  Omr = "OMR",
  /** Panamanian Balboa */
  Pab = "PAB",
  /** Peruvian Inti */
  Pei = "PEI",
  /** Peruvian Sol */
  Pen = "PEN",
  /** Peruvian Sol (1863–1965) */
  Pes = "PES",
  /** Papua New Guinean Kina */
  Pgk = "PGK",
  /** Philippine Peso */
  Php = "PHP",
  /** Pakistani Rupee */
  Pkr = "PKR",
  /** Polish Zloty */
  Pln = "PLN",
  /** Polish Zloty (1950–1995) */
  Plz = "PLZ",
  /** Portuguese Escudo */
  Pte = "PTE",
  /** Paraguayan Guarani */
  Pyg = "PYG",
  /** Qatari Riyal */
  Qar = "QAR",
  /** Rhodesian Dollar */
  Rhd = "RHD",
  /** Romanian Leu (1952–2006) */
  Rol = "ROL",
  /** Romanian Leu */
  Ron = "RON",
  /** Serbian Dinar */
  Rsd = "RSD",
  /** Russian Ruble */
  Rub = "RUB",
  /** Russian Ruble (1991–1998) */
  Rur = "RUR",
  /** Rwandan Franc */
  Rwf = "RWF",
  /** Saudi Riyal */
  Sar = "SAR",
  /** Solomon Islands Dollar */
  Sbd = "SBD",
  /** Seychellois Rupee */
  Scr = "SCR",
  /** Sudanese Dinar (1992–2007) */
  Sdd = "SDD",
  /** Sudanese Pound */
  Sdg = "SDG",
  /** Sudanese Pound (1957–1998) */
  Sdp = "SDP",
  /** Swedish Krona */
  Sek = "SEK",
  /** Singapore Dollar */
  Sgd = "SGD",
  /** St. Helena Pound */
  Shp = "SHP",
  /** Slovenian Tolar */
  Sit = "SIT",
  /** Slovak Koruna */
  Skk = "SKK",
  /** Sierra Leonean Leone (1964—2022) */
  Sll = "SLL",
  /** Somali Shilling */
  Sos = "SOS",
  /** Surinamese Dollar */
  Srd = "SRD",
  /** Surinamese Guilder */
  Srg = "SRG",
  /** South Sudanese Pound */
  Ssp = "SSP",
  /** São Tomé & Príncipe Dobra (1977–2017) */
  Std = "STD",
  /** São Tomé & Príncipe Dobra */
  Stn = "STN",
  /** Soviet Rouble */
  Sur = "SUR",
  /** Salvadoran Colón */
  Svc = "SVC",
  /** Syrian Pound */
  Syp = "SYP",
  /** Swazi Lilangeni */
  Szl = "SZL",
  /** Thai Baht */
  Thb = "THB",
  /** Tajikistani Ruble */
  Tjr = "TJR",
  /** Tajikistani Somoni */
  Tjs = "TJS",
  /** Turkmenistani Manat (1993–2009) */
  Tmm = "TMM",
  /** Turkmenistani Manat */
  Tmt = "TMT",
  /** Tunisian Dinar */
  Tnd = "TND",
  /** Tongan Paʻanga */
  Top = "TOP",
  /** Timorese Escudo */
  Tpe = "TPE",
  /** Turkish Lira (1922–2005) */
  Trl = "TRL",
  /** Turkish Lira */
  Try = "TRY",
  /** Trinidad & Tobago Dollar */
  Ttd = "TTD",
  /** TVD */
  Tvd = "TVD",
  /** New Taiwan Dollar */
  Twd = "TWD",
  /** Tanzanian Shilling */
  Tzs = "TZS",
  /** Ukrainian Hryvnia */
  Uah = "UAH",
  /** Ukrainian Karbovanets */
  Uak = "UAK",
  /** Ugandan Shilling (1966–1987) */
  Ugs = "UGS",
  /** Ugandan Shilling */
  Ugx = "UGX",
  /** US Dollar */
  Usd = "USD",
  /** US Dollar (Next day) */
  Usn = "USN",
  /** US Dollar (Same day) */
  Uss = "USS",
  /** Uruguayan Peso (Indexed Units) */
  Uyi = "UYI",
  /** Uruguayan Peso (1975–1993) */
  Uyp = "UYP",
  /** Uruguayan Peso */
  Uyu = "UYU",
  /** Uruguayan Nominal Wage Index Unit */
  Uyw = "UYW",
  /** Uzbekistani Som */
  Uzs = "UZS",
  /** Venezuelan Bolívar (1871–2008) */
  Veb = "VEB",
  /** Venezuelan Bolívar (2008–2018) */
  Vef = "VEF",
  /** Venezuelan Bolívar */
  Ves = "VES",
  /** Vietnamese Dong */
  Vnd = "VND",
  /** Vietnamese Dong (1978–1985) */
  Vnn = "VNN",
  /** Vanuatu Vatu */
  Vuv = "VUV",
  /** Samoan Tala */
  Wst = "WST",
  /** Central African CFA Franc */
  Xaf = "XAF",
  /** Silver */
  Xag = "XAG",
  /** Gold */
  Xau = "XAU",
  /** European Composite Unit */
  Xba = "XBA",
  /** European Monetary Unit */
  Xbb = "XBB",
  /** European Unit of Account (XBC) */
  Xbc = "XBC",
  /** European Unit of Account (XBD) */
  Xbd = "XBD",
  /** East Caribbean Dollar */
  Xcd = "XCD",
  /** Special Drawing Rights */
  Xdr = "XDR",
  /** European Currency Unit */
  Xeu = "XEU",
  /** French Gold Franc */
  Xfo = "XFO",
  /** French UIC-Franc */
  Xfu = "XFU",
  /** West African CFA Franc */
  Xof = "XOF",
  /** Palladium */
  Xpd = "XPD",
  /** CFP Franc */
  Xpf = "XPF",
  /** Platinum */
  Xpt = "XPT",
  /** RINET Funds */
  Xre = "XRE",
  /** Sucre */
  Xsu = "XSU",
  /** Testing Currency Code */
  Xts = "XTS",
  /** ADB Unit of Account */
  Xua = "XUA",
  /** The codes assigned for transactions where no currency is involved */
  Xxx = "XXX",
  /** Yemeni Dinar */
  Ydd = "YDD",
  /** Yemeni Rial */
  Yer = "YER",
  /** Yugoslavian Hard Dinar (1966–1990) */
  Yud = "YUD",
  /** Yugoslavian New Dinar (1994–2002) */
  Yum = "YUM",
  /** Yugoslavian Convertible Dinar (1990–1992) */
  Yun = "YUN",
  /** Yugoslavian Reformed Dinar (1992–1993) */
  Yur = "YUR",
  /** South African Rand (financial) */
  Zal = "ZAL",
  /** South African Rand */
  Zar = "ZAR",
  /** Zambian Kwacha (1968–2012) */
  Zmk = "ZMK",
  /** Zambian Kwacha */
  Zmw = "ZMW",
  /** Zairean New Zaire (1993–1998) */
  Zrn = "ZRN",
  /** Zairean Zaire (1971–1993) */
  Zrz = "ZRZ",
  /** Zimbabwean Dollar (1980–2008) */
  Zwd = "ZWD",
  /** Zimbabwean Dollar (2009) */
  Zwl = "ZWL",
  /** ZWN */
  Zwn = "ZWN",
  /** Zimbabwean Dollar (2008) */
  Zwr = "ZWR",
}

/** An enumeration. */
export const enum ScreenersGivePointsPaymentPointsFeesCurrencyChoices {
  /** Andorran Peseta */
  Adp = "ADP",
  /** United Arab Emirates Dirham */
  Aed = "AED",
  /** Afghan Afghani (1927–2002) */
  Afa = "AFA",
  /** Afghan Afghani */
  Afn = "AFN",
  /** Albanian Lek (1946–1965) */
  Alk = "ALK",
  /** Albanian Lek */
  All = "ALL",
  /** Armenian Dram */
  Amd = "AMD",
  /** Netherlands Antillean Guilder */
  Ang = "ANG",
  /** Angolan Kwanza */
  Aoa = "AOA",
  /** Angolan Kwanza (1977–1991) */
  Aok = "AOK",
  /** Angolan New Kwanza (1990–2000) */
  Aon = "AON",
  /** Angolan Readjusted Kwanza (1995–1999) */
  Aor = "AOR",
  /** Argentine Austral */
  Ara = "ARA",
  /** Argentine Peso Ley (1970–1983) */
  Arl = "ARL",
  /** Argentine Peso (1881–1970) */
  Arm = "ARM",
  /** Argentine Peso (1983–1985) */
  Arp = "ARP",
  /** Argentine Peso */
  Ars = "ARS",
  /** Austrian Schilling */
  Ats = "ATS",
  /** Australian Dollar */
  Aud = "AUD",
  /** Aruban Florin */
  Awg = "AWG",
  /** Azerbaijani Manat (1993–2006) */
  Azm = "AZM",
  /** Azerbaijani Manat */
  Azn = "AZN",
  /** Bosnia-Herzegovina Dinar (1992–1994) */
  Bad = "BAD",
  /** Bosnia-Herzegovina Convertible Mark */
  Bam = "BAM",
  /** Bosnia-Herzegovina New Dinar (1994–1997) */
  Ban = "BAN",
  /** Barbadian Dollar */
  Bbd = "BBD",
  /** Bangladeshi Taka */
  Bdt = "BDT",
  /** Belgian Franc (convertible) */
  Bec = "BEC",
  /** Belgian Franc */
  Bef = "BEF",
  /** Belgian Franc (financial) */
  Bel = "BEL",
  /** Bulgarian Hard Lev */
  Bgl = "BGL",
  /** Bulgarian Socialist Lev */
  Bgm = "BGM",
  /** Bulgarian Lev */
  Bgn = "BGN",
  /** Bulgarian Lev (1879–1952) */
  Bgo = "BGO",
  /** Bahraini Dinar */
  Bhd = "BHD",
  /** Burundian Franc */
  Bif = "BIF",
  /** Bermudan Dollar */
  Bmd = "BMD",
  /** Brunei Dollar */
  Bnd = "BND",
  /** Bolivian Boliviano */
  Bob = "BOB",
  /** Bolivian Boliviano (1863–1963) */
  Bol = "BOL",
  /** Bolivian Peso */
  Bop = "BOP",
  /** Bolivian Mvdol */
  Bov = "BOV",
  /** Brazilian New Cruzeiro (1967–1986) */
  Brb = "BRB",
  /** Brazilian Cruzado (1986–1989) */
  Brc = "BRC",
  /** Brazilian Cruzeiro (1990–1993) */
  Bre = "BRE",
  /** Brazilian Real */
  Brl = "BRL",
  /** Brazilian New Cruzado (1989–1990) */
  Brn = "BRN",
  /** Brazilian Cruzeiro (1993–1994) */
  Brr = "BRR",
  /** Brazilian Cruzeiro (1942–1967) */
  Brz = "BRZ",
  /** Bahamian Dollar */
  Bsd = "BSD",
  /** Bhutanese Ngultrum */
  Btn = "BTN",
  /** Burmese Kyat */
  Buk = "BUK",
  /** Botswanan Pula */
  Bwp = "BWP",
  /** Belarusian Ruble (1994–1999) */
  Byb = "BYB",
  /** Belarusian Ruble */
  Byn = "BYN",
  /** Belarusian Ruble (2000–2016) */
  Byr = "BYR",
  /** Belize Dollar */
  Bzd = "BZD",
  /** Canadian Dollar */
  Cad = "CAD",
  /** Congolese Franc */
  Cdf = "CDF",
  /** WIR Euro */
  Che = "CHE",
  /** Swiss Franc */
  Chf = "CHF",
  /** WIR Franc */
  Chw = "CHW",
  /** Chilean Escudo */
  Cle = "CLE",
  /** Chilean Unit of Account (UF) */
  Clf = "CLF",
  /** Chilean Peso */
  Clp = "CLP",
  /** Chinese Yuan (offshore) */
  Cnh = "CNH",
  /** Chinese People’s Bank Dollar */
  Cnx = "CNX",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Colombian Peso */
  Cop = "COP",
  /** Colombian Real Value Unit */
  Cou = "COU",
  /** Costa Rican Colón */
  Crc = "CRC",
  /** Serbian Dinar (2002–2006) */
  Csd = "CSD",
  /** Czechoslovak Hard Koruna */
  Csk = "CSK",
  /** Cuban Convertible Peso */
  Cuc = "CUC",
  /** Cuban Peso */
  Cup = "CUP",
  /** Cape Verdean Escudo */
  Cve = "CVE",
  /** Cypriot Pound */
  Cyp = "CYP",
  /** Czech Koruna */
  Czk = "CZK",
  /** East German Mark */
  Ddm = "DDM",
  /** German Mark */
  Dem = "DEM",
  /** Djiboutian Franc */
  Djf = "DJF",
  /** Danish Krone */
  Dkk = "DKK",
  /** Dominican Peso */
  Dop = "DOP",
  /** Algerian Dinar */
  Dzd = "DZD",
  /** Ecuadorian Sucre */
  Ecs = "ECS",
  /** Ecuadorian Unit of Constant Value */
  Ecv = "ECV",
  /** Estonian Kroon */
  Eek = "EEK",
  /** Egyptian Pound */
  Egp = "EGP",
  /** Eritrean Nakfa */
  Ern = "ERN",
  /** Spanish Peseta (A account) */
  Esa = "ESA",
  /** Spanish Peseta (convertible account) */
  Esb = "ESB",
  /** Spanish Peseta */
  Esp = "ESP",
  /** Ethiopian Birr */
  Etb = "ETB",
  /** Euro */
  Eur = "EUR",
  /** Finnish Markka */
  Fim = "FIM",
  /** Fijian Dollar */
  Fjd = "FJD",
  /** Falkland Islands Pound */
  Fkp = "FKP",
  /** French Franc */
  Frf = "FRF",
  /** British Pound */
  Gbp = "GBP",
  /** Georgian Kupon Larit */
  Gek = "GEK",
  /** Georgian Lari */
  Gel = "GEL",
  /** Ghanaian Cedi (1979–2007) */
  Ghc = "GHC",
  /** Ghanaian Cedi */
  Ghs = "GHS",
  /** Gibraltar Pound */
  Gip = "GIP",
  /** Gambian Dalasi */
  Gmd = "GMD",
  /** Guinean Franc */
  Gnf = "GNF",
  /** Guinean Syli */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele */
  Gqe = "GQE",
  /** Greek Drachma */
  Grd = "GRD",
  /** Guatemalan Quetzal */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo */
  Gwe = "GWE",
  /** Guinea-Bissau Peso */
  Gwp = "GWP",
  /** Guyanaese Dollar */
  Gyd = "GYD",
  /** Hong Kong Dollar */
  Hkd = "HKD",
  /** Honduran Lempira */
  Hnl = "HNL",
  /** Croatian Dinar */
  Hrd = "HRD",
  /** Croatian Kuna */
  Hrk = "HRK",
  /** Haitian Gourde */
  Htg = "HTG",
  /** Hungarian Forint */
  Huf = "HUF",
  /** Indonesian Rupiah */
  Idr = "IDR",
  /** Irish Pound */
  Iep = "IEP",
  /** Israeli Pound */
  Ilp = "ILP",
  /** Israeli Shekel (1980–1985) */
  Ilr = "ILR",
  /** Israeli New Shekel */
  Ils = "ILS",
  /** IMP */
  Imp = "IMP",
  /** Indian Rupee */
  Inr = "INR",
  /** Iraqi Dinar */
  Iqd = "IQD",
  /** Iranian Rial */
  Irr = "IRR",
  /** Icelandic Króna (1918–1981) */
  Isj = "ISJ",
  /** Icelandic Króna */
  Isk = "ISK",
  /** Italian Lira */
  Itl = "ITL",
  /** Jamaican Dollar */
  Jmd = "JMD",
  /** Jordanian Dinar */
  Jod = "JOD",
  /** Japanese Yen */
  Jpy = "JPY",
  /** Kenyan Shilling */
  Kes = "KES",
  /** Kyrgystani Som */
  Kgs = "KGS",
  /** Cambodian Riel */
  Khr = "KHR",
  /** Comorian Franc */
  Kmf = "KMF",
  /** North Korean Won */
  Kpw = "KPW",
  /** South Korean Hwan (1953–1962) */
  Krh = "KRH",
  /** South Korean Won (1945–1953) */
  Kro = "KRO",
  /** South Korean Won */
  Krw = "KRW",
  /** Kuwaiti Dinar */
  Kwd = "KWD",
  /** Cayman Islands Dollar */
  Kyd = "KYD",
  /** Kazakhstani Tenge */
  Kzt = "KZT",
  /** Laotian Kip */
  Lak = "LAK",
  /** Lebanese Pound */
  Lbp = "LBP",
  /** Sri Lankan Rupee */
  Lkr = "LKR",
  /** Liberian Dollar */
  Lrd = "LRD",
  /** Lesotho Loti */
  Lsl = "LSL",
  /** Lithuanian Litas */
  Ltl = "LTL",
  /** Lithuanian Talonas */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc */
  Luc = "LUC",
  /** Luxembourgian Franc */
  Luf = "LUF",
  /** Luxembourg Financial Franc */
  Lul = "LUL",
  /** Latvian Lats */
  Lvl = "LVL",
  /** Latvian Ruble */
  Lvr = "LVR",
  /** Libyan Dinar */
  Lyd = "LYD",
  /** Moroccan Dirham */
  Mad = "MAD",
  /** Moroccan Franc */
  Maf = "MAF",
  /** Monegasque Franc */
  Mcf = "MCF",
  /** Moldovan Cupon */
  Mdc = "MDC",
  /** Moldovan Leu */
  Mdl = "MDL",
  /** Malagasy Ariary */
  Mga = "MGA",
  /** Malagasy Franc */
  Mgf = "MGF",
  /** Macedonian Denar */
  Mkd = "MKD",
  /** Macedonian Denar (1992–1993) */
  Mkn = "MKN",
  /** Malian Franc */
  Mlf = "MLF",
  /** Myanmar Kyat */
  Mmk = "MMK",
  /** Mongolian Tugrik */
  Mnt = "MNT",
  /** Macanese Pataca */
  Mop = "MOP",
  /** Mauritanian Ouguiya (1973–2017) */
  Mro = "MRO",
  /** Mauritanian Ouguiya */
  Mru = "MRU",
  /** Maltese Lira */
  Mtl = "MTL",
  /** Maltese Pound */
  Mtp = "MTP",
  /** Mauritian Rupee */
  Mur = "MUR",
  /** Maldivian Rupee (1947–1981) */
  Mvp = "MVP",
  /** Maldivian Rufiyaa */
  Mvr = "MVR",
  /** Malawian Kwacha */
  Mwk = "MWK",
  /** Mexican Peso */
  Mxn = "MXN",
  /** Mexican Silver Peso (1861–1992) */
  Mxp = "MXP",
  /** Mexican Investment Unit */
  Mxv = "MXV",
  /** Malaysian Ringgit */
  Myr = "MYR",
  /** Mozambican Escudo */
  Mze = "MZE",
  /** Mozambican Metical (1980–2006) */
  Mzm = "MZM",
  /** Mozambican Metical */
  Mzn = "MZN",
  /** Namibian Dollar */
  Nad = "NAD",
  /** Nigerian Naira */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (1988–1991) */
  Nic = "NIC",
  /** Nicaraguan Córdoba */
  Nio = "NIO",
  /** Dutch Guilder */
  Nlg = "NLG",
  /** Norwegian Krone */
  Nok = "NOK",
  /** Nepalese Rupee */
  Npr = "NPR",
  /** New Zealand Dollar */
  Nzd = "NZD",
  /** Omani Rial */
  Omr = "OMR",
  /** Panamanian Balboa */
  Pab = "PAB",
  /** Peruvian Inti */
  Pei = "PEI",
  /** Peruvian Sol */
  Pen = "PEN",
  /** Peruvian Sol (1863–1965) */
  Pes = "PES",
  /** Papua New Guinean Kina */
  Pgk = "PGK",
  /** Philippine Peso */
  Php = "PHP",
  /** Pakistani Rupee */
  Pkr = "PKR",
  /** Polish Zloty */
  Pln = "PLN",
  /** Polish Zloty (1950–1995) */
  Plz = "PLZ",
  /** Portuguese Escudo */
  Pte = "PTE",
  /** Paraguayan Guarani */
  Pyg = "PYG",
  /** Qatari Riyal */
  Qar = "QAR",
  /** Rhodesian Dollar */
  Rhd = "RHD",
  /** Romanian Leu (1952–2006) */
  Rol = "ROL",
  /** Romanian Leu */
  Ron = "RON",
  /** Serbian Dinar */
  Rsd = "RSD",
  /** Russian Ruble */
  Rub = "RUB",
  /** Russian Ruble (1991–1998) */
  Rur = "RUR",
  /** Rwandan Franc */
  Rwf = "RWF",
  /** Saudi Riyal */
  Sar = "SAR",
  /** Solomon Islands Dollar */
  Sbd = "SBD",
  /** Seychellois Rupee */
  Scr = "SCR",
  /** Sudanese Dinar (1992–2007) */
  Sdd = "SDD",
  /** Sudanese Pound */
  Sdg = "SDG",
  /** Sudanese Pound (1957–1998) */
  Sdp = "SDP",
  /** Swedish Krona */
  Sek = "SEK",
  /** Singapore Dollar */
  Sgd = "SGD",
  /** St. Helena Pound */
  Shp = "SHP",
  /** Slovenian Tolar */
  Sit = "SIT",
  /** Slovak Koruna */
  Skk = "SKK",
  /** Sierra Leonean Leone (1964—2022) */
  Sll = "SLL",
  /** Somali Shilling */
  Sos = "SOS",
  /** Surinamese Dollar */
  Srd = "SRD",
  /** Surinamese Guilder */
  Srg = "SRG",
  /** South Sudanese Pound */
  Ssp = "SSP",
  /** São Tomé & Príncipe Dobra (1977–2017) */
  Std = "STD",
  /** São Tomé & Príncipe Dobra */
  Stn = "STN",
  /** Soviet Rouble */
  Sur = "SUR",
  /** Salvadoran Colón */
  Svc = "SVC",
  /** Syrian Pound */
  Syp = "SYP",
  /** Swazi Lilangeni */
  Szl = "SZL",
  /** Thai Baht */
  Thb = "THB",
  /** Tajikistani Ruble */
  Tjr = "TJR",
  /** Tajikistani Somoni */
  Tjs = "TJS",
  /** Turkmenistani Manat (1993–2009) */
  Tmm = "TMM",
  /** Turkmenistani Manat */
  Tmt = "TMT",
  /** Tunisian Dinar */
  Tnd = "TND",
  /** Tongan Paʻanga */
  Top = "TOP",
  /** Timorese Escudo */
  Tpe = "TPE",
  /** Turkish Lira (1922–2005) */
  Trl = "TRL",
  /** Turkish Lira */
  Try = "TRY",
  /** Trinidad & Tobago Dollar */
  Ttd = "TTD",
  /** TVD */
  Tvd = "TVD",
  /** New Taiwan Dollar */
  Twd = "TWD",
  /** Tanzanian Shilling */
  Tzs = "TZS",
  /** Ukrainian Hryvnia */
  Uah = "UAH",
  /** Ukrainian Karbovanets */
  Uak = "UAK",
  /** Ugandan Shilling (1966–1987) */
  Ugs = "UGS",
  /** Ugandan Shilling */
  Ugx = "UGX",
  /** US Dollar */
  Usd = "USD",
  /** US Dollar (Next day) */
  Usn = "USN",
  /** US Dollar (Same day) */
  Uss = "USS",
  /** Uruguayan Peso (Indexed Units) */
  Uyi = "UYI",
  /** Uruguayan Peso (1975–1993) */
  Uyp = "UYP",
  /** Uruguayan Peso */
  Uyu = "UYU",
  /** Uruguayan Nominal Wage Index Unit */
  Uyw = "UYW",
  /** Uzbekistani Som */
  Uzs = "UZS",
  /** Venezuelan Bolívar (1871–2008) */
  Veb = "VEB",
  /** Venezuelan Bolívar (2008–2018) */
  Vef = "VEF",
  /** Venezuelan Bolívar */
  Ves = "VES",
  /** Vietnamese Dong */
  Vnd = "VND",
  /** Vietnamese Dong (1978–1985) */
  Vnn = "VNN",
  /** Vanuatu Vatu */
  Vuv = "VUV",
  /** Samoan Tala */
  Wst = "WST",
  /** Central African CFA Franc */
  Xaf = "XAF",
  /** Silver */
  Xag = "XAG",
  /** Gold */
  Xau = "XAU",
  /** European Composite Unit */
  Xba = "XBA",
  /** European Monetary Unit */
  Xbb = "XBB",
  /** European Unit of Account (XBC) */
  Xbc = "XBC",
  /** European Unit of Account (XBD) */
  Xbd = "XBD",
  /** East Caribbean Dollar */
  Xcd = "XCD",
  /** Special Drawing Rights */
  Xdr = "XDR",
  /** European Currency Unit */
  Xeu = "XEU",
  /** French Gold Franc */
  Xfo = "XFO",
  /** French UIC-Franc */
  Xfu = "XFU",
  /** West African CFA Franc */
  Xof = "XOF",
  /** Palladium */
  Xpd = "XPD",
  /** CFP Franc */
  Xpf = "XPF",
  /** Platinum */
  Xpt = "XPT",
  /** RINET Funds */
  Xre = "XRE",
  /** Sucre */
  Xsu = "XSU",
  /** Testing Currency Code */
  Xts = "XTS",
  /** ADB Unit of Account */
  Xua = "XUA",
  /** The codes assigned for transactions where no currency is involved */
  Xxx = "XXX",
  /** Yemeni Dinar */
  Ydd = "YDD",
  /** Yemeni Rial */
  Yer = "YER",
  /** Yugoslavian Hard Dinar (1966–1990) */
  Yud = "YUD",
  /** Yugoslavian New Dinar (1994–2002) */
  Yum = "YUM",
  /** Yugoslavian Convertible Dinar (1990–1992) */
  Yun = "YUN",
  /** Yugoslavian Reformed Dinar (1992–1993) */
  Yur = "YUR",
  /** South African Rand (financial) */
  Zal = "ZAL",
  /** South African Rand */
  Zar = "ZAR",
  /** Zambian Kwacha (1968–2012) */
  Zmk = "ZMK",
  /** Zambian Kwacha */
  Zmw = "ZMW",
  /** Zairean New Zaire (1993–1998) */
  Zrn = "ZRN",
  /** Zairean Zaire (1971–1993) */
  Zrz = "ZRZ",
  /** Zimbabwean Dollar (1980–2008) */
  Zwd = "ZWD",
  /** Zimbabwean Dollar (2009) */
  Zwl = "ZWL",
  /** ZWN */
  Zwn = "ZWN",
  /** Zimbabwean Dollar (2008) */
  Zwr = "ZWR",
}

/** An enumeration. */
export const enum ScreenersIncentiveOrderIncentiveTypeChoices {
  /** Cash */
  Ca = "CA",
  /** Points */
  Pt = "PT",
}

/** An enumeration. */
export const enum ScreenersIncentiveOrderReasonChoices {
  /** Participation */
  Pa = "PA",
  /** Panelist Bonus */
  Pn = "PN",
  /** Participant Bonus */
  Pr = "PR",
}

/** An enumeration. */
export const enum ScreenersIncentiveOrderStatusChoices {
  /** Completed */
  C = "C",
  /** Pending */
  P = "P",
  /** Canceled */
  X = "X",
}

/** An enumeration. */
export const enum ScreenersParticipantIncentivizedAmountCurrencyChoices {
  /** Andorran Peseta */
  Adp = "ADP",
  /** United Arab Emirates Dirham */
  Aed = "AED",
  /** Afghan Afghani (1927–2002) */
  Afa = "AFA",
  /** Afghan Afghani */
  Afn = "AFN",
  /** Albanian Lek (1946–1965) */
  Alk = "ALK",
  /** Albanian Lek */
  All = "ALL",
  /** Armenian Dram */
  Amd = "AMD",
  /** Netherlands Antillean Guilder */
  Ang = "ANG",
  /** Angolan Kwanza */
  Aoa = "AOA",
  /** Angolan Kwanza (1977–1991) */
  Aok = "AOK",
  /** Angolan New Kwanza (1990–2000) */
  Aon = "AON",
  /** Angolan Readjusted Kwanza (1995–1999) */
  Aor = "AOR",
  /** Argentine Austral */
  Ara = "ARA",
  /** Argentine Peso Ley (1970–1983) */
  Arl = "ARL",
  /** Argentine Peso (1881–1970) */
  Arm = "ARM",
  /** Argentine Peso (1983–1985) */
  Arp = "ARP",
  /** Argentine Peso */
  Ars = "ARS",
  /** Austrian Schilling */
  Ats = "ATS",
  /** Australian Dollar */
  Aud = "AUD",
  /** Aruban Florin */
  Awg = "AWG",
  /** Azerbaijani Manat (1993–2006) */
  Azm = "AZM",
  /** Azerbaijani Manat */
  Azn = "AZN",
  /** Bosnia-Herzegovina Dinar (1992–1994) */
  Bad = "BAD",
  /** Bosnia-Herzegovina Convertible Mark */
  Bam = "BAM",
  /** Bosnia-Herzegovina New Dinar (1994–1997) */
  Ban = "BAN",
  /** Barbadian Dollar */
  Bbd = "BBD",
  /** Bangladeshi Taka */
  Bdt = "BDT",
  /** Belgian Franc (convertible) */
  Bec = "BEC",
  /** Belgian Franc */
  Bef = "BEF",
  /** Belgian Franc (financial) */
  Bel = "BEL",
  /** Bulgarian Hard Lev */
  Bgl = "BGL",
  /** Bulgarian Socialist Lev */
  Bgm = "BGM",
  /** Bulgarian Lev */
  Bgn = "BGN",
  /** Bulgarian Lev (1879–1952) */
  Bgo = "BGO",
  /** Bahraini Dinar */
  Bhd = "BHD",
  /** Burundian Franc */
  Bif = "BIF",
  /** Bermudan Dollar */
  Bmd = "BMD",
  /** Brunei Dollar */
  Bnd = "BND",
  /** Bolivian Boliviano */
  Bob = "BOB",
  /** Bolivian Boliviano (1863–1963) */
  Bol = "BOL",
  /** Bolivian Peso */
  Bop = "BOP",
  /** Bolivian Mvdol */
  Bov = "BOV",
  /** Brazilian New Cruzeiro (1967–1986) */
  Brb = "BRB",
  /** Brazilian Cruzado (1986–1989) */
  Brc = "BRC",
  /** Brazilian Cruzeiro (1990–1993) */
  Bre = "BRE",
  /** Brazilian Real */
  Brl = "BRL",
  /** Brazilian New Cruzado (1989–1990) */
  Brn = "BRN",
  /** Brazilian Cruzeiro (1993–1994) */
  Brr = "BRR",
  /** Brazilian Cruzeiro (1942–1967) */
  Brz = "BRZ",
  /** Bahamian Dollar */
  Bsd = "BSD",
  /** Bhutanese Ngultrum */
  Btn = "BTN",
  /** Burmese Kyat */
  Buk = "BUK",
  /** Botswanan Pula */
  Bwp = "BWP",
  /** Belarusian Ruble (1994–1999) */
  Byb = "BYB",
  /** Belarusian Ruble */
  Byn = "BYN",
  /** Belarusian Ruble (2000–2016) */
  Byr = "BYR",
  /** Belize Dollar */
  Bzd = "BZD",
  /** Canadian Dollar */
  Cad = "CAD",
  /** Congolese Franc */
  Cdf = "CDF",
  /** WIR Euro */
  Che = "CHE",
  /** Swiss Franc */
  Chf = "CHF",
  /** WIR Franc */
  Chw = "CHW",
  /** Chilean Escudo */
  Cle = "CLE",
  /** Chilean Unit of Account (UF) */
  Clf = "CLF",
  /** Chilean Peso */
  Clp = "CLP",
  /** Chinese Yuan (offshore) */
  Cnh = "CNH",
  /** Chinese People’s Bank Dollar */
  Cnx = "CNX",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Colombian Peso */
  Cop = "COP",
  /** Colombian Real Value Unit */
  Cou = "COU",
  /** Costa Rican Colón */
  Crc = "CRC",
  /** Serbian Dinar (2002–2006) */
  Csd = "CSD",
  /** Czechoslovak Hard Koruna */
  Csk = "CSK",
  /** Cuban Convertible Peso */
  Cuc = "CUC",
  /** Cuban Peso */
  Cup = "CUP",
  /** Cape Verdean Escudo */
  Cve = "CVE",
  /** Cypriot Pound */
  Cyp = "CYP",
  /** Czech Koruna */
  Czk = "CZK",
  /** East German Mark */
  Ddm = "DDM",
  /** German Mark */
  Dem = "DEM",
  /** Djiboutian Franc */
  Djf = "DJF",
  /** Danish Krone */
  Dkk = "DKK",
  /** Dominican Peso */
  Dop = "DOP",
  /** Algerian Dinar */
  Dzd = "DZD",
  /** Ecuadorian Sucre */
  Ecs = "ECS",
  /** Ecuadorian Unit of Constant Value */
  Ecv = "ECV",
  /** Estonian Kroon */
  Eek = "EEK",
  /** Egyptian Pound */
  Egp = "EGP",
  /** Eritrean Nakfa */
  Ern = "ERN",
  /** Spanish Peseta (A account) */
  Esa = "ESA",
  /** Spanish Peseta (convertible account) */
  Esb = "ESB",
  /** Spanish Peseta */
  Esp = "ESP",
  /** Ethiopian Birr */
  Etb = "ETB",
  /** Euro */
  Eur = "EUR",
  /** Finnish Markka */
  Fim = "FIM",
  /** Fijian Dollar */
  Fjd = "FJD",
  /** Falkland Islands Pound */
  Fkp = "FKP",
  /** French Franc */
  Frf = "FRF",
  /** British Pound */
  Gbp = "GBP",
  /** Georgian Kupon Larit */
  Gek = "GEK",
  /** Georgian Lari */
  Gel = "GEL",
  /** Ghanaian Cedi (1979–2007) */
  Ghc = "GHC",
  /** Ghanaian Cedi */
  Ghs = "GHS",
  /** Gibraltar Pound */
  Gip = "GIP",
  /** Gambian Dalasi */
  Gmd = "GMD",
  /** Guinean Franc */
  Gnf = "GNF",
  /** Guinean Syli */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele */
  Gqe = "GQE",
  /** Greek Drachma */
  Grd = "GRD",
  /** Guatemalan Quetzal */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo */
  Gwe = "GWE",
  /** Guinea-Bissau Peso */
  Gwp = "GWP",
  /** Guyanaese Dollar */
  Gyd = "GYD",
  /** Hong Kong Dollar */
  Hkd = "HKD",
  /** Honduran Lempira */
  Hnl = "HNL",
  /** Croatian Dinar */
  Hrd = "HRD",
  /** Croatian Kuna */
  Hrk = "HRK",
  /** Haitian Gourde */
  Htg = "HTG",
  /** Hungarian Forint */
  Huf = "HUF",
  /** Indonesian Rupiah */
  Idr = "IDR",
  /** Irish Pound */
  Iep = "IEP",
  /** Israeli Pound */
  Ilp = "ILP",
  /** Israeli Shekel (1980–1985) */
  Ilr = "ILR",
  /** Israeli New Shekel */
  Ils = "ILS",
  /** IMP */
  Imp = "IMP",
  /** Indian Rupee */
  Inr = "INR",
  /** Iraqi Dinar */
  Iqd = "IQD",
  /** Iranian Rial */
  Irr = "IRR",
  /** Icelandic Króna (1918–1981) */
  Isj = "ISJ",
  /** Icelandic Króna */
  Isk = "ISK",
  /** Italian Lira */
  Itl = "ITL",
  /** Jamaican Dollar */
  Jmd = "JMD",
  /** Jordanian Dinar */
  Jod = "JOD",
  /** Japanese Yen */
  Jpy = "JPY",
  /** Kenyan Shilling */
  Kes = "KES",
  /** Kyrgystani Som */
  Kgs = "KGS",
  /** Cambodian Riel */
  Khr = "KHR",
  /** Comorian Franc */
  Kmf = "KMF",
  /** North Korean Won */
  Kpw = "KPW",
  /** South Korean Hwan (1953–1962) */
  Krh = "KRH",
  /** South Korean Won (1945–1953) */
  Kro = "KRO",
  /** South Korean Won */
  Krw = "KRW",
  /** Kuwaiti Dinar */
  Kwd = "KWD",
  /** Cayman Islands Dollar */
  Kyd = "KYD",
  /** Kazakhstani Tenge */
  Kzt = "KZT",
  /** Laotian Kip */
  Lak = "LAK",
  /** Lebanese Pound */
  Lbp = "LBP",
  /** Sri Lankan Rupee */
  Lkr = "LKR",
  /** Liberian Dollar */
  Lrd = "LRD",
  /** Lesotho Loti */
  Lsl = "LSL",
  /** Lithuanian Litas */
  Ltl = "LTL",
  /** Lithuanian Talonas */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc */
  Luc = "LUC",
  /** Luxembourgian Franc */
  Luf = "LUF",
  /** Luxembourg Financial Franc */
  Lul = "LUL",
  /** Latvian Lats */
  Lvl = "LVL",
  /** Latvian Ruble */
  Lvr = "LVR",
  /** Libyan Dinar */
  Lyd = "LYD",
  /** Moroccan Dirham */
  Mad = "MAD",
  /** Moroccan Franc */
  Maf = "MAF",
  /** Monegasque Franc */
  Mcf = "MCF",
  /** Moldovan Cupon */
  Mdc = "MDC",
  /** Moldovan Leu */
  Mdl = "MDL",
  /** Malagasy Ariary */
  Mga = "MGA",
  /** Malagasy Franc */
  Mgf = "MGF",
  /** Macedonian Denar */
  Mkd = "MKD",
  /** Macedonian Denar (1992–1993) */
  Mkn = "MKN",
  /** Malian Franc */
  Mlf = "MLF",
  /** Myanmar Kyat */
  Mmk = "MMK",
  /** Mongolian Tugrik */
  Mnt = "MNT",
  /** Macanese Pataca */
  Mop = "MOP",
  /** Mauritanian Ouguiya (1973–2017) */
  Mro = "MRO",
  /** Mauritanian Ouguiya */
  Mru = "MRU",
  /** Maltese Lira */
  Mtl = "MTL",
  /** Maltese Pound */
  Mtp = "MTP",
  /** Mauritian Rupee */
  Mur = "MUR",
  /** Maldivian Rupee (1947–1981) */
  Mvp = "MVP",
  /** Maldivian Rufiyaa */
  Mvr = "MVR",
  /** Malawian Kwacha */
  Mwk = "MWK",
  /** Mexican Peso */
  Mxn = "MXN",
  /** Mexican Silver Peso (1861–1992) */
  Mxp = "MXP",
  /** Mexican Investment Unit */
  Mxv = "MXV",
  /** Malaysian Ringgit */
  Myr = "MYR",
  /** Mozambican Escudo */
  Mze = "MZE",
  /** Mozambican Metical (1980–2006) */
  Mzm = "MZM",
  /** Mozambican Metical */
  Mzn = "MZN",
  /** Namibian Dollar */
  Nad = "NAD",
  /** Nigerian Naira */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (1988–1991) */
  Nic = "NIC",
  /** Nicaraguan Córdoba */
  Nio = "NIO",
  /** Dutch Guilder */
  Nlg = "NLG",
  /** Norwegian Krone */
  Nok = "NOK",
  /** Nepalese Rupee */
  Npr = "NPR",
  /** New Zealand Dollar */
  Nzd = "NZD",
  /** Omani Rial */
  Omr = "OMR",
  /** Panamanian Balboa */
  Pab = "PAB",
  /** Peruvian Inti */
  Pei = "PEI",
  /** Peruvian Sol */
  Pen = "PEN",
  /** Peruvian Sol (1863–1965) */
  Pes = "PES",
  /** Papua New Guinean Kina */
  Pgk = "PGK",
  /** Philippine Peso */
  Php = "PHP",
  /** Pakistani Rupee */
  Pkr = "PKR",
  /** Polish Zloty */
  Pln = "PLN",
  /** Polish Zloty (1950–1995) */
  Plz = "PLZ",
  /** Portuguese Escudo */
  Pte = "PTE",
  /** Paraguayan Guarani */
  Pyg = "PYG",
  /** Qatari Riyal */
  Qar = "QAR",
  /** Rhodesian Dollar */
  Rhd = "RHD",
  /** Romanian Leu (1952–2006) */
  Rol = "ROL",
  /** Romanian Leu */
  Ron = "RON",
  /** Serbian Dinar */
  Rsd = "RSD",
  /** Russian Ruble */
  Rub = "RUB",
  /** Russian Ruble (1991–1998) */
  Rur = "RUR",
  /** Rwandan Franc */
  Rwf = "RWF",
  /** Saudi Riyal */
  Sar = "SAR",
  /** Solomon Islands Dollar */
  Sbd = "SBD",
  /** Seychellois Rupee */
  Scr = "SCR",
  /** Sudanese Dinar (1992–2007) */
  Sdd = "SDD",
  /** Sudanese Pound */
  Sdg = "SDG",
  /** Sudanese Pound (1957–1998) */
  Sdp = "SDP",
  /** Swedish Krona */
  Sek = "SEK",
  /** Singapore Dollar */
  Sgd = "SGD",
  /** St. Helena Pound */
  Shp = "SHP",
  /** Slovenian Tolar */
  Sit = "SIT",
  /** Slovak Koruna */
  Skk = "SKK",
  /** Sierra Leonean Leone (1964—2022) */
  Sll = "SLL",
  /** Somali Shilling */
  Sos = "SOS",
  /** Surinamese Dollar */
  Srd = "SRD",
  /** Surinamese Guilder */
  Srg = "SRG",
  /** South Sudanese Pound */
  Ssp = "SSP",
  /** São Tomé & Príncipe Dobra (1977–2017) */
  Std = "STD",
  /** São Tomé & Príncipe Dobra */
  Stn = "STN",
  /** Soviet Rouble */
  Sur = "SUR",
  /** Salvadoran Colón */
  Svc = "SVC",
  /** Syrian Pound */
  Syp = "SYP",
  /** Swazi Lilangeni */
  Szl = "SZL",
  /** Thai Baht */
  Thb = "THB",
  /** Tajikistani Ruble */
  Tjr = "TJR",
  /** Tajikistani Somoni */
  Tjs = "TJS",
  /** Turkmenistani Manat (1993–2009) */
  Tmm = "TMM",
  /** Turkmenistani Manat */
  Tmt = "TMT",
  /** Tunisian Dinar */
  Tnd = "TND",
  /** Tongan Paʻanga */
  Top = "TOP",
  /** Timorese Escudo */
  Tpe = "TPE",
  /** Turkish Lira (1922–2005) */
  Trl = "TRL",
  /** Turkish Lira */
  Try = "TRY",
  /** Trinidad & Tobago Dollar */
  Ttd = "TTD",
  /** TVD */
  Tvd = "TVD",
  /** New Taiwan Dollar */
  Twd = "TWD",
  /** Tanzanian Shilling */
  Tzs = "TZS",
  /** Ukrainian Hryvnia */
  Uah = "UAH",
  /** Ukrainian Karbovanets */
  Uak = "UAK",
  /** Ugandan Shilling (1966–1987) */
  Ugs = "UGS",
  /** Ugandan Shilling */
  Ugx = "UGX",
  /** US Dollar */
  Usd = "USD",
  /** US Dollar (Next day) */
  Usn = "USN",
  /** US Dollar (Same day) */
  Uss = "USS",
  /** Uruguayan Peso (Indexed Units) */
  Uyi = "UYI",
  /** Uruguayan Peso (1975–1993) */
  Uyp = "UYP",
  /** Uruguayan Peso */
  Uyu = "UYU",
  /** Uruguayan Nominal Wage Index Unit */
  Uyw = "UYW",
  /** Uzbekistani Som */
  Uzs = "UZS",
  /** Venezuelan Bolívar (1871–2008) */
  Veb = "VEB",
  /** Venezuelan Bolívar (2008–2018) */
  Vef = "VEF",
  /** Venezuelan Bolívar */
  Ves = "VES",
  /** Vietnamese Dong */
  Vnd = "VND",
  /** Vietnamese Dong (1978–1985) */
  Vnn = "VNN",
  /** Vanuatu Vatu */
  Vuv = "VUV",
  /** Samoan Tala */
  Wst = "WST",
  /** Central African CFA Franc */
  Xaf = "XAF",
  /** Silver */
  Xag = "XAG",
  /** Gold */
  Xau = "XAU",
  /** European Composite Unit */
  Xba = "XBA",
  /** European Monetary Unit */
  Xbb = "XBB",
  /** European Unit of Account (XBC) */
  Xbc = "XBC",
  /** European Unit of Account (XBD) */
  Xbd = "XBD",
  /** East Caribbean Dollar */
  Xcd = "XCD",
  /** Special Drawing Rights */
  Xdr = "XDR",
  /** European Currency Unit */
  Xeu = "XEU",
  /** French Gold Franc */
  Xfo = "XFO",
  /** French UIC-Franc */
  Xfu = "XFU",
  /** West African CFA Franc */
  Xof = "XOF",
  /** Palladium */
  Xpd = "XPD",
  /** CFP Franc */
  Xpf = "XPF",
  /** Platinum */
  Xpt = "XPT",
  /** RINET Funds */
  Xre = "XRE",
  /** Sucre */
  Xsu = "XSU",
  /** Testing Currency Code */
  Xts = "XTS",
  /** ADB Unit of Account */
  Xua = "XUA",
  /** The codes assigned for transactions where no currency is involved */
  Xxx = "XXX",
  /** Yemeni Dinar */
  Ydd = "YDD",
  /** Yemeni Rial */
  Yer = "YER",
  /** Yugoslavian Hard Dinar (1966–1990) */
  Yud = "YUD",
  /** Yugoslavian New Dinar (1994–2002) */
  Yum = "YUM",
  /** Yugoslavian Convertible Dinar (1990–1992) */
  Yun = "YUN",
  /** Yugoslavian Reformed Dinar (1992–1993) */
  Yur = "YUR",
  /** South African Rand (financial) */
  Zal = "ZAL",
  /** South African Rand */
  Zar = "ZAR",
  /** Zambian Kwacha (1968–2012) */
  Zmk = "ZMK",
  /** Zambian Kwacha */
  Zmw = "ZMW",
  /** Zairean New Zaire (1993–1998) */
  Zrn = "ZRN",
  /** Zairean Zaire (1971–1993) */
  Zrz = "ZRZ",
  /** Zimbabwean Dollar (1980–2008) */
  Zwd = "ZWD",
  /** Zimbabwean Dollar (2009) */
  Zwl = "ZWL",
  /** ZWN */
  Zwn = "ZWN",
  /** Zimbabwean Dollar (2008) */
  Zwr = "ZWR",
}

/** An enumeration. */
export const enum ScreenersParticipantIncentivizedTypeChoices {
  /** Cash */
  Ca = "CA",
  /** External */
  Ex = "EX",
  /** Points */
  Pt = "PT",
}

/** An enumeration. */
export const enum ScreenersParticipantRsvpChoices {
  /** Accepted */
  Accepted = "ACCEPTED",
  /** Declined */
  Declined = "DECLINED",
  /** Undecided */
  Needsaction = "NEEDSACTION",
  /** Maybe */
  Tentative = "TENTATIVE",
}

/** An enumeration. */
export const enum ScreenersPersonCountryChoices {
  /** Australia */
  Au = "AU",
  /** Canada */
  Ca = "CA",
  /** Germany */
  De = "DE",
  /** Spain */
  Es = "ES",
  /** France */
  Fr = "FR",
  /** United Kingdom */
  Gb = "GB",
  /** United States */
  Us = "US",
}

/** An enumeration. */
export const enum ScreenersRecruitIncentiveCurrencyChoices {
  /** Andorran Peseta */
  Adp = "ADP",
  /** United Arab Emirates Dirham */
  Aed = "AED",
  /** Afghan Afghani (1927–2002) */
  Afa = "AFA",
  /** Afghan Afghani */
  Afn = "AFN",
  /** Albanian Lek (1946–1965) */
  Alk = "ALK",
  /** Albanian Lek */
  All = "ALL",
  /** Armenian Dram */
  Amd = "AMD",
  /** Netherlands Antillean Guilder */
  Ang = "ANG",
  /** Angolan Kwanza */
  Aoa = "AOA",
  /** Angolan Kwanza (1977–1991) */
  Aok = "AOK",
  /** Angolan New Kwanza (1990–2000) */
  Aon = "AON",
  /** Angolan Readjusted Kwanza (1995–1999) */
  Aor = "AOR",
  /** Argentine Austral */
  Ara = "ARA",
  /** Argentine Peso Ley (1970–1983) */
  Arl = "ARL",
  /** Argentine Peso (1881–1970) */
  Arm = "ARM",
  /** Argentine Peso (1983–1985) */
  Arp = "ARP",
  /** Argentine Peso */
  Ars = "ARS",
  /** Austrian Schilling */
  Ats = "ATS",
  /** Australian Dollar */
  Aud = "AUD",
  /** Aruban Florin */
  Awg = "AWG",
  /** Azerbaijani Manat (1993–2006) */
  Azm = "AZM",
  /** Azerbaijani Manat */
  Azn = "AZN",
  /** Bosnia-Herzegovina Dinar (1992–1994) */
  Bad = "BAD",
  /** Bosnia-Herzegovina Convertible Mark */
  Bam = "BAM",
  /** Bosnia-Herzegovina New Dinar (1994–1997) */
  Ban = "BAN",
  /** Barbadian Dollar */
  Bbd = "BBD",
  /** Bangladeshi Taka */
  Bdt = "BDT",
  /** Belgian Franc (convertible) */
  Bec = "BEC",
  /** Belgian Franc */
  Bef = "BEF",
  /** Belgian Franc (financial) */
  Bel = "BEL",
  /** Bulgarian Hard Lev */
  Bgl = "BGL",
  /** Bulgarian Socialist Lev */
  Bgm = "BGM",
  /** Bulgarian Lev */
  Bgn = "BGN",
  /** Bulgarian Lev (1879–1952) */
  Bgo = "BGO",
  /** Bahraini Dinar */
  Bhd = "BHD",
  /** Burundian Franc */
  Bif = "BIF",
  /** Bermudan Dollar */
  Bmd = "BMD",
  /** Brunei Dollar */
  Bnd = "BND",
  /** Bolivian Boliviano */
  Bob = "BOB",
  /** Bolivian Boliviano (1863–1963) */
  Bol = "BOL",
  /** Bolivian Peso */
  Bop = "BOP",
  /** Bolivian Mvdol */
  Bov = "BOV",
  /** Brazilian New Cruzeiro (1967–1986) */
  Brb = "BRB",
  /** Brazilian Cruzado (1986–1989) */
  Brc = "BRC",
  /** Brazilian Cruzeiro (1990–1993) */
  Bre = "BRE",
  /** Brazilian Real */
  Brl = "BRL",
  /** Brazilian New Cruzado (1989–1990) */
  Brn = "BRN",
  /** Brazilian Cruzeiro (1993–1994) */
  Brr = "BRR",
  /** Brazilian Cruzeiro (1942–1967) */
  Brz = "BRZ",
  /** Bahamian Dollar */
  Bsd = "BSD",
  /** Bhutanese Ngultrum */
  Btn = "BTN",
  /** Burmese Kyat */
  Buk = "BUK",
  /** Botswanan Pula */
  Bwp = "BWP",
  /** Belarusian Ruble (1994–1999) */
  Byb = "BYB",
  /** Belarusian Ruble */
  Byn = "BYN",
  /** Belarusian Ruble (2000–2016) */
  Byr = "BYR",
  /** Belize Dollar */
  Bzd = "BZD",
  /** Canadian Dollar */
  Cad = "CAD",
  /** Congolese Franc */
  Cdf = "CDF",
  /** WIR Euro */
  Che = "CHE",
  /** Swiss Franc */
  Chf = "CHF",
  /** WIR Franc */
  Chw = "CHW",
  /** Chilean Escudo */
  Cle = "CLE",
  /** Chilean Unit of Account (UF) */
  Clf = "CLF",
  /** Chilean Peso */
  Clp = "CLP",
  /** Chinese Yuan (offshore) */
  Cnh = "CNH",
  /** Chinese People’s Bank Dollar */
  Cnx = "CNX",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Colombian Peso */
  Cop = "COP",
  /** Colombian Real Value Unit */
  Cou = "COU",
  /** Costa Rican Colón */
  Crc = "CRC",
  /** Serbian Dinar (2002–2006) */
  Csd = "CSD",
  /** Czechoslovak Hard Koruna */
  Csk = "CSK",
  /** Cuban Convertible Peso */
  Cuc = "CUC",
  /** Cuban Peso */
  Cup = "CUP",
  /** Cape Verdean Escudo */
  Cve = "CVE",
  /** Cypriot Pound */
  Cyp = "CYP",
  /** Czech Koruna */
  Czk = "CZK",
  /** East German Mark */
  Ddm = "DDM",
  /** German Mark */
  Dem = "DEM",
  /** Djiboutian Franc */
  Djf = "DJF",
  /** Danish Krone */
  Dkk = "DKK",
  /** Dominican Peso */
  Dop = "DOP",
  /** Algerian Dinar */
  Dzd = "DZD",
  /** Ecuadorian Sucre */
  Ecs = "ECS",
  /** Ecuadorian Unit of Constant Value */
  Ecv = "ECV",
  /** Estonian Kroon */
  Eek = "EEK",
  /** Egyptian Pound */
  Egp = "EGP",
  /** Eritrean Nakfa */
  Ern = "ERN",
  /** Spanish Peseta (A account) */
  Esa = "ESA",
  /** Spanish Peseta (convertible account) */
  Esb = "ESB",
  /** Spanish Peseta */
  Esp = "ESP",
  /** Ethiopian Birr */
  Etb = "ETB",
  /** Euro */
  Eur = "EUR",
  /** Finnish Markka */
  Fim = "FIM",
  /** Fijian Dollar */
  Fjd = "FJD",
  /** Falkland Islands Pound */
  Fkp = "FKP",
  /** French Franc */
  Frf = "FRF",
  /** British Pound */
  Gbp = "GBP",
  /** Georgian Kupon Larit */
  Gek = "GEK",
  /** Georgian Lari */
  Gel = "GEL",
  /** Ghanaian Cedi (1979–2007) */
  Ghc = "GHC",
  /** Ghanaian Cedi */
  Ghs = "GHS",
  /** Gibraltar Pound */
  Gip = "GIP",
  /** Gambian Dalasi */
  Gmd = "GMD",
  /** Guinean Franc */
  Gnf = "GNF",
  /** Guinean Syli */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele */
  Gqe = "GQE",
  /** Greek Drachma */
  Grd = "GRD",
  /** Guatemalan Quetzal */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo */
  Gwe = "GWE",
  /** Guinea-Bissau Peso */
  Gwp = "GWP",
  /** Guyanaese Dollar */
  Gyd = "GYD",
  /** Hong Kong Dollar */
  Hkd = "HKD",
  /** Honduran Lempira */
  Hnl = "HNL",
  /** Croatian Dinar */
  Hrd = "HRD",
  /** Croatian Kuna */
  Hrk = "HRK",
  /** Haitian Gourde */
  Htg = "HTG",
  /** Hungarian Forint */
  Huf = "HUF",
  /** Indonesian Rupiah */
  Idr = "IDR",
  /** Irish Pound */
  Iep = "IEP",
  /** Israeli Pound */
  Ilp = "ILP",
  /** Israeli Shekel (1980–1985) */
  Ilr = "ILR",
  /** Israeli New Shekel */
  Ils = "ILS",
  /** IMP */
  Imp = "IMP",
  /** Indian Rupee */
  Inr = "INR",
  /** Iraqi Dinar */
  Iqd = "IQD",
  /** Iranian Rial */
  Irr = "IRR",
  /** Icelandic Króna (1918–1981) */
  Isj = "ISJ",
  /** Icelandic Króna */
  Isk = "ISK",
  /** Italian Lira */
  Itl = "ITL",
  /** Jamaican Dollar */
  Jmd = "JMD",
  /** Jordanian Dinar */
  Jod = "JOD",
  /** Japanese Yen */
  Jpy = "JPY",
  /** Kenyan Shilling */
  Kes = "KES",
  /** Kyrgystani Som */
  Kgs = "KGS",
  /** Cambodian Riel */
  Khr = "KHR",
  /** Comorian Franc */
  Kmf = "KMF",
  /** North Korean Won */
  Kpw = "KPW",
  /** South Korean Hwan (1953–1962) */
  Krh = "KRH",
  /** South Korean Won (1945–1953) */
  Kro = "KRO",
  /** South Korean Won */
  Krw = "KRW",
  /** Kuwaiti Dinar */
  Kwd = "KWD",
  /** Cayman Islands Dollar */
  Kyd = "KYD",
  /** Kazakhstani Tenge */
  Kzt = "KZT",
  /** Laotian Kip */
  Lak = "LAK",
  /** Lebanese Pound */
  Lbp = "LBP",
  /** Sri Lankan Rupee */
  Lkr = "LKR",
  /** Liberian Dollar */
  Lrd = "LRD",
  /** Lesotho Loti */
  Lsl = "LSL",
  /** Lithuanian Litas */
  Ltl = "LTL",
  /** Lithuanian Talonas */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc */
  Luc = "LUC",
  /** Luxembourgian Franc */
  Luf = "LUF",
  /** Luxembourg Financial Franc */
  Lul = "LUL",
  /** Latvian Lats */
  Lvl = "LVL",
  /** Latvian Ruble */
  Lvr = "LVR",
  /** Libyan Dinar */
  Lyd = "LYD",
  /** Moroccan Dirham */
  Mad = "MAD",
  /** Moroccan Franc */
  Maf = "MAF",
  /** Monegasque Franc */
  Mcf = "MCF",
  /** Moldovan Cupon */
  Mdc = "MDC",
  /** Moldovan Leu */
  Mdl = "MDL",
  /** Malagasy Ariary */
  Mga = "MGA",
  /** Malagasy Franc */
  Mgf = "MGF",
  /** Macedonian Denar */
  Mkd = "MKD",
  /** Macedonian Denar (1992–1993) */
  Mkn = "MKN",
  /** Malian Franc */
  Mlf = "MLF",
  /** Myanmar Kyat */
  Mmk = "MMK",
  /** Mongolian Tugrik */
  Mnt = "MNT",
  /** Macanese Pataca */
  Mop = "MOP",
  /** Mauritanian Ouguiya (1973–2017) */
  Mro = "MRO",
  /** Mauritanian Ouguiya */
  Mru = "MRU",
  /** Maltese Lira */
  Mtl = "MTL",
  /** Maltese Pound */
  Mtp = "MTP",
  /** Mauritian Rupee */
  Mur = "MUR",
  /** Maldivian Rupee (1947–1981) */
  Mvp = "MVP",
  /** Maldivian Rufiyaa */
  Mvr = "MVR",
  /** Malawian Kwacha */
  Mwk = "MWK",
  /** Mexican Peso */
  Mxn = "MXN",
  /** Mexican Silver Peso (1861–1992) */
  Mxp = "MXP",
  /** Mexican Investment Unit */
  Mxv = "MXV",
  /** Malaysian Ringgit */
  Myr = "MYR",
  /** Mozambican Escudo */
  Mze = "MZE",
  /** Mozambican Metical (1980–2006) */
  Mzm = "MZM",
  /** Mozambican Metical */
  Mzn = "MZN",
  /** Namibian Dollar */
  Nad = "NAD",
  /** Nigerian Naira */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (1988–1991) */
  Nic = "NIC",
  /** Nicaraguan Córdoba */
  Nio = "NIO",
  /** Dutch Guilder */
  Nlg = "NLG",
  /** Norwegian Krone */
  Nok = "NOK",
  /** Nepalese Rupee */
  Npr = "NPR",
  /** New Zealand Dollar */
  Nzd = "NZD",
  /** Omani Rial */
  Omr = "OMR",
  /** Panamanian Balboa */
  Pab = "PAB",
  /** Peruvian Inti */
  Pei = "PEI",
  /** Peruvian Sol */
  Pen = "PEN",
  /** Peruvian Sol (1863–1965) */
  Pes = "PES",
  /** Papua New Guinean Kina */
  Pgk = "PGK",
  /** Philippine Peso */
  Php = "PHP",
  /** Pakistani Rupee */
  Pkr = "PKR",
  /** Polish Zloty */
  Pln = "PLN",
  /** Polish Zloty (1950–1995) */
  Plz = "PLZ",
  /** Portuguese Escudo */
  Pte = "PTE",
  /** Paraguayan Guarani */
  Pyg = "PYG",
  /** Qatari Riyal */
  Qar = "QAR",
  /** Rhodesian Dollar */
  Rhd = "RHD",
  /** Romanian Leu (1952–2006) */
  Rol = "ROL",
  /** Romanian Leu */
  Ron = "RON",
  /** Serbian Dinar */
  Rsd = "RSD",
  /** Russian Ruble */
  Rub = "RUB",
  /** Russian Ruble (1991–1998) */
  Rur = "RUR",
  /** Rwandan Franc */
  Rwf = "RWF",
  /** Saudi Riyal */
  Sar = "SAR",
  /** Solomon Islands Dollar */
  Sbd = "SBD",
  /** Seychellois Rupee */
  Scr = "SCR",
  /** Sudanese Dinar (1992–2007) */
  Sdd = "SDD",
  /** Sudanese Pound */
  Sdg = "SDG",
  /** Sudanese Pound (1957–1998) */
  Sdp = "SDP",
  /** Swedish Krona */
  Sek = "SEK",
  /** Singapore Dollar */
  Sgd = "SGD",
  /** St. Helena Pound */
  Shp = "SHP",
  /** Slovenian Tolar */
  Sit = "SIT",
  /** Slovak Koruna */
  Skk = "SKK",
  /** Sierra Leonean Leone (1964—2022) */
  Sll = "SLL",
  /** Somali Shilling */
  Sos = "SOS",
  /** Surinamese Dollar */
  Srd = "SRD",
  /** Surinamese Guilder */
  Srg = "SRG",
  /** South Sudanese Pound */
  Ssp = "SSP",
  /** São Tomé & Príncipe Dobra (1977–2017) */
  Std = "STD",
  /** São Tomé & Príncipe Dobra */
  Stn = "STN",
  /** Soviet Rouble */
  Sur = "SUR",
  /** Salvadoran Colón */
  Svc = "SVC",
  /** Syrian Pound */
  Syp = "SYP",
  /** Swazi Lilangeni */
  Szl = "SZL",
  /** Thai Baht */
  Thb = "THB",
  /** Tajikistani Ruble */
  Tjr = "TJR",
  /** Tajikistani Somoni */
  Tjs = "TJS",
  /** Turkmenistani Manat (1993–2009) */
  Tmm = "TMM",
  /** Turkmenistani Manat */
  Tmt = "TMT",
  /** Tunisian Dinar */
  Tnd = "TND",
  /** Tongan Paʻanga */
  Top = "TOP",
  /** Timorese Escudo */
  Tpe = "TPE",
  /** Turkish Lira (1922–2005) */
  Trl = "TRL",
  /** Turkish Lira */
  Try = "TRY",
  /** Trinidad & Tobago Dollar */
  Ttd = "TTD",
  /** TVD */
  Tvd = "TVD",
  /** New Taiwan Dollar */
  Twd = "TWD",
  /** Tanzanian Shilling */
  Tzs = "TZS",
  /** Ukrainian Hryvnia */
  Uah = "UAH",
  /** Ukrainian Karbovanets */
  Uak = "UAK",
  /** Ugandan Shilling (1966–1987) */
  Ugs = "UGS",
  /** Ugandan Shilling */
  Ugx = "UGX",
  /** US Dollar */
  Usd = "USD",
  /** US Dollar (Next day) */
  Usn = "USN",
  /** US Dollar (Same day) */
  Uss = "USS",
  /** Uruguayan Peso (Indexed Units) */
  Uyi = "UYI",
  /** Uruguayan Peso (1975–1993) */
  Uyp = "UYP",
  /** Uruguayan Peso */
  Uyu = "UYU",
  /** Uruguayan Nominal Wage Index Unit */
  Uyw = "UYW",
  /** Uzbekistani Som */
  Uzs = "UZS",
  /** Venezuelan Bolívar (1871–2008) */
  Veb = "VEB",
  /** Venezuelan Bolívar (2008–2018) */
  Vef = "VEF",
  /** Venezuelan Bolívar */
  Ves = "VES",
  /** Vietnamese Dong */
  Vnd = "VND",
  /** Vietnamese Dong (1978–1985) */
  Vnn = "VNN",
  /** Vanuatu Vatu */
  Vuv = "VUV",
  /** Samoan Tala */
  Wst = "WST",
  /** Central African CFA Franc */
  Xaf = "XAF",
  /** Silver */
  Xag = "XAG",
  /** Gold */
  Xau = "XAU",
  /** European Composite Unit */
  Xba = "XBA",
  /** European Monetary Unit */
  Xbb = "XBB",
  /** European Unit of Account (XBC) */
  Xbc = "XBC",
  /** European Unit of Account (XBD) */
  Xbd = "XBD",
  /** East Caribbean Dollar */
  Xcd = "XCD",
  /** Special Drawing Rights */
  Xdr = "XDR",
  /** European Currency Unit */
  Xeu = "XEU",
  /** French Gold Franc */
  Xfo = "XFO",
  /** French UIC-Franc */
  Xfu = "XFU",
  /** West African CFA Franc */
  Xof = "XOF",
  /** Palladium */
  Xpd = "XPD",
  /** CFP Franc */
  Xpf = "XPF",
  /** Platinum */
  Xpt = "XPT",
  /** RINET Funds */
  Xre = "XRE",
  /** Sucre */
  Xsu = "XSU",
  /** Testing Currency Code */
  Xts = "XTS",
  /** ADB Unit of Account */
  Xua = "XUA",
  /** The codes assigned for transactions where no currency is involved */
  Xxx = "XXX",
  /** Yemeni Dinar */
  Ydd = "YDD",
  /** Yemeni Rial */
  Yer = "YER",
  /** Yugoslavian Hard Dinar (1966–1990) */
  Yud = "YUD",
  /** Yugoslavian New Dinar (1994–2002) */
  Yum = "YUM",
  /** Yugoslavian Convertible Dinar (1990–1992) */
  Yun = "YUN",
  /** Yugoslavian Reformed Dinar (1992–1993) */
  Yur = "YUR",
  /** South African Rand (financial) */
  Zal = "ZAL",
  /** South African Rand */
  Zar = "ZAR",
  /** Zambian Kwacha (1968–2012) */
  Zmk = "ZMK",
  /** Zambian Kwacha */
  Zmw = "ZMW",
  /** Zairean New Zaire (1993–1998) */
  Zrn = "ZRN",
  /** Zairean Zaire (1971–1993) */
  Zrz = "ZRZ",
  /** Zimbabwean Dollar (1980–2008) */
  Zwd = "ZWD",
  /** Zimbabwean Dollar (2009) */
  Zwl = "ZWL",
  /** ZWN */
  Zwn = "ZWN",
  /** Zimbabwean Dollar (2008) */
  Zwr = "ZWR",
}

/** An enumeration. */
export const enum ScreenersRecruitIncentiveTypeChoices {
  /** Cash */
  Ca = "CA",
  /** External */
  Ex = "EX",
  /** Points */
  Pt = "PT",
}

/** An enumeration. */
export const enum ScreenersRecruitPersonStatusChoices {
  /** Email Opened */
  E = "E",
  /** Invited */
  I = "I",
  /** Queued */
  Q = "Q",
  /** Selected */
  S = "S",
}

/** An enumeration. */
export const enum ScreenersRecruitStatusChoices {
  /** Finished */
  F = "F",
  /** Not Started */
  Ns = "NS",
  /** Paused */
  P = "P",
  /** Started */
  S = "S",
}

/** An enumeration. */
export const enum ScreenersRecruitTransactionValueCurrencyChoices {
  /** Andorran Peseta */
  Adp = "ADP",
  /** United Arab Emirates Dirham */
  Aed = "AED",
  /** Afghan Afghani (1927–2002) */
  Afa = "AFA",
  /** Afghan Afghani */
  Afn = "AFN",
  /** Albanian Lek (1946–1965) */
  Alk = "ALK",
  /** Albanian Lek */
  All = "ALL",
  /** Armenian Dram */
  Amd = "AMD",
  /** Netherlands Antillean Guilder */
  Ang = "ANG",
  /** Angolan Kwanza */
  Aoa = "AOA",
  /** Angolan Kwanza (1977–1991) */
  Aok = "AOK",
  /** Angolan New Kwanza (1990–2000) */
  Aon = "AON",
  /** Angolan Readjusted Kwanza (1995–1999) */
  Aor = "AOR",
  /** Argentine Austral */
  Ara = "ARA",
  /** Argentine Peso Ley (1970–1983) */
  Arl = "ARL",
  /** Argentine Peso (1881–1970) */
  Arm = "ARM",
  /** Argentine Peso (1983–1985) */
  Arp = "ARP",
  /** Argentine Peso */
  Ars = "ARS",
  /** Austrian Schilling */
  Ats = "ATS",
  /** Australian Dollar */
  Aud = "AUD",
  /** Aruban Florin */
  Awg = "AWG",
  /** Azerbaijani Manat (1993–2006) */
  Azm = "AZM",
  /** Azerbaijani Manat */
  Azn = "AZN",
  /** Bosnia-Herzegovina Dinar (1992–1994) */
  Bad = "BAD",
  /** Bosnia-Herzegovina Convertible Mark */
  Bam = "BAM",
  /** Bosnia-Herzegovina New Dinar (1994–1997) */
  Ban = "BAN",
  /** Barbadian Dollar */
  Bbd = "BBD",
  /** Bangladeshi Taka */
  Bdt = "BDT",
  /** Belgian Franc (convertible) */
  Bec = "BEC",
  /** Belgian Franc */
  Bef = "BEF",
  /** Belgian Franc (financial) */
  Bel = "BEL",
  /** Bulgarian Hard Lev */
  Bgl = "BGL",
  /** Bulgarian Socialist Lev */
  Bgm = "BGM",
  /** Bulgarian Lev */
  Bgn = "BGN",
  /** Bulgarian Lev (1879–1952) */
  Bgo = "BGO",
  /** Bahraini Dinar */
  Bhd = "BHD",
  /** Burundian Franc */
  Bif = "BIF",
  /** Bermudan Dollar */
  Bmd = "BMD",
  /** Brunei Dollar */
  Bnd = "BND",
  /** Bolivian Boliviano */
  Bob = "BOB",
  /** Bolivian Boliviano (1863–1963) */
  Bol = "BOL",
  /** Bolivian Peso */
  Bop = "BOP",
  /** Bolivian Mvdol */
  Bov = "BOV",
  /** Brazilian New Cruzeiro (1967–1986) */
  Brb = "BRB",
  /** Brazilian Cruzado (1986–1989) */
  Brc = "BRC",
  /** Brazilian Cruzeiro (1990–1993) */
  Bre = "BRE",
  /** Brazilian Real */
  Brl = "BRL",
  /** Brazilian New Cruzado (1989–1990) */
  Brn = "BRN",
  /** Brazilian Cruzeiro (1993–1994) */
  Brr = "BRR",
  /** Brazilian Cruzeiro (1942–1967) */
  Brz = "BRZ",
  /** Bahamian Dollar */
  Bsd = "BSD",
  /** Bhutanese Ngultrum */
  Btn = "BTN",
  /** Burmese Kyat */
  Buk = "BUK",
  /** Botswanan Pula */
  Bwp = "BWP",
  /** Belarusian Ruble (1994–1999) */
  Byb = "BYB",
  /** Belarusian Ruble */
  Byn = "BYN",
  /** Belarusian Ruble (2000–2016) */
  Byr = "BYR",
  /** Belize Dollar */
  Bzd = "BZD",
  /** Canadian Dollar */
  Cad = "CAD",
  /** Congolese Franc */
  Cdf = "CDF",
  /** WIR Euro */
  Che = "CHE",
  /** Swiss Franc */
  Chf = "CHF",
  /** WIR Franc */
  Chw = "CHW",
  /** Chilean Escudo */
  Cle = "CLE",
  /** Chilean Unit of Account (UF) */
  Clf = "CLF",
  /** Chilean Peso */
  Clp = "CLP",
  /** Chinese Yuan (offshore) */
  Cnh = "CNH",
  /** Chinese People’s Bank Dollar */
  Cnx = "CNX",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Colombian Peso */
  Cop = "COP",
  /** Colombian Real Value Unit */
  Cou = "COU",
  /** Costa Rican Colón */
  Crc = "CRC",
  /** Serbian Dinar (2002–2006) */
  Csd = "CSD",
  /** Czechoslovak Hard Koruna */
  Csk = "CSK",
  /** Cuban Convertible Peso */
  Cuc = "CUC",
  /** Cuban Peso */
  Cup = "CUP",
  /** Cape Verdean Escudo */
  Cve = "CVE",
  /** Cypriot Pound */
  Cyp = "CYP",
  /** Czech Koruna */
  Czk = "CZK",
  /** East German Mark */
  Ddm = "DDM",
  /** German Mark */
  Dem = "DEM",
  /** Djiboutian Franc */
  Djf = "DJF",
  /** Danish Krone */
  Dkk = "DKK",
  /** Dominican Peso */
  Dop = "DOP",
  /** Algerian Dinar */
  Dzd = "DZD",
  /** Ecuadorian Sucre */
  Ecs = "ECS",
  /** Ecuadorian Unit of Constant Value */
  Ecv = "ECV",
  /** Estonian Kroon */
  Eek = "EEK",
  /** Egyptian Pound */
  Egp = "EGP",
  /** Eritrean Nakfa */
  Ern = "ERN",
  /** Spanish Peseta (A account) */
  Esa = "ESA",
  /** Spanish Peseta (convertible account) */
  Esb = "ESB",
  /** Spanish Peseta */
  Esp = "ESP",
  /** Ethiopian Birr */
  Etb = "ETB",
  /** Euro */
  Eur = "EUR",
  /** Finnish Markka */
  Fim = "FIM",
  /** Fijian Dollar */
  Fjd = "FJD",
  /** Falkland Islands Pound */
  Fkp = "FKP",
  /** French Franc */
  Frf = "FRF",
  /** British Pound */
  Gbp = "GBP",
  /** Georgian Kupon Larit */
  Gek = "GEK",
  /** Georgian Lari */
  Gel = "GEL",
  /** Ghanaian Cedi (1979–2007) */
  Ghc = "GHC",
  /** Ghanaian Cedi */
  Ghs = "GHS",
  /** Gibraltar Pound */
  Gip = "GIP",
  /** Gambian Dalasi */
  Gmd = "GMD",
  /** Guinean Franc */
  Gnf = "GNF",
  /** Guinean Syli */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele */
  Gqe = "GQE",
  /** Greek Drachma */
  Grd = "GRD",
  /** Guatemalan Quetzal */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo */
  Gwe = "GWE",
  /** Guinea-Bissau Peso */
  Gwp = "GWP",
  /** Guyanaese Dollar */
  Gyd = "GYD",
  /** Hong Kong Dollar */
  Hkd = "HKD",
  /** Honduran Lempira */
  Hnl = "HNL",
  /** Croatian Dinar */
  Hrd = "HRD",
  /** Croatian Kuna */
  Hrk = "HRK",
  /** Haitian Gourde */
  Htg = "HTG",
  /** Hungarian Forint */
  Huf = "HUF",
  /** Indonesian Rupiah */
  Idr = "IDR",
  /** Irish Pound */
  Iep = "IEP",
  /** Israeli Pound */
  Ilp = "ILP",
  /** Israeli Shekel (1980–1985) */
  Ilr = "ILR",
  /** Israeli New Shekel */
  Ils = "ILS",
  /** IMP */
  Imp = "IMP",
  /** Indian Rupee */
  Inr = "INR",
  /** Iraqi Dinar */
  Iqd = "IQD",
  /** Iranian Rial */
  Irr = "IRR",
  /** Icelandic Króna (1918–1981) */
  Isj = "ISJ",
  /** Icelandic Króna */
  Isk = "ISK",
  /** Italian Lira */
  Itl = "ITL",
  /** Jamaican Dollar */
  Jmd = "JMD",
  /** Jordanian Dinar */
  Jod = "JOD",
  /** Japanese Yen */
  Jpy = "JPY",
  /** Kenyan Shilling */
  Kes = "KES",
  /** Kyrgystani Som */
  Kgs = "KGS",
  /** Cambodian Riel */
  Khr = "KHR",
  /** Comorian Franc */
  Kmf = "KMF",
  /** North Korean Won */
  Kpw = "KPW",
  /** South Korean Hwan (1953–1962) */
  Krh = "KRH",
  /** South Korean Won (1945–1953) */
  Kro = "KRO",
  /** South Korean Won */
  Krw = "KRW",
  /** Kuwaiti Dinar */
  Kwd = "KWD",
  /** Cayman Islands Dollar */
  Kyd = "KYD",
  /** Kazakhstani Tenge */
  Kzt = "KZT",
  /** Laotian Kip */
  Lak = "LAK",
  /** Lebanese Pound */
  Lbp = "LBP",
  /** Sri Lankan Rupee */
  Lkr = "LKR",
  /** Liberian Dollar */
  Lrd = "LRD",
  /** Lesotho Loti */
  Lsl = "LSL",
  /** Lithuanian Litas */
  Ltl = "LTL",
  /** Lithuanian Talonas */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc */
  Luc = "LUC",
  /** Luxembourgian Franc */
  Luf = "LUF",
  /** Luxembourg Financial Franc */
  Lul = "LUL",
  /** Latvian Lats */
  Lvl = "LVL",
  /** Latvian Ruble */
  Lvr = "LVR",
  /** Libyan Dinar */
  Lyd = "LYD",
  /** Moroccan Dirham */
  Mad = "MAD",
  /** Moroccan Franc */
  Maf = "MAF",
  /** Monegasque Franc */
  Mcf = "MCF",
  /** Moldovan Cupon */
  Mdc = "MDC",
  /** Moldovan Leu */
  Mdl = "MDL",
  /** Malagasy Ariary */
  Mga = "MGA",
  /** Malagasy Franc */
  Mgf = "MGF",
  /** Macedonian Denar */
  Mkd = "MKD",
  /** Macedonian Denar (1992–1993) */
  Mkn = "MKN",
  /** Malian Franc */
  Mlf = "MLF",
  /** Myanmar Kyat */
  Mmk = "MMK",
  /** Mongolian Tugrik */
  Mnt = "MNT",
  /** Macanese Pataca */
  Mop = "MOP",
  /** Mauritanian Ouguiya (1973–2017) */
  Mro = "MRO",
  /** Mauritanian Ouguiya */
  Mru = "MRU",
  /** Maltese Lira */
  Mtl = "MTL",
  /** Maltese Pound */
  Mtp = "MTP",
  /** Mauritian Rupee */
  Mur = "MUR",
  /** Maldivian Rupee (1947–1981) */
  Mvp = "MVP",
  /** Maldivian Rufiyaa */
  Mvr = "MVR",
  /** Malawian Kwacha */
  Mwk = "MWK",
  /** Mexican Peso */
  Mxn = "MXN",
  /** Mexican Silver Peso (1861–1992) */
  Mxp = "MXP",
  /** Mexican Investment Unit */
  Mxv = "MXV",
  /** Malaysian Ringgit */
  Myr = "MYR",
  /** Mozambican Escudo */
  Mze = "MZE",
  /** Mozambican Metical (1980–2006) */
  Mzm = "MZM",
  /** Mozambican Metical */
  Mzn = "MZN",
  /** Namibian Dollar */
  Nad = "NAD",
  /** Nigerian Naira */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (1988–1991) */
  Nic = "NIC",
  /** Nicaraguan Córdoba */
  Nio = "NIO",
  /** Dutch Guilder */
  Nlg = "NLG",
  /** Norwegian Krone */
  Nok = "NOK",
  /** Nepalese Rupee */
  Npr = "NPR",
  /** New Zealand Dollar */
  Nzd = "NZD",
  /** Omani Rial */
  Omr = "OMR",
  /** Panamanian Balboa */
  Pab = "PAB",
  /** Peruvian Inti */
  Pei = "PEI",
  /** Peruvian Sol */
  Pen = "PEN",
  /** Peruvian Sol (1863–1965) */
  Pes = "PES",
  /** Papua New Guinean Kina */
  Pgk = "PGK",
  /** Philippine Peso */
  Php = "PHP",
  /** Pakistani Rupee */
  Pkr = "PKR",
  /** Polish Zloty */
  Pln = "PLN",
  /** Polish Zloty (1950–1995) */
  Plz = "PLZ",
  /** Portuguese Escudo */
  Pte = "PTE",
  /** Paraguayan Guarani */
  Pyg = "PYG",
  /** Qatari Riyal */
  Qar = "QAR",
  /** Rhodesian Dollar */
  Rhd = "RHD",
  /** Romanian Leu (1952–2006) */
  Rol = "ROL",
  /** Romanian Leu */
  Ron = "RON",
  /** Serbian Dinar */
  Rsd = "RSD",
  /** Russian Ruble */
  Rub = "RUB",
  /** Russian Ruble (1991–1998) */
  Rur = "RUR",
  /** Rwandan Franc */
  Rwf = "RWF",
  /** Saudi Riyal */
  Sar = "SAR",
  /** Solomon Islands Dollar */
  Sbd = "SBD",
  /** Seychellois Rupee */
  Scr = "SCR",
  /** Sudanese Dinar (1992–2007) */
  Sdd = "SDD",
  /** Sudanese Pound */
  Sdg = "SDG",
  /** Sudanese Pound (1957–1998) */
  Sdp = "SDP",
  /** Swedish Krona */
  Sek = "SEK",
  /** Singapore Dollar */
  Sgd = "SGD",
  /** St. Helena Pound */
  Shp = "SHP",
  /** Slovenian Tolar */
  Sit = "SIT",
  /** Slovak Koruna */
  Skk = "SKK",
  /** Sierra Leonean Leone (1964—2022) */
  Sll = "SLL",
  /** Somali Shilling */
  Sos = "SOS",
  /** Surinamese Dollar */
  Srd = "SRD",
  /** Surinamese Guilder */
  Srg = "SRG",
  /** South Sudanese Pound */
  Ssp = "SSP",
  /** São Tomé & Príncipe Dobra (1977–2017) */
  Std = "STD",
  /** São Tomé & Príncipe Dobra */
  Stn = "STN",
  /** Soviet Rouble */
  Sur = "SUR",
  /** Salvadoran Colón */
  Svc = "SVC",
  /** Syrian Pound */
  Syp = "SYP",
  /** Swazi Lilangeni */
  Szl = "SZL",
  /** Thai Baht */
  Thb = "THB",
  /** Tajikistani Ruble */
  Tjr = "TJR",
  /** Tajikistani Somoni */
  Tjs = "TJS",
  /** Turkmenistani Manat (1993–2009) */
  Tmm = "TMM",
  /** Turkmenistani Manat */
  Tmt = "TMT",
  /** Tunisian Dinar */
  Tnd = "TND",
  /** Tongan Paʻanga */
  Top = "TOP",
  /** Timorese Escudo */
  Tpe = "TPE",
  /** Turkish Lira (1922–2005) */
  Trl = "TRL",
  /** Turkish Lira */
  Try = "TRY",
  /** Trinidad & Tobago Dollar */
  Ttd = "TTD",
  /** TVD */
  Tvd = "TVD",
  /** New Taiwan Dollar */
  Twd = "TWD",
  /** Tanzanian Shilling */
  Tzs = "TZS",
  /** Ukrainian Hryvnia */
  Uah = "UAH",
  /** Ukrainian Karbovanets */
  Uak = "UAK",
  /** Ugandan Shilling (1966–1987) */
  Ugs = "UGS",
  /** Ugandan Shilling */
  Ugx = "UGX",
  /** US Dollar */
  Usd = "USD",
  /** US Dollar (Next day) */
  Usn = "USN",
  /** US Dollar (Same day) */
  Uss = "USS",
  /** Uruguayan Peso (Indexed Units) */
  Uyi = "UYI",
  /** Uruguayan Peso (1975–1993) */
  Uyp = "UYP",
  /** Uruguayan Peso */
  Uyu = "UYU",
  /** Uruguayan Nominal Wage Index Unit */
  Uyw = "UYW",
  /** Uzbekistani Som */
  Uzs = "UZS",
  /** Venezuelan Bolívar (1871–2008) */
  Veb = "VEB",
  /** Venezuelan Bolívar (2008–2018) */
  Vef = "VEF",
  /** Venezuelan Bolívar */
  Ves = "VES",
  /** Vietnamese Dong */
  Vnd = "VND",
  /** Vietnamese Dong (1978–1985) */
  Vnn = "VNN",
  /** Vanuatu Vatu */
  Vuv = "VUV",
  /** Samoan Tala */
  Wst = "WST",
  /** Central African CFA Franc */
  Xaf = "XAF",
  /** Silver */
  Xag = "XAG",
  /** Gold */
  Xau = "XAU",
  /** European Composite Unit */
  Xba = "XBA",
  /** European Monetary Unit */
  Xbb = "XBB",
  /** European Unit of Account (XBC) */
  Xbc = "XBC",
  /** European Unit of Account (XBD) */
  Xbd = "XBD",
  /** East Caribbean Dollar */
  Xcd = "XCD",
  /** Special Drawing Rights */
  Xdr = "XDR",
  /** European Currency Unit */
  Xeu = "XEU",
  /** French Gold Franc */
  Xfo = "XFO",
  /** French UIC-Franc */
  Xfu = "XFU",
  /** West African CFA Franc */
  Xof = "XOF",
  /** Palladium */
  Xpd = "XPD",
  /** CFP Franc */
  Xpf = "XPF",
  /** Platinum */
  Xpt = "XPT",
  /** RINET Funds */
  Xre = "XRE",
  /** Sucre */
  Xsu = "XSU",
  /** Testing Currency Code */
  Xts = "XTS",
  /** ADB Unit of Account */
  Xua = "XUA",
  /** The codes assigned for transactions where no currency is involved */
  Xxx = "XXX",
  /** Yemeni Dinar */
  Ydd = "YDD",
  /** Yemeni Rial */
  Yer = "YER",
  /** Yugoslavian Hard Dinar (1966–1990) */
  Yud = "YUD",
  /** Yugoslavian New Dinar (1994–2002) */
  Yum = "YUM",
  /** Yugoslavian Convertible Dinar (1990–1992) */
  Yun = "YUN",
  /** Yugoslavian Reformed Dinar (1992–1993) */
  Yur = "YUR",
  /** South African Rand (financial) */
  Zal = "ZAL",
  /** South African Rand */
  Zar = "ZAR",
  /** Zambian Kwacha (1968–2012) */
  Zmk = "ZMK",
  /** Zambian Kwacha */
  Zmw = "ZMW",
  /** Zairean New Zaire (1993–1998) */
  Zrn = "ZRN",
  /** Zairean Zaire (1971–1993) */
  Zrz = "ZRZ",
  /** Zimbabwean Dollar (1980–2008) */
  Zwd = "ZWD",
  /** Zimbabwean Dollar (2009) */
  Zwl = "ZWL",
  /** ZWN */
  Zwn = "ZWN",
  /** Zimbabwean Dollar (2008) */
  Zwr = "ZWR",
}

/** An enumeration. */
export const enum ScreenersRecruitTypeChoices {
  /** Email */
  Em = "EM",
  /** HubUX Panel */
  Hp = "HP",
  /** Respondent.io */
  Ro = "RO",
  /** Survey Link */
  Wl = "WL",
  /** Your Panel */
  Yp = "YP",
}

/** An enumeration. */
export const enum ScreenersReminderStatusChoices {
  /** Scheduled */
  Sc = "SC",
  /** Sending */
  Sd = "SD",
  /** Sent */
  St = "ST",
}

/** An enumeration. */
export const enum ScreenersReminderTemplateVersionMeetingTypeChoices {
  /** In-person */
  P = "P",
  /** Virtual */
  V = "V",
}

/** An enumeration. */
export const enum ScreenersRespondentApprovalStatusChoices {
  /** Approved */
  A = "A",
  /** Denied */
  D = "D",
  /** Needs Review */
  Nr = "NR",
}

/** An enumeration. */
export const enum ScreenersRespondentMasterStatusChoices {
  /** Approved */
  A = "A",
  /** Completed Survey */
  Cs = "CS",
  /** Declined */
  D = "D",
  /** Interviewed */
  Iw = "IW",
  /** Screener completed */
  Nr = "NR",
  /** No Show */
  Ns = "NS",
  /** Over Quota */
  Oq = "OQ",
  /** Rated */
  Rt = "RT",
  /** Completed */
  Rw = "RW",
  /** Scheduled */
  S = "S",
  /** Started Screener */
  Ss = "SS",
  /** Termed */
  T = "T",
}

/** An enumeration. */
export const enum ScreenersRespondentStatusChoices {
  /** over quota */
  Oq = "OQ",
  /** met quota */
  Q = "Q",
  /** terminated */
  T = "T",
}

/** An enumeration. */
export const enum ScreenersScreenerTerminateRespondentChoices {
  /** Terminate at the end of the screener */
  Et = "ET",
  /** Instant Termination */
  It = "IT",
}

/** An enumeration. */
export const enum ScreenersSegmentResponseStatusChoices {
  /** over quota */
  Oq = "OQ",
  /** met quota */
  Q = "Q",
  /** terminated */
  T = "T",
}

/** An enumeration. */
export const enum ScreenersStudyContactBypassChoices {
  /** Always */
  Always = "ALWAYS",
  /** Never */
  Never = "NEVER",
  /** Term Only */
  TermOnly = "TERM_ONLY",
}

/** An enumeration. */
export const enum ScreenersStudyDefaultIncentiveCurrencyChoices {
  /** Andorran Peseta */
  Adp = "ADP",
  /** United Arab Emirates Dirham */
  Aed = "AED",
  /** Afghan Afghani (1927–2002) */
  Afa = "AFA",
  /** Afghan Afghani */
  Afn = "AFN",
  /** Albanian Lek (1946–1965) */
  Alk = "ALK",
  /** Albanian Lek */
  All = "ALL",
  /** Armenian Dram */
  Amd = "AMD",
  /** Netherlands Antillean Guilder */
  Ang = "ANG",
  /** Angolan Kwanza */
  Aoa = "AOA",
  /** Angolan Kwanza (1977–1991) */
  Aok = "AOK",
  /** Angolan New Kwanza (1990–2000) */
  Aon = "AON",
  /** Angolan Readjusted Kwanza (1995–1999) */
  Aor = "AOR",
  /** Argentine Austral */
  Ara = "ARA",
  /** Argentine Peso Ley (1970–1983) */
  Arl = "ARL",
  /** Argentine Peso (1881–1970) */
  Arm = "ARM",
  /** Argentine Peso (1983–1985) */
  Arp = "ARP",
  /** Argentine Peso */
  Ars = "ARS",
  /** Austrian Schilling */
  Ats = "ATS",
  /** Australian Dollar */
  Aud = "AUD",
  /** Aruban Florin */
  Awg = "AWG",
  /** Azerbaijani Manat (1993–2006) */
  Azm = "AZM",
  /** Azerbaijani Manat */
  Azn = "AZN",
  /** Bosnia-Herzegovina Dinar (1992–1994) */
  Bad = "BAD",
  /** Bosnia-Herzegovina Convertible Mark */
  Bam = "BAM",
  /** Bosnia-Herzegovina New Dinar (1994–1997) */
  Ban = "BAN",
  /** Barbadian Dollar */
  Bbd = "BBD",
  /** Bangladeshi Taka */
  Bdt = "BDT",
  /** Belgian Franc (convertible) */
  Bec = "BEC",
  /** Belgian Franc */
  Bef = "BEF",
  /** Belgian Franc (financial) */
  Bel = "BEL",
  /** Bulgarian Hard Lev */
  Bgl = "BGL",
  /** Bulgarian Socialist Lev */
  Bgm = "BGM",
  /** Bulgarian Lev */
  Bgn = "BGN",
  /** Bulgarian Lev (1879–1952) */
  Bgo = "BGO",
  /** Bahraini Dinar */
  Bhd = "BHD",
  /** Burundian Franc */
  Bif = "BIF",
  /** Bermudan Dollar */
  Bmd = "BMD",
  /** Brunei Dollar */
  Bnd = "BND",
  /** Bolivian Boliviano */
  Bob = "BOB",
  /** Bolivian Boliviano (1863–1963) */
  Bol = "BOL",
  /** Bolivian Peso */
  Bop = "BOP",
  /** Bolivian Mvdol */
  Bov = "BOV",
  /** Brazilian New Cruzeiro (1967–1986) */
  Brb = "BRB",
  /** Brazilian Cruzado (1986–1989) */
  Brc = "BRC",
  /** Brazilian Cruzeiro (1990–1993) */
  Bre = "BRE",
  /** Brazilian Real */
  Brl = "BRL",
  /** Brazilian New Cruzado (1989–1990) */
  Brn = "BRN",
  /** Brazilian Cruzeiro (1993–1994) */
  Brr = "BRR",
  /** Brazilian Cruzeiro (1942–1967) */
  Brz = "BRZ",
  /** Bahamian Dollar */
  Bsd = "BSD",
  /** Bhutanese Ngultrum */
  Btn = "BTN",
  /** Burmese Kyat */
  Buk = "BUK",
  /** Botswanan Pula */
  Bwp = "BWP",
  /** Belarusian Ruble (1994–1999) */
  Byb = "BYB",
  /** Belarusian Ruble */
  Byn = "BYN",
  /** Belarusian Ruble (2000–2016) */
  Byr = "BYR",
  /** Belize Dollar */
  Bzd = "BZD",
  /** Canadian Dollar */
  Cad = "CAD",
  /** Congolese Franc */
  Cdf = "CDF",
  /** WIR Euro */
  Che = "CHE",
  /** Swiss Franc */
  Chf = "CHF",
  /** WIR Franc */
  Chw = "CHW",
  /** Chilean Escudo */
  Cle = "CLE",
  /** Chilean Unit of Account (UF) */
  Clf = "CLF",
  /** Chilean Peso */
  Clp = "CLP",
  /** Chinese Yuan (offshore) */
  Cnh = "CNH",
  /** Chinese People’s Bank Dollar */
  Cnx = "CNX",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Colombian Peso */
  Cop = "COP",
  /** Colombian Real Value Unit */
  Cou = "COU",
  /** Costa Rican Colón */
  Crc = "CRC",
  /** Serbian Dinar (2002–2006) */
  Csd = "CSD",
  /** Czechoslovak Hard Koruna */
  Csk = "CSK",
  /** Cuban Convertible Peso */
  Cuc = "CUC",
  /** Cuban Peso */
  Cup = "CUP",
  /** Cape Verdean Escudo */
  Cve = "CVE",
  /** Cypriot Pound */
  Cyp = "CYP",
  /** Czech Koruna */
  Czk = "CZK",
  /** East German Mark */
  Ddm = "DDM",
  /** German Mark */
  Dem = "DEM",
  /** Djiboutian Franc */
  Djf = "DJF",
  /** Danish Krone */
  Dkk = "DKK",
  /** Dominican Peso */
  Dop = "DOP",
  /** Algerian Dinar */
  Dzd = "DZD",
  /** Ecuadorian Sucre */
  Ecs = "ECS",
  /** Ecuadorian Unit of Constant Value */
  Ecv = "ECV",
  /** Estonian Kroon */
  Eek = "EEK",
  /** Egyptian Pound */
  Egp = "EGP",
  /** Eritrean Nakfa */
  Ern = "ERN",
  /** Spanish Peseta (A account) */
  Esa = "ESA",
  /** Spanish Peseta (convertible account) */
  Esb = "ESB",
  /** Spanish Peseta */
  Esp = "ESP",
  /** Ethiopian Birr */
  Etb = "ETB",
  /** Euro */
  Eur = "EUR",
  /** Finnish Markka */
  Fim = "FIM",
  /** Fijian Dollar */
  Fjd = "FJD",
  /** Falkland Islands Pound */
  Fkp = "FKP",
  /** French Franc */
  Frf = "FRF",
  /** British Pound */
  Gbp = "GBP",
  /** Georgian Kupon Larit */
  Gek = "GEK",
  /** Georgian Lari */
  Gel = "GEL",
  /** Ghanaian Cedi (1979–2007) */
  Ghc = "GHC",
  /** Ghanaian Cedi */
  Ghs = "GHS",
  /** Gibraltar Pound */
  Gip = "GIP",
  /** Gambian Dalasi */
  Gmd = "GMD",
  /** Guinean Franc */
  Gnf = "GNF",
  /** Guinean Syli */
  Gns = "GNS",
  /** Equatorial Guinean Ekwele */
  Gqe = "GQE",
  /** Greek Drachma */
  Grd = "GRD",
  /** Guatemalan Quetzal */
  Gtq = "GTQ",
  /** Portuguese Guinea Escudo */
  Gwe = "GWE",
  /** Guinea-Bissau Peso */
  Gwp = "GWP",
  /** Guyanaese Dollar */
  Gyd = "GYD",
  /** Hong Kong Dollar */
  Hkd = "HKD",
  /** Honduran Lempira */
  Hnl = "HNL",
  /** Croatian Dinar */
  Hrd = "HRD",
  /** Croatian Kuna */
  Hrk = "HRK",
  /** Haitian Gourde */
  Htg = "HTG",
  /** Hungarian Forint */
  Huf = "HUF",
  /** Indonesian Rupiah */
  Idr = "IDR",
  /** Irish Pound */
  Iep = "IEP",
  /** Israeli Pound */
  Ilp = "ILP",
  /** Israeli Shekel (1980–1985) */
  Ilr = "ILR",
  /** Israeli New Shekel */
  Ils = "ILS",
  /** IMP */
  Imp = "IMP",
  /** Indian Rupee */
  Inr = "INR",
  /** Iraqi Dinar */
  Iqd = "IQD",
  /** Iranian Rial */
  Irr = "IRR",
  /** Icelandic Króna (1918–1981) */
  Isj = "ISJ",
  /** Icelandic Króna */
  Isk = "ISK",
  /** Italian Lira */
  Itl = "ITL",
  /** Jamaican Dollar */
  Jmd = "JMD",
  /** Jordanian Dinar */
  Jod = "JOD",
  /** Japanese Yen */
  Jpy = "JPY",
  /** Kenyan Shilling */
  Kes = "KES",
  /** Kyrgystani Som */
  Kgs = "KGS",
  /** Cambodian Riel */
  Khr = "KHR",
  /** Comorian Franc */
  Kmf = "KMF",
  /** North Korean Won */
  Kpw = "KPW",
  /** South Korean Hwan (1953–1962) */
  Krh = "KRH",
  /** South Korean Won (1945–1953) */
  Kro = "KRO",
  /** South Korean Won */
  Krw = "KRW",
  /** Kuwaiti Dinar */
  Kwd = "KWD",
  /** Cayman Islands Dollar */
  Kyd = "KYD",
  /** Kazakhstani Tenge */
  Kzt = "KZT",
  /** Laotian Kip */
  Lak = "LAK",
  /** Lebanese Pound */
  Lbp = "LBP",
  /** Sri Lankan Rupee */
  Lkr = "LKR",
  /** Liberian Dollar */
  Lrd = "LRD",
  /** Lesotho Loti */
  Lsl = "LSL",
  /** Lithuanian Litas */
  Ltl = "LTL",
  /** Lithuanian Talonas */
  Ltt = "LTT",
  /** Luxembourgian Convertible Franc */
  Luc = "LUC",
  /** Luxembourgian Franc */
  Luf = "LUF",
  /** Luxembourg Financial Franc */
  Lul = "LUL",
  /** Latvian Lats */
  Lvl = "LVL",
  /** Latvian Ruble */
  Lvr = "LVR",
  /** Libyan Dinar */
  Lyd = "LYD",
  /** Moroccan Dirham */
  Mad = "MAD",
  /** Moroccan Franc */
  Maf = "MAF",
  /** Monegasque Franc */
  Mcf = "MCF",
  /** Moldovan Cupon */
  Mdc = "MDC",
  /** Moldovan Leu */
  Mdl = "MDL",
  /** Malagasy Ariary */
  Mga = "MGA",
  /** Malagasy Franc */
  Mgf = "MGF",
  /** Macedonian Denar */
  Mkd = "MKD",
  /** Macedonian Denar (1992–1993) */
  Mkn = "MKN",
  /** Malian Franc */
  Mlf = "MLF",
  /** Myanmar Kyat */
  Mmk = "MMK",
  /** Mongolian Tugrik */
  Mnt = "MNT",
  /** Macanese Pataca */
  Mop = "MOP",
  /** Mauritanian Ouguiya (1973–2017) */
  Mro = "MRO",
  /** Mauritanian Ouguiya */
  Mru = "MRU",
  /** Maltese Lira */
  Mtl = "MTL",
  /** Maltese Pound */
  Mtp = "MTP",
  /** Mauritian Rupee */
  Mur = "MUR",
  /** Maldivian Rupee (1947–1981) */
  Mvp = "MVP",
  /** Maldivian Rufiyaa */
  Mvr = "MVR",
  /** Malawian Kwacha */
  Mwk = "MWK",
  /** Mexican Peso */
  Mxn = "MXN",
  /** Mexican Silver Peso (1861–1992) */
  Mxp = "MXP",
  /** Mexican Investment Unit */
  Mxv = "MXV",
  /** Malaysian Ringgit */
  Myr = "MYR",
  /** Mozambican Escudo */
  Mze = "MZE",
  /** Mozambican Metical (1980–2006) */
  Mzm = "MZM",
  /** Mozambican Metical */
  Mzn = "MZN",
  /** Namibian Dollar */
  Nad = "NAD",
  /** Nigerian Naira */
  Ngn = "NGN",
  /** Nicaraguan Córdoba (1988–1991) */
  Nic = "NIC",
  /** Nicaraguan Córdoba */
  Nio = "NIO",
  /** Dutch Guilder */
  Nlg = "NLG",
  /** Norwegian Krone */
  Nok = "NOK",
  /** Nepalese Rupee */
  Npr = "NPR",
  /** New Zealand Dollar */
  Nzd = "NZD",
  /** Omani Rial */
  Omr = "OMR",
  /** Panamanian Balboa */
  Pab = "PAB",
  /** Peruvian Inti */
  Pei = "PEI",
  /** Peruvian Sol */
  Pen = "PEN",
  /** Peruvian Sol (1863–1965) */
  Pes = "PES",
  /** Papua New Guinean Kina */
  Pgk = "PGK",
  /** Philippine Peso */
  Php = "PHP",
  /** Pakistani Rupee */
  Pkr = "PKR",
  /** Polish Zloty */
  Pln = "PLN",
  /** Polish Zloty (1950–1995) */
  Plz = "PLZ",
  /** Portuguese Escudo */
  Pte = "PTE",
  /** Paraguayan Guarani */
  Pyg = "PYG",
  /** Qatari Riyal */
  Qar = "QAR",
  /** Rhodesian Dollar */
  Rhd = "RHD",
  /** Romanian Leu (1952–2006) */
  Rol = "ROL",
  /** Romanian Leu */
  Ron = "RON",
  /** Serbian Dinar */
  Rsd = "RSD",
  /** Russian Ruble */
  Rub = "RUB",
  /** Russian Ruble (1991–1998) */
  Rur = "RUR",
  /** Rwandan Franc */
  Rwf = "RWF",
  /** Saudi Riyal */
  Sar = "SAR",
  /** Solomon Islands Dollar */
  Sbd = "SBD",
  /** Seychellois Rupee */
  Scr = "SCR",
  /** Sudanese Dinar (1992–2007) */
  Sdd = "SDD",
  /** Sudanese Pound */
  Sdg = "SDG",
  /** Sudanese Pound (1957–1998) */
  Sdp = "SDP",
  /** Swedish Krona */
  Sek = "SEK",
  /** Singapore Dollar */
  Sgd = "SGD",
  /** St. Helena Pound */
  Shp = "SHP",
  /** Slovenian Tolar */
  Sit = "SIT",
  /** Slovak Koruna */
  Skk = "SKK",
  /** Sierra Leonean Leone (1964—2022) */
  Sll = "SLL",
  /** Somali Shilling */
  Sos = "SOS",
  /** Surinamese Dollar */
  Srd = "SRD",
  /** Surinamese Guilder */
  Srg = "SRG",
  /** South Sudanese Pound */
  Ssp = "SSP",
  /** São Tomé & Príncipe Dobra (1977–2017) */
  Std = "STD",
  /** São Tomé & Príncipe Dobra */
  Stn = "STN",
  /** Soviet Rouble */
  Sur = "SUR",
  /** Salvadoran Colón */
  Svc = "SVC",
  /** Syrian Pound */
  Syp = "SYP",
  /** Swazi Lilangeni */
  Szl = "SZL",
  /** Thai Baht */
  Thb = "THB",
  /** Tajikistani Ruble */
  Tjr = "TJR",
  /** Tajikistani Somoni */
  Tjs = "TJS",
  /** Turkmenistani Manat (1993–2009) */
  Tmm = "TMM",
  /** Turkmenistani Manat */
  Tmt = "TMT",
  /** Tunisian Dinar */
  Tnd = "TND",
  /** Tongan Paʻanga */
  Top = "TOP",
  /** Timorese Escudo */
  Tpe = "TPE",
  /** Turkish Lira (1922–2005) */
  Trl = "TRL",
  /** Turkish Lira */
  Try = "TRY",
  /** Trinidad & Tobago Dollar */
  Ttd = "TTD",
  /** TVD */
  Tvd = "TVD",
  /** New Taiwan Dollar */
  Twd = "TWD",
  /** Tanzanian Shilling */
  Tzs = "TZS",
  /** Ukrainian Hryvnia */
  Uah = "UAH",
  /** Ukrainian Karbovanets */
  Uak = "UAK",
  /** Ugandan Shilling (1966–1987) */
  Ugs = "UGS",
  /** Ugandan Shilling */
  Ugx = "UGX",
  /** US Dollar */
  Usd = "USD",
  /** US Dollar (Next day) */
  Usn = "USN",
  /** US Dollar (Same day) */
  Uss = "USS",
  /** Uruguayan Peso (Indexed Units) */
  Uyi = "UYI",
  /** Uruguayan Peso (1975–1993) */
  Uyp = "UYP",
  /** Uruguayan Peso */
  Uyu = "UYU",
  /** Uruguayan Nominal Wage Index Unit */
  Uyw = "UYW",
  /** Uzbekistani Som */
  Uzs = "UZS",
  /** Venezuelan Bolívar (1871–2008) */
  Veb = "VEB",
  /** Venezuelan Bolívar (2008–2018) */
  Vef = "VEF",
  /** Venezuelan Bolívar */
  Ves = "VES",
  /** Vietnamese Dong */
  Vnd = "VND",
  /** Vietnamese Dong (1978–1985) */
  Vnn = "VNN",
  /** Vanuatu Vatu */
  Vuv = "VUV",
  /** Samoan Tala */
  Wst = "WST",
  /** Central African CFA Franc */
  Xaf = "XAF",
  /** Silver */
  Xag = "XAG",
  /** Gold */
  Xau = "XAU",
  /** European Composite Unit */
  Xba = "XBA",
  /** European Monetary Unit */
  Xbb = "XBB",
  /** European Unit of Account (XBC) */
  Xbc = "XBC",
  /** European Unit of Account (XBD) */
  Xbd = "XBD",
  /** East Caribbean Dollar */
  Xcd = "XCD",
  /** Special Drawing Rights */
  Xdr = "XDR",
  /** European Currency Unit */
  Xeu = "XEU",
  /** French Gold Franc */
  Xfo = "XFO",
  /** French UIC-Franc */
  Xfu = "XFU",
  /** West African CFA Franc */
  Xof = "XOF",
  /** Palladium */
  Xpd = "XPD",
  /** CFP Franc */
  Xpf = "XPF",
  /** Platinum */
  Xpt = "XPT",
  /** RINET Funds */
  Xre = "XRE",
  /** Sucre */
  Xsu = "XSU",
  /** Testing Currency Code */
  Xts = "XTS",
  /** ADB Unit of Account */
  Xua = "XUA",
  /** The codes assigned for transactions where no currency is involved */
  Xxx = "XXX",
  /** Yemeni Dinar */
  Ydd = "YDD",
  /** Yemeni Rial */
  Yer = "YER",
  /** Yugoslavian Hard Dinar (1966–1990) */
  Yud = "YUD",
  /** Yugoslavian New Dinar (1994–2002) */
  Yum = "YUM",
  /** Yugoslavian Convertible Dinar (1990–1992) */
  Yun = "YUN",
  /** Yugoslavian Reformed Dinar (1992–1993) */
  Yur = "YUR",
  /** South African Rand (financial) */
  Zal = "ZAL",
  /** South African Rand */
  Zar = "ZAR",
  /** Zambian Kwacha (1968–2012) */
  Zmk = "ZMK",
  /** Zambian Kwacha */
  Zmw = "ZMW",
  /** Zairean New Zaire (1993–1998) */
  Zrn = "ZRN",
  /** Zairean Zaire (1971–1993) */
  Zrz = "ZRZ",
  /** Zimbabwean Dollar (1980–2008) */
  Zwd = "ZWD",
  /** Zimbabwean Dollar (2009) */
  Zwl = "ZWL",
  /** ZWN */
  Zwn = "ZWN",
  /** Zimbabwean Dollar (2008) */
  Zwr = "ZWR",
}

/** An enumeration. */
export const enum ScreenersStudyEventsTypeChoices {
  /** Sessions */
  Sessions = "SESSIONS",
  /** Single */
  Single = "SINGLE",
}

/** An enumeration. */
export const enum ScreenersStudyIncentiveTypeChoices {
  /** Cash */
  Ca = "CA",
  /** External */
  Ex = "EX",
  /** Points */
  Pt = "PT",
}

/** An enumeration. */
export const enum ScreenersStudyLanguageCodeChoices {
  /** Afrikaans */
  Af = "AF",
  /** Arabic */
  Ar = "AR",
  /** Algerian Arabic */
  ArDz = "AR_DZ",
  /** Asturian */
  Ast = "AST",
  /** Azerbaijani */
  Az = "AZ",
  /** Belarusian */
  Be = "BE",
  /** Bulgarian */
  Bg = "BG",
  /** Bengali */
  Bn = "BN",
  /** Breton */
  Br = "BR",
  /** Bosnian */
  Bs = "BS",
  /** Catalan */
  Ca = "CA",
  /** Central Kurdish (Sorani) */
  Ckb = "CKB",
  /** Czech */
  Cs = "CS",
  /** Welsh */
  Cy = "CY",
  /** Danish */
  Da = "DA",
  /** German */
  De = "DE",
  /** Lower Sorbian */
  Dsb = "DSB",
  /** Greek */
  El = "EL",
  /** English */
  En = "EN",
  /** Australian English */
  EnAu = "EN_AU",
  /** British English */
  EnGb = "EN_GB",
  /** Esperanto */
  Eo = "EO",
  /** Spanish */
  Es = "ES",
  /** Argentinian Spanish */
  EsAr = "ES_AR",
  /** Colombian Spanish */
  EsCo = "ES_CO",
  /** Mexican Spanish */
  EsMx = "ES_MX",
  /** Nicaraguan Spanish */
  EsNi = "ES_NI",
  /** Venezuelan Spanish */
  EsVe = "ES_VE",
  /** Estonian */
  Et = "ET",
  /** Basque */
  Eu = "EU",
  /** Persian */
  Fa = "FA",
  /** Finnish */
  Fi = "FI",
  /** French */
  Fr = "FR",
  /** Frisian */
  Fy = "FY",
  /** Irish */
  Ga = "GA",
  /** Scottish Gaelic */
  Gd = "GD",
  /** Galician */
  Gl = "GL",
  /** Hebrew */
  He = "HE",
  /** Hindi */
  Hi = "HI",
  /** Croatian */
  Hr = "HR",
  /** Upper Sorbian */
  Hsb = "HSB",
  /** Hungarian */
  Hu = "HU",
  /** Armenian */
  Hy = "HY",
  /** Interlingua */
  Ia = "IA",
  /** Indonesian */
  Id = "ID",
  /** Igbo */
  Ig = "IG",
  /** Ido */
  Io = "IO",
  /** Icelandic */
  Is = "IS",
  /** Italian */
  It = "IT",
  /** Japanese */
  Ja = "JA",
  /** Georgian */
  Ka = "KA",
  /** Kabyle */
  Kab = "KAB",
  /** Kazakh */
  Kk = "KK",
  /** Khmer */
  Km = "KM",
  /** Kannada */
  Kn = "KN",
  /** Korean */
  Ko = "KO",
  /** Kyrgyz */
  Ky = "KY",
  /** Luxembourgish */
  Lb = "LB",
  /** Lithuanian */
  Lt = "LT",
  /** Latvian */
  Lv = "LV",
  /** Macedonian */
  Mk = "MK",
  /** Malayalam */
  Ml = "ML",
  /** Mongolian */
  Mn = "MN",
  /** Marathi */
  Mr = "MR",
  /** Malay */
  Ms = "MS",
  /** Burmese */
  My = "MY",
  /** Norwegian Bokmål */
  Nb = "NB",
  /** Nepali */
  Ne = "NE",
  /** Dutch */
  Nl = "NL",
  /** Norwegian Nynorsk */
  Nn = "NN",
  /** Ossetic */
  Os = "OS",
  /** Punjabi */
  Pa = "PA",
  /** Polish */
  Pl = "PL",
  /** Portuguese */
  Pt = "PT",
  /** Brazilian Portuguese */
  PtBr = "PT_BR",
  /** Romanian */
  Ro = "RO",
  /** Russian */
  Ru = "RU",
  /** Slovak */
  Sk = "SK",
  /** Slovenian */
  Sl = "SL",
  /** Albanian */
  Sq = "SQ",
  /** Serbian */
  Sr = "SR",
  /** Serbian Latin */
  SrLatn = "SR_LATN",
  /** Swedish */
  Sv = "SV",
  /** Swahili */
  Sw = "SW",
  /** Tamil */
  Ta = "TA",
  /** Telugu */
  Te = "TE",
  /** Tajik */
  Tg = "TG",
  /** Thai */
  Th = "TH",
  /** Turkmen */
  Tk = "TK",
  /** Turkish */
  Tr = "TR",
  /** Tatar */
  Tt = "TT",
  /** Udmurt */
  Udm = "UDM",
  /** Ukrainian */
  Uk = "UK",
  /** Urdu */
  Ur = "UR",
  /** Uzbek */
  Uz = "UZ",
  /** Vietnamese */
  Vi = "VI",
  /** Simplified Chinese */
  ZhHans = "ZH_HANS",
  /** Traditional Chinese */
  ZhHant = "ZH_HANT",
}

/** An enumeration. */
export const enum ScreenersStudyScheduledByChoices {
  /** Participant */
  P = "P",
  /** Your Team */
  T = "T",
}

/** An enumeration. */
export const enum ScreenersStudySchedulingTypeChoices {
  /** Calendly */
  Cl = "CL",
  /** HubUX */
  Hx = "HX",
}

/** An enumeration. */
export const enum ScreenersStudyStatusChoices {
  /** Complete */
  Cm = "CM",
  /** Draft */
  Dr = "DR",
  /** Active */
  Lv = "LV",
}

/** An enumeration. */
export const enum ScreenersStudyTypeChoices {
  /** Interview */
  Oo = "OO",
  /** Focus Group */
  Ss = "SS",
  /** Survey */
  Sy = "SY",
}

/** An enumeration. */
export const enum ScreenersTagColorChoices {
  /** blue */
  Bl = "BL",
  /** cyan */
  Cn = "CN",
  /** green */
  Gn = "GN",
  /** gray */
  Gy = "GY",
  /** orange */
  Og = "OG",
  /** purple */
  Pl = "PL",
  /** red */
  Rd = "RD",
  /** yellow */
  Yw = "YW",
}

export type SegmentNode = Node & {
  readonly condition?: Maybe<Scalars["GenericScalar"]>;
  readonly count?: Maybe<Scalars["Int"]>;
  readonly created: Scalars["DateTime"];
  readonly dbId: Scalars["String"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly position: Scalars["Int"];
  readonly quota?: Maybe<Scalars["Int"]>;
  readonly responses: SegmentResponseNodeConnection;
  readonly screener: ScreenerNode;
  readonly terminate: Scalars["Boolean"];
  readonly text?: Maybe<Scalars["String"]>;
};

export type SegmentNodeResponsesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type SegmentNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SegmentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `SegmentNode` and its cursor. */
export type SegmentNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SegmentNode>;
};

export type SegmentResponseNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly respondent: RespondentNode;
  readonly segment: SegmentNode;
  readonly status?: Maybe<
    ScreenersSegmentResponseStatusChoices | "%future added value" | `${ScreenersSegmentResponseStatusChoices}`
  >;
};

export type SegmentResponseNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<SegmentResponseNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `SegmentResponseNode` and its cursor. */
export type SegmentResponseNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<SegmentResponseNode>;
};

export type SelectedResponse = {
  readonly answers: ReadonlyArray<SelectedResponseAnswer>;
  readonly characteristic: CharacteristicNode;
  readonly characteristicResponses: ReadonlyArray<CharacteristicResponseNode>;
  readonly key: Scalars["String"];
  readonly text: Scalars["String"];
  readonly type: Type | "%future added value" | `${Type}`;
};

export type SelectedResponseAnswer = {
  readonly characteristicResponse?: Maybe<CharacteristicResponseNode>;
  readonly customAnswer?: Maybe<Scalars["String"]>;
  readonly key: Scalars["String"];
  readonly other?: Maybe<Scalars["Boolean"]>;
  readonly row?: Maybe<Scalars["String"]>;
  readonly rowText?: Maybe<Scalars["String"]>;
  readonly selected: Scalars["Boolean"];
  readonly text: Scalars["String"];
  readonly userSpecified?: Maybe<Scalars["Boolean"]>;
};

export type SendEmailResponse = {
  readonly recipientCount?: Maybe<Scalars["Int"]>;
  readonly sent?: Maybe<Scalars["Boolean"]>;
};

export type SendStudyEmailInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly emails?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
  readonly message: Scalars["String"];
  readonly replyTo?: InputMaybe<Scalars["String"]>;
  readonly sendTo: SendToEnum | "%future added value" | `${SendToEnum}`;
  readonly studyId: Scalars["String"];
  readonly subject: Scalars["String"];
  readonly to?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]>>>;
};

export type SendStudyEmailPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly response?: Maybe<SendEmailResponse>;
};

export type SendTestParticipantInvitesInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly emails: ReadonlyArray<Scalars["String"]>;
  readonly studyId: Scalars["String"];
};

export type SendTestParticipantInvitesPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly response?: Maybe<SendEmailResponse>;
};

export const enum SendToEnum {
  AllParticipants = "ALL_PARTICIPANTS",
  CustomList = "CUSTOM_LIST",
  SpecifiedParticipants = "SPECIFIED_PARTICIPANTS",
}

export type StartRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
};

/**
 * Starting a recruiting round creates the RecruitPerson records. It does not send any invites.
 * Invites are sent when the project is set to Active or periodically by the background worker (tasks.py)
 */
export type StartRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
};

export type StudiesNeedingApprovalsNode = {
  readonly approvalsNeeded: Scalars["Int"];
  readonly study: StudyNode;
};

export type StudiesNeedingIncentivesNode = {
  readonly incentivesNeeded: Scalars["Int"];
  readonly study: StudyNode;
};

export type StudyAvailabilitySlotConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<StudyAvailabilitySlotEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `StudyAvailabilitySlot` and its cursor. */
export type StudyAvailabilitySlotEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<StudyAvailabilitySlotNode>;
};

export type StudyAvailabilitySlotNode = Node & {
  readonly availabilityBuffer?: Maybe<Scalars["Int"]>;
  readonly availablePlaces?: Maybe<Scalars["Int"]>;
  readonly countOfBookedSlots?: Maybe<Scalars["Int"]>;
  readonly created: Scalars["DateTime"];
  readonly dId?: Maybe<Scalars["UUID"]>;
  readonly duration?: Maybe<Scalars["Int"]>;
  readonly end: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly inPerson: Scalars["Boolean"];
  readonly interviewer?: Maybe<InterviewerNode>;
  readonly manualSlot: Scalars["Boolean"];
  readonly meetingBotId?: Maybe<Scalars["String"]>;
  readonly meetingDetails?: Maybe<Scalars["String"]>;
  readonly meetingLink?: Maybe<Scalars["String"]>;
  readonly meetingLocation?: Maybe<Scalars["String"]>;
  readonly moderatorEmail?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly placesLimit?: Maybe<Scalars["Int"]>;
  readonly reminders: RemindersNodeConnection;
  readonly scheduledSlots: RespondentScheduledSlotNodeConnection;
  readonly session: StudySessionNode;
  readonly start: Scalars["DateTime"];
  readonly uuid: Scalars["UUID"];
};

export type StudyAvailabilitySlotNodeRemindersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyAvailabilitySlotNodeScheduledSlotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyAvailabilitySlotNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<StudyAvailabilitySlotNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `StudyAvailabilitySlotNode` and its cursor. */
export type StudyAvailabilitySlotNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<StudyAvailabilitySlotNode>;
};

export type StudyEmailNode = Node & {
  readonly created: Scalars["DateTime"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly message: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly recipients?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  readonly study: StudyNode;
  readonly subject: Scalars["String"];
};

export type StudyEmailNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<StudyEmailNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `StudyEmailNode` and its cursor. */
export type StudyEmailNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<StudyEmailNode>;
};

export type StudyNode = Node & {
  readonly allCount?: Maybe<Scalars["Int"]>;
  readonly allowAutoApproval: Scalars["Boolean"];
  readonly allowIpBypass: Scalars["Boolean"];
  readonly allowPanelCapture: Scalars["Boolean"];
  readonly approvedCount?: Maybe<Scalars["Int"]>;
  readonly approvedTabCount?: Maybe<Scalars["Int"]>;
  readonly automatedEmails: AutomatedEmailNodeConnection;
  readonly availabilityBuffer?: Maybe<Scalars["Int"]>;
  readonly availabilitySlots: StudyAvailabilitySlotConnection;
  readonly canHaveMultipleSessions?: Maybe<Scalars["Boolean"]>;
  readonly completeCount?: Maybe<Scalars["Int"]>;
  readonly completedOn?: Maybe<Scalars["DateTime"]>;
  readonly contactBypass:
    | ScreenersStudyContactBypassChoices
    | "%future added value"
    | `${ScreenersStudyContactBypassChoices}`;
  readonly created: Scalars["DateTime"];
  readonly dId?: Maybe<Scalars["UUID"]>;
  readonly defaultIncentive?: Maybe<Scalars["String"]>;
  readonly defaultIncentiveCurrency?: Maybe<
    | ScreenersStudyDefaultIncentiveCurrencyChoices
    | "%future added value"
    | `${ScreenersStudyDefaultIncentiveCurrencyChoices}`
  >;
  readonly defaultIncentivePoints?: Maybe<Scalars["Int"]>;
  readonly deletedOn?: Maybe<Scalars["DateTime"]>;
  readonly deprecatedDuration?: Maybe<Scalars["Int"]>;
  readonly deprecatedGoal?: Maybe<Scalars["Int"]>;
  readonly emails: StudyEmailNodeConnection;
  readonly eventsType: ScreenersStudyEventsTypeChoices | "%future added value" | `${ScreenersStudyEventsTypeChoices}`;
  readonly export?: Maybe<ExportNode>;
  readonly exports: ExportNodeConnection;
  readonly externalIncentive?: Maybe<ExternalIncentiveNode>;
  readonly fieldReport?: Maybe<FieldReportNode>;
  readonly filterPanelists: Scalars["Boolean"];
  readonly filters: ReadonlyArray<FilterNode>;
  readonly givePointsPayments: GivePointsPaymentNodeConnection;
  readonly goalPaidForCount?: Maybe<Scalars["Int"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly importId?: Maybe<Scalars["String"]>;
  readonly incentiveType:
    | ScreenersStudyIncentiveTypeChoices
    | "%future added value"
    | `${ScreenersStudyIncentiveTypeChoices}`;
  readonly interviewers: InterviewerNodeConnection;
  readonly inviteMeetingNameInPerson: Scalars["String"];
  readonly inviteMeetingNameVirtual: Scalars["String"];
  readonly inviteMessageCustomized: Scalars["Boolean"];
  readonly inviteMessageInPerson: Scalars["String"];
  readonly inviteMessageVirtual: Scalars["String"];
  readonly invitesLimit?: Maybe<Scalars["Int"]>;
  readonly iteration: Scalars["Int"];
  readonly languageCode?: Maybe<
    ScreenersStudyLanguageCodeChoices | "%future added value" | `${ScreenersStudyLanguageCodeChoices}`
  >;
  readonly launchPanelEmailSend: Scalars["Boolean"];
  readonly limitInvites: Scalars["Boolean"];
  readonly meetingLink?: Maybe<Scalars["String"]>;
  readonly modified: Scalars["DateTime"];
  readonly name?: Maybe<Scalars["String"]>;
  readonly namePublic?: Maybe<Scalars["String"]>;
  readonly needsPaymentCount?: Maybe<Scalars["Int"]>;
  readonly needsReviewCount?: Maybe<Scalars["Int"]>;
  readonly overquotaCount?: Maybe<Scalars["Int"]>;
  readonly owner: UserNode;
  readonly panelFilters?: Maybe<Scalars["GenericScalar"]>;
  readonly panelists: PanelistNodeConnection;
  readonly participants?: Maybe<ParticipantNodeConnection>;
  readonly participantsDetail?: Maybe<ParticipantConnection>;
  readonly previousStudyFilterDays?: Maybe<Scalars["Int"]>;
  readonly privacyPolicy: PrivacyPolicyNode;
  readonly privacyPolicyOptions?: Maybe<ReadonlyArray<Maybe<PrivacyPolicyNode>>>;
  readonly qualQuotaCount?: Maybe<Scalars["Int"]>;
  readonly quotaCount?: Maybe<Scalars["Int"]>;
  readonly recruit?: Maybe<RecruitNode>;
  readonly recruitpersonSet: RecruitPersonNodeConnection;
  readonly recruits: RecruitNodeConnection;
  readonly rejectedCount?: Maybe<Scalars["Int"]>;
  readonly reminderTemplates: RemindersTemplatesNodeConnection;
  readonly rewardedCount?: Maybe<Scalars["Int"]>;
  readonly scheduled?: Maybe<Scalars["DateTime"]>;
  readonly scheduledBy:
    | ScreenersStudyScheduledByChoices
    | "%future added value"
    | `${ScreenersStudyScheduledByChoices}`;
  readonly scheduledCount?: Maybe<Scalars["Int"]>;
  readonly scheduledSlots: RespondentScheduledSlotNodeConnection;
  readonly schedulingLink?: Maybe<Scalars["String"]>;
  readonly schedulingType:
    | ScreenersStudySchedulingTypeChoices
    | "%future added value"
    | `${ScreenersStudySchedulingTypeChoices}`;
  readonly screener?: Maybe<ScreenerNode>;
  readonly screenerLink?: Maybe<Scalars["String"]>;
  readonly sessions: StudySessionNodeConnection;
  readonly startDate?: Maybe<Scalars["DateTime"]>;
  readonly status: ScreenersStudyStatusChoices | "%future added value" | `${ScreenersStudyStatusChoices}`;
  readonly tags: ReadonlyArray<TagNode>;
  readonly tenant: TenantNode;
  readonly testRecruit: RecruitNode;
  readonly totalBaseCosts?: Maybe<Scalars["Decimal"]>;
  readonly totalDeposits?: Maybe<Scalars["Decimal"]>;
  readonly totalFees?: Maybe<Scalars["Decimal"]>;
  readonly totalIncentives?: Maybe<Scalars["Decimal"]>;
  readonly totalPayouts?: Maybe<Scalars["Decimal"]>;
  readonly totalPerParticipantCosts?: Maybe<Scalars["Decimal"]>;
  readonly totalRefunds?: Maybe<Scalars["Decimal"]>;
  readonly totalRefundsDue?: Maybe<Scalars["Decimal"]>;
  readonly totalSpend?: Maybe<Scalars["Decimal"]>;
  readonly type: ScreenersStudyTypeChoices | "%future added value" | `${ScreenersStudyTypeChoices}`;
  readonly usesApiToMarkComplete: Scalars["Boolean"];
  readonly voxpopmeGlobal: Scalars["Boolean"];
  readonly voxpopmeProjectId?: Maybe<Scalars["Int"]>;
  readonly voxpopmeRecorderEnabled: Scalars["Boolean"];
};

export type StudyNodeAutomatedEmailsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeAvailabilitySlotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeEmailsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeExportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeGivePointsPaymentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeInterviewersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodePanelistsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeParticipantsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includeTest?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  respondent_ApprovalStatus?: InputMaybe<ScreenersRespondentApprovalStatusChoices>;
  respondent_Person_FirstName_Icontains?: InputMaybe<Scalars["String"]>;
  respondent_Person_OptIn?: InputMaybe<Scalars["Boolean"]>;
  respondent_Status?: InputMaybe<ScreenersRespondentStatusChoices>;
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type StudyNodeParticipantsDetailArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeNonTest?: InputMaybe<Scalars["Boolean"]>;
  filterComplex?: InputMaybe<Scalars["GenericScalar"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includeTest?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  tab?: InputMaybe<Scalars["String"]>;
};

export type StudyNodeRecruitpersonSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeRecruitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeReminderTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeScheduledSlotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeSessionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudyNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<StudyNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `StudyNode` and its cursor. */
export type StudyNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<StudyNode>;
};

export type StudySessionInput = {
  readonly availabilitySlots?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilitySlotInput>>>;
  readonly duration?: InputMaybe<Scalars["Int"]>;
  readonly id?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
};

export type StudySessionNode = Node & {
  readonly availabilitySlots: StudyAvailabilitySlotNodeConnection;
  readonly countOfBookedSlots?: Maybe<Scalars["Int"]>;
  readonly created: Scalars["DateTime"];
  readonly duration?: Maybe<Scalars["Int"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly name: Scalars["String"];
  readonly position: Scalars["Int"];
  readonly scheduledSlots: RespondentScheduledSlotNodeConnection;
  readonly study: StudyNode;
};

export type StudySessionNodeAvailabilitySlotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudySessionNodeScheduledSlotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type StudySessionNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<StudySessionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `StudySessionNode` and its cursor. */
export type StudySessionNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<StudySessionNode>;
};

export type SwitchProfileInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly profileId: Scalars["String"];
};

export type SwitchProfilePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly viewer: Viewer;
};

export type TagCreateOrAttachInput = {
  readonly color?: InputMaybe<Scalars["String"]>;
  readonly id?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
};

export type TagNode = Node & {
  readonly color: ScreenersTagColorChoices | "%future added value" | `${ScreenersTagColorChoices}`;
  readonly created: Scalars["DateTime"];
  readonly dId?: Maybe<Scalars["UUID"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly modified: Scalars["DateTime"];
  readonly name: Scalars["String"];
  readonly studies: StudyNodeConnection;
  readonly tenant: TenantNode;
};

export type TagNodeStudiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantGlobalCharacteristicsConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TenantGlobalCharacteristicsEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `TenantGlobalCharacteristics` and its cursor. */
export type TenantGlobalCharacteristicsEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<CharacteristicNode>;
};

export type TenantNode = Node & {
  /** <p>When set to <em>Flagged for Potential Fraud</em>:</p><ul><li>All pending Incentive Orders will be canceled</li><li>All associated user accounts will be disabled</li></ul><p>No data will be deleted, however changing this setting back to <em>In Good Standing</em> will not undo these changes.</p> */
  readonly accountStanding:
    | AccountsTenantAccountStandingChoices
    | "%future added value"
    | `${AccountsTenantAccountStandingChoices}`;
  readonly allowExternalIncentive: Scalars["Boolean"];
  readonly allowPanelCapture: Scalars["Boolean"];
  readonly assetSet: AssetNodeConnection;
  readonly avatarUrl?: Maybe<Scalars["String"]>;
  readonly blockedCurrencies?: Maybe<Scalars["JSONString"]>;
  readonly calendarConnected?: Maybe<Scalars["Boolean"]>;
  readonly characteristics: CharacteristicNodeConnection;
  readonly created: Scalars["DateTime"];
  readonly customportal?: Maybe<CustomPortalNode>;
  readonly dId?: Maybe<Scalars["UUID"]>;
  readonly defaultSurveysToUseApi: Scalars["Boolean"];
  readonly emailDomain?: Maybe<Scalars["String"]>;
  readonly emailTemplates?: Maybe<EmailTemplateConnection>;
  readonly enableBillingTab: Scalars["Boolean"];
  readonly enableCalendlyIntegration: Scalars["Boolean"];
  readonly enableCustomCommunityTab: Scalars["Boolean"];
  readonly enableCustomPortal: Scalars["Boolean"];
  readonly enableHubuxPanel: Scalars["Boolean"];
  readonly enablePanelExport: Scalars["Boolean"];
  readonly enablePanelView: Scalars["Boolean"];
  readonly enableRecruitPotentialPanelists: Scalars["Boolean"];
  readonly enableThreatDetection: Scalars["Boolean"];
  readonly externalIncentivePerParticipantCostUsdCents: Scalars["Int"];
  readonly fakeWidgetEnabled: Scalars["Boolean"];
  readonly filters: ReadonlyArray<FilterNode>;
  readonly globalCharacteristics?: Maybe<TenantGlobalCharacteristicsConnection>;
  readonly googleCalendarEmail?: Maybe<Scalars["String"]>;
  readonly hideBillingPage: Scalars["Boolean"];
  readonly hideParticipantEmails: Scalars["Boolean"];
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentiveorderSet: PanelistBonusOrderNodeConnection;
  /** <p>Number of days to wait (after a user pays for an incentive) to allow IncentiveOrders to fully execute. After this waiting period, the IncentiveOrder is considered to have cleared and IncentiveTransactions are created to deliver incentives. A Study can't be completed until incentives have cleared.</p><p><em>This setting only applies if <strong>Require Study Payment</strong> is enabled</em></p> */
  readonly incentivesOrderFulfillmentVerificationDays?: Maybe<Scalars["Int"]>;
  /** Instead of sending incentives, send demo emails to participants */
  readonly isDemoAccount: Scalars["Boolean"];
  readonly isSelfSignup: Scalars["Boolean"];
  readonly modified: Scalars["DateTime"];
  readonly name: Scalars["String"];
  readonly oidcProviders: ReadonlyArray<OidcProviderNode>;
  readonly panel?: Maybe<PanelNode>;
  readonly panelHealthSummaryStats: PanelHealthSummaryNodeConnection;
  readonly panelistBonuses?: Maybe<PanelistBonusOrderConnection>;
  readonly panelistmembershipSet: PanelistMembershipNodeConnection;
  readonly panelists?: Maybe<PanelistConnection>;
  readonly portalCards: PortalCardNodeConnection;
  readonly profileSet: ProfileNodeConnection;
  readonly recruitTransactions: RecruitTransactionNodeConnection;
  /** Used in screener query parameters to identify source ids. Must be lowercase, start with a letter, and contain only letters, numbers, and underscores. */
  readonly recruitmentSourceKey: Scalars["String"];
  readonly reportingMeta?: Maybe<Scalars["JSONString"]>;
  readonly requirePhoneNumber: Scalars["Boolean"];
  readonly requireStudyPayment: Scalars["Boolean"];
  readonly rewardCards?: Maybe<ReadonlyArray<Maybe<RewardCardObject>>>;
  /** Used in screener query parameters to identify source groups. Must be lowercase, start with a letter, and contain only letters, numbers, and underscores. */
  readonly sourceGroupKey: Scalars["String"];
  readonly stripeCustomerId?: Maybe<Scalars["String"]>;
  readonly stripePaymentMethodId?: Maybe<Scalars["String"]>;
  readonly stripePaymentMethodLast4?: Maybe<Scalars["String"]>;
  readonly studies: StudyNodeConnection;
  readonly studiesLaunched: ReadonlyArray<StudyNode>;
  readonly surveyDomain?: Maybe<Scalars["String"]>;
  readonly tags: ReadonlyArray<TagNode>;
  readonly voxpopmeApiKey?: Maybe<Scalars["String"]>;
  readonly voxpopmeGlobal: Scalars["Boolean"];
  readonly voxpopmeLiveDefaultProjectId?: Maybe<Scalars["Int"]>;
  readonly vpmAccountId?: Maybe<Scalars["Int"]>;
};

export type TenantNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeCharacteristicsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeEmailTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeGlobalCharacteristicsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeIncentiveorderSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodePanelHealthSummaryStatsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  since: Scalars["DateTime"];
};

export type TenantNodePanelistBonusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  placedRange?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["DateTime"]>>>;
};

export type TenantNodePanelistmembershipSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodePanelistsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  panelFilters?: InputMaybe<Scalars["GenericScalar"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type TenantNodePortalCardsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeProfileSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeRecruitTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeStudiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TenantNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TenantNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `TenantNode` and its cursor. */
export type TenantNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<TenantNode>;
};

export type TenantPanelCharacteristic = {
  readonly answers?: Maybe<ReadonlyArray<Maybe<PanelAnswer>>>;
  readonly key?: Maybe<Scalars["String"]>;
  readonly title?: Maybe<Scalars["String"]>;
  readonly type?: Maybe<Scalars["String"]>;
};

/** Represents access, id, and refresh tokens */
export type TokenSet = {
  readonly accessToken: Scalars["String"];
  readonly idToken: Scalars["String"];
  readonly refreshToken: Scalars["String"];
};

export type TotalQualedNode = {
  readonly qualified?: Maybe<Scalars["Int"]>;
  readonly total?: Maybe<Scalars["Int"]>;
};

/** An enumeration. */
export const enum TransactionType {
  Dp = "DP",
  Po = "PO",
  Rf = "RF",
}

/** An enumeration. */
export const enum Type {
  Dt = "DT",
  Gs = "GS",
  Ms = "MS",
  Nm = "NM",
  Oe = "OE",
  Ss = "SS",
}

export type UpdateAnswerInput = {
  readonly answerId: Scalars["String"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly exclusive?: InputMaybe<Scalars["Boolean"]>;
  readonly quota?: InputMaybe<Scalars["Int"]>;
  readonly rating?: InputMaybe<Scalars["Int"]>;
  readonly terminate?: InputMaybe<Scalars["Boolean"]>;
  readonly terminateCondition?: InputMaybe<Scalars["GenericScalar"]>;
  readonly text?: InputMaybe<Scalars["String"]>;
  readonly userSpecified?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateAnswerPayload = {
  readonly answer?: Maybe<AnswerNode>;
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
  readonly gridAnswers?: Maybe<ReadonlyArray<Maybe<GridAnswerNode>>>;
};

export type UpdateElementInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly elementId: Scalars["String"];
  readonly label?: InputMaybe<Scalars["String"]>;
  readonly randomize?: InputMaybe<Scalars["Boolean"]>;
  readonly responseValidation?: InputMaybe<Scalars["String"]>;
  readonly showIfCondition?: InputMaybe<Scalars["GenericScalar"]>;
  readonly target?: InputMaybe<Scalars["Int"]>;
  readonly target2?: InputMaybe<Scalars["Int"]>;
  readonly targetTypes?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  readonly text?: InputMaybe<Scalars["String"]>;
  readonly usePreviousAnswers?: InputMaybe<Scalars["JSONString"]>;
};

export type UpdateElementPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
};

export type UpdateGridAnswerInput = {
  readonly answerId: Scalars["String"];
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly goal?: InputMaybe<Scalars["Int"]>;
  readonly rowId: Scalars["String"];
  readonly terminate?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateGridAnswerPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly element?: Maybe<ElementNode>;
  readonly gridAnswer?: Maybe<GridAnswerNode>;
};

export type UpdateNoteInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly panelistId?: InputMaybe<Scalars["String"]>;
  readonly respondentId?: InputMaybe<Scalars["String"]>;
  readonly text?: InputMaybe<Scalars["String"]>;
};

export type UpdateNotePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly note?: Maybe<NoteNode>;
};

export type UpdatePanelInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly panelId: Scalars["String"];
  readonly panelistLayout: ReadonlyArray<Scalars["String"]>;
};

export type UpdatePanelPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly panel: PanelNode;
};

export type UpdatePanelistInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly data: Scalars["GenericScalar"];
  readonly panelistId: Scalars["String"];
};

export type UpdatePanelistPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly panelist: PanelistNode;
};

export type UpdatePersonInput = {
  readonly avatarUrl?: InputMaybe<Scalars["String"]>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly country?: InputMaybe<Scalars["String"]>;
  readonly email?: InputMaybe<Scalars["String"]>;
  readonly firstName?: InputMaybe<Scalars["String"]>;
  readonly lastName?: InputMaybe<Scalars["String"]>;
  readonly password?: InputMaybe<Scalars["String"]>;
  readonly phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type UpdatePersonPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  /** @deprecated This field is redundant. Use person.panelist instead. */
  readonly panelist?: Maybe<PanelistNode>;
  readonly person: PersonNode;
};

export type UpdateRecruitDetailsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly description?: InputMaybe<Scalars["String"]>;
  readonly goal?: InputMaybe<Scalars["Int"]>;
  readonly incentive?: InputMaybe<Scalars["String"]>;
  readonly incentivePoints?: InputMaybe<Scalars["Int"]>;
  readonly incentiveType?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly recruitId: Scalars["String"];
  readonly remindersEnabled?: InputMaybe<Scalars["Boolean"]>;
  readonly settings?: InputMaybe<Scalars["JSONString"]>;
};

export type UpdateRecruitDetailsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
};

export type UpdateRecruitInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly meta: Scalars["GenericScalar"];
  readonly recruitId: Scalars["String"];
  readonly selectedEmails?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
};

export type UpdateRecruitPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly recruit?: Maybe<RecruitNode>;
};

export type UpdateRemindersTemplateInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly daysBefore?: InputMaybe<Scalars["Int"]>;
  readonly enabled?: InputMaybe<Scalars["Boolean"]>;
  readonly hoursBefore?: InputMaybe<Scalars["Int"]>;
  readonly message?: InputMaybe<Scalars["String"]>;
  readonly minsBefore?: InputMaybe<Scalars["Int"]>;
  readonly subject?: InputMaybe<Scalars["String"]>;
  readonly templateId: Scalars["String"];
  readonly version?: InputMaybe<Scalars["String"]>;
};

/** Updates a reminders template e.g. content, when it sends, if it's enabled */
export type UpdateRemindersTemplatePayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly remindersTemplate?: Maybe<RemindersTemplatesNode>;
};

export type UpdateRowInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly rowId: Scalars["String"];
  readonly text?: InputMaybe<Scalars["String"]>;
};

/** Update's a specified row's values. */
export type UpdateRowPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly row?: Maybe<RowNode>;
};

export type UpdateScreenerInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly description?: InputMaybe<Scalars["String"]>;
  readonly overquotaExitLink?: InputMaybe<Scalars["String"]>;
  readonly quotaExitLink?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
  readonly terminateRespondent?: InputMaybe<Scalars["String"]>;
  readonly terminatedExitLink?: InputMaybe<Scalars["String"]>;
  readonly title?: InputMaybe<Scalars["String"]>;
};

export type UpdateScreenerPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly screener?: Maybe<ScreenerNode>;
};

export type UpdateSegmentInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly condition?: InputMaybe<Scalars["GenericScalar"]>;
  readonly quota?: InputMaybe<Scalars["Int"]>;
  readonly segmentId: Scalars["String"];
  readonly terminate?: InputMaybe<Scalars["Boolean"]>;
  readonly text?: InputMaybe<Scalars["String"]>;
};

export type UpdateSegmentPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly segment?: Maybe<SegmentNode>;
};

export type UpdateStudyInput = {
  readonly allowAutoApproval?: InputMaybe<Scalars["Boolean"]>;
  readonly availabilityBuffer?: InputMaybe<Scalars["String"]>;
  readonly availabilitySlots?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilitySlotInput>>>;
  readonly claimLink?: InputMaybe<Scalars["String"]>;
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly defaultIncentive?: InputMaybe<Scalars["String"]>;
  readonly defaultIncentivePoints?: InputMaybe<Scalars["Int"]>;
  readonly duration?: InputMaybe<Scalars["String"]>;
  readonly incentiveType?: InputMaybe<Scalars["String"]>;
  readonly inviteMeetingName?: InputMaybe<Scalars["String"]>;
  readonly inviteMeetingType?: InputMaybe<Scalars["String"]>;
  readonly inviteMessage?: InputMaybe<Scalars["String"]>;
  readonly inviteMessageCustomized?: InputMaybe<Scalars["Boolean"]>;
  readonly languageCode?: InputMaybe<Scalars["String"]>;
  readonly launchPanelEmailSend?: InputMaybe<Scalars["Boolean"]>;
  readonly meetingLink?: InputMaybe<Scalars["String"]>;
  readonly name?: InputMaybe<Scalars["String"]>;
  readonly namePublic?: InputMaybe<Scalars["String"]>;
  readonly panelFilters?: InputMaybe<Scalars["GenericScalar"]>;
  readonly privacyPolicy?: InputMaybe<Scalars["String"]>;
  readonly rewardDescription?: InputMaybe<Scalars["String"]>;
  readonly scheduled?: InputMaybe<Scalars["DateTime"]>;
  readonly scheduledBy?: InputMaybe<Scalars["String"]>;
  readonly schedulingLink?: InputMaybe<Scalars["String"]>;
  readonly schedulingType?: InputMaybe<Scalars["String"]>;
  readonly studyId: Scalars["String"];
  readonly studySessions?: InputMaybe<ReadonlyArray<InputMaybe<StudySessionInput>>>;
  readonly tags?: InputMaybe<ReadonlyArray<TagCreateOrAttachInput>>;
  readonly type?: InputMaybe<Scalars["String"]>;
  readonly usesApiToMarkComplete?: InputMaybe<Scalars["Boolean"]>;
  readonly voxpopmeProjectId?: InputMaybe<Scalars["Int"]>;
  readonly voxpopmeRecorderEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateStudyPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type UpdateStudySessionsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly eventsType: Scalars["String"];
  readonly sessions: ReadonlyArray<InputMaybe<StudySessionInput>>;
  readonly studyId: Scalars["String"];
};

export type UpdateStudySessionsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly study?: Maybe<StudyNode>;
};

export type UpdateTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly color?: InputMaybe<Color | "%future added value" | `${Color}`>;
  readonly id: Scalars["String"];
  readonly name?: InputMaybe<Scalars["String"]>;
};

export type UpdateTagPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tag?: Maybe<TagNode>;
};

export type UpdateWorkspaceSettingsInput = {
  readonly clientMutationId?: InputMaybe<Scalars["String"]>;
  readonly defaultSurveysToUseApi?: InputMaybe<Scalars["Boolean"]>;
  readonly enableThreatDetection?: InputMaybe<Scalars["Boolean"]>;
  readonly fakeWidgetEnabled?: InputMaybe<Scalars["Boolean"]>;
  readonly headerMarkup?: InputMaybe<Scalars["String"]>;
  readonly headerMarkupEnabled?: InputMaybe<Scalars["Boolean"]>;
  readonly requirePhoneNumber?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateWorkspaceSettingsPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly tenant?: Maybe<TenantNode>;
};

export type UserNode = Node & {
  readonly cognitoUserId?: Maybe<Scalars["UUID"]>;
  readonly created: Scalars["DateTime"];
  readonly dId?: Maybe<Scalars["UUID"]>;
  readonly dateJoined: Scalars["DateTime"];
  readonly email: Scalars["String"];
  readonly emailtemplateSet: EmailTemplateNodeConnection;
  readonly exports: ExportNodeConnection;
  readonly firstName: Scalars["String"];
  readonly fullName?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  readonly id: Scalars["ID"];
  readonly incentiveorderSet: PanelistBonusOrderNodeConnection;
  readonly isActive: Scalars["Boolean"];
  readonly isEmailVerified: Scalars["Boolean"];
  readonly isPanelist: Scalars["Boolean"];
  readonly isStaff: Scalars["Boolean"];
  /** Designates that this user has all permissions without explicitly assigning them. */
  readonly isSuperuser: Scalars["Boolean"];
  readonly lastLogin?: Maybe<Scalars["DateTime"]>;
  readonly lastName: Scalars["String"];
  readonly modified: Scalars["DateTime"];
  readonly oidcProvider?: Maybe<OidcProviderNode>;
  readonly panelist?: Maybe<PanelistNode>;
  readonly participantSet: ParticipantNodeConnection;
  readonly profile?: Maybe<ProfileNode>;
  readonly profiles: ProfileNodeConnection;
  readonly recruitsCreated: RecruitNodeConnection;
  readonly studies: StudyNodeConnection;
  readonly vpmUserId?: Maybe<Scalars["Int"]>;
};

export type UserNodeEmailtemplateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeExportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeIncentiveorderSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeParticipantSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeRecruitsCreatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeStudiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeConnection = {
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  /** A cursor for use in pagination */
  readonly cursor: Scalars["String"];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserNode>;
};

export type Verify = {
  readonly payload: Scalars["GenericScalar"];
};

export type Viewer = {
  readonly characteristic: CharacteristicNode;
  readonly customPortal?: Maybe<CustomPortalNode>;
  readonly element?: Maybe<ElementNode>;
  readonly languages: ReadonlyArray<Maybe<LanguageNode>>;
  readonly notifications?: Maybe<NotificationsNode>;
  readonly panel?: Maybe<Panel>;
  readonly panelist?: Maybe<PanelistNode>;
  readonly privacyPolicyOptions?: Maybe<ReadonlyArray<Maybe<PrivacyPolicyNode>>>;
  readonly recruit?: Maybe<RecruitNode>;
  readonly rewardCards?: Maybe<RewardCardNodeConnection>;
  readonly studies?: Maybe<StudyNodeConnection>;
  readonly study?: Maybe<StudyNode>;
  readonly studyCount: Scalars["Int"];
  readonly user?: Maybe<UserNode>;
};

export type ViewerCharacteristicArgs = {
  characteristicId: Scalars["String"];
};

export type ViewerCustomPortalArgs = {
  customLandingHostname?: InputMaybe<Scalars["String"]>;
};

export type ViewerElementArgs = {
  elementId: Scalars["String"];
};

export type ViewerPanelistArgs = {
  panelistId?: InputMaybe<Scalars["String"]>;
};

export type ViewerRecruitArgs = {
  recruitId?: InputMaybe<Scalars["String"]>;
};

export type ViewerRewardCardsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ViewerStudiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  completedOnRange?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ScreenersStudyStatusChoices>;
  status_In?: InputMaybe<ReadonlyArray<InputMaybe<ScreenersStudyStatusChoices>>>;
  tags_In?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["ID"]>>>;
};

export type ViewerStudyArgs = {
  studyId?: InputMaybe<Scalars["String"]>;
};
