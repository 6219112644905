import { Icon } from "@iconify/react";
import { Form } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { ThemeColor } from "../../../antd";

import { AutomatedEmail, AUTOMATED_EMAIL_TYPE, PROJECT_TYPES } from "../../../constants";
import { BORDER_RADIUS, GRAY_3, SECONDARY_TEXT_COLOR } from "../../../style";
import { flattenEdges, useConfirmQuitEditing, useFlag } from "../../../utils";
import { AutomatedEmailsPage_study$data } from "../../../__generated__/AutomatedEmailsPage_study.graphql";
import ProjectPageContent from "../../ProjectPageContent";
import { AutomatedEmailEditor } from "./AutomatedEmailEditor";
import { getAutomatedEmailMeta } from "./types";

const _AutomatedEmailsPage = ({ study }: { study: AutomatedEmailsPage_study$data }) => {
  const [selectedType, setSelectedType] = useState("");
  const [editorIsDirty, setEditorIsDirty] = useState(false);
  const { router } = useRouter();
  const overflowContainerRef = useRef<HTMLDivElement>(null);

  const [editorForm] = Form.useForm();

  useEffect(() => setEditorIsDirty(false), [selectedType]);

  useEffect(() => overflowContainerRef.current?.scrollTo(0, 0), [overflowContainerRef, selectedType]);

  const confirmQuitEditing = useConfirmQuitEditing();

  const handleNavigate = useCallback(
    async () => !editorIsDirty || (await confirmQuitEditing()),
    [confirmQuitEditing, editorIsDirty]
  );

  useEffect(
    () =>
      router.addNavigationListener(
        location => {
          // required when beforeUnload is true
          if (editorIsDirty && !location) {
            return false;
          }

          return handleNavigate();
        },
        { beforeUnload: true }
      ),
    [router, editorIsDirty, handleNavigate]
  );

  const getAutomatedEmailForStatus = useCallback(
    (type: string): AutomatedEmail => {
      const existingAutomatedEmail = study.automatedEmails.edges.find(e => e?.node?.type === type)?.node;
      return existingAutomatedEmail
        ? {
            studyId: study.id,
            type: existingAutomatedEmail.type,
            enabled: existingAutomatedEmail.enabled,
            replyTo: existingAutomatedEmail.replyTo,
            subject: existingAutomatedEmail.subject,
            message: existingAutomatedEmail.message,
            ctaLabel: existingAutomatedEmail.ctaLabel,
            ctaUrl: existingAutomatedEmail.ctaUrl,
            assets: flattenEdges(existingAutomatedEmail.assets),
          }
        : {
            studyId: study.id,
            type,
            enabled: false,
            replyTo: "",
            subject: "",
            message: "",
            ctaLabel: null,
            ctaUrl: null,
            assets: [],
          };
    },
    [study]
  );

  const selectedAutomatedEmail = useMemo(
    () => getAutomatedEmailForStatus(selectedType),
    [selectedType, getAutomatedEmailForStatus]
  );

  const isVoxpopme = useFlag("platform-hubux-template");

  return (
    <ProjectPageContent style={{ display: "flex", padding: 0, height: "100%" }}>
      <TypesContainer $isVoxpopme={isVoxpopme}>
        <h1 style={{ fontWeight: 500 }}>Automated emails</h1>
        <p>These emails are automatically sent to participants when their status changes.</p>
        {study.tenant.voxpopmeGlobal && (
          <p className="panel-info-text">
            Communication customization is not available via the Voxpopme Audience Panel at this time. For information
            on the default messaging for the Voxpopme Audience Panel, contact{" "}
            <a href="mailto:support@voxpopme.com">support@voxpopme.com</a>
          </p>
        )}
        {[
          AUTOMATED_EMAIL_TYPE.NEEDS_REVIEW,
          AUTOMATED_EMAIL_TYPE.APPROVED,
          AUTOMATED_EMAIL_TYPE.DENIED,
          AUTOMATED_EMAIL_TYPE.TERMED,
          AUTOMATED_EMAIL_TYPE.OVER_QUOTA,
          AUTOMATED_EMAIL_TYPE.RATED,
          AUTOMATED_EMAIL_TYPE.REWARDED,
          ...(study.type === PROJECT_TYPES.SURVEY ? [] : [AUTOMATED_EMAIL_TYPE.SCHEDULED]),
        ].map(automatedEmailStatus => {
          const automatedEmail = getAutomatedEmailForStatus(automatedEmailStatus);
          if (!automatedEmail) return null;

          return (
            <StatusOption
              $isVoxpopme={isVoxpopme}
              key={automatedEmailStatus}
              selected={selectedType === automatedEmailStatus}
              onClick={async () => {
                if (selectedType === automatedEmailStatus) {
                  return;
                }
                if (await handleNavigate()) {
                  setSelectedType(automatedEmailStatus);
                }
              }}
            >
              <div className="status-option-header">
                <div className="status-option-display">
                  <div className="status-option-header-icon">
                    <Icon width="20" icon={getAutomatedEmailMeta(automatedEmailStatus)!.icon} />
                  </div>
                  <div className="status-option-header-description">
                    {getAutomatedEmailMeta(automatedEmailStatus)!.description}
                  </div>
                </div>
                <div className="status-option-enabled">{automatedEmail.enabled && "Enabled"}</div>
              </div>
              {!!automatedEmail.message && <div className="email-body-preview">{automatedEmail.message}</div>}
            </StatusOption>
          );
        })}
      </TypesContainer>
      <OverflowContainer ref={overflowContainerRef}>
        {!!selectedType && !!selectedAutomatedEmail ? (
          <AutomatedEmailEditor
            form={editorForm}
            study={study}
            automatedEmail={selectedAutomatedEmail}
            setIsDirty={setEditorIsDirty}
          />
        ) : (
          <div style={{ height: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h1 style={{ fontWeight: 500 }}>Select a status to view or edit</h1>
          </div>
        )}
      </OverflowContainer>
    </ProjectPageContent>
  );
};

const sidebarWidthPx = 64;
const typesContainerWidthPx = 400;

const TypesContainer = styled.div<{ $isVoxpopme: boolean }>`
  width: ${typesContainerWidthPx}px;
  padding: 24px 24px 48px;
  border-right: 1px solid ${props => (props.$isVoxpopme ? ThemeColor.VoxStroke : GRAY_3)};
  overflow-y: auto;
`;

const OverflowContainer = styled.div`
  height: 100%;
  padding: 24px;
  width: calc(100vw - ${sidebarWidthPx}px - ${typesContainerWidthPx}px);
`;

const StatusOption = styled.div<{ $isVoxpopme: boolean; selected: boolean }>`
  background: white;
  border: ${props =>
    props.selected
      ? "2px solid var(--ant-primary-color)"
      : `1px solid ${props.$isVoxpopme ? ThemeColor.VoxStroke : GRAY_3}`};
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 62px;
  margin-bottom: 12px;
  padding: ${({ selected }) => (selected ? "14px 19px" : "15px 20px")};

  .status-option-header {
    display: flex;
    justify-content: space-between;

    .status-option-display {
      display: flex;

      .status-option-header-icon {
        margin: 3px 5px 0 0;
        color: ${SECONDARY_TEXT_COLOR};
      }

      .status-option-header-description {
        font-size: 16px;
      }
    }

    .status-option-enabled {
      color: var(--ant-success-color);
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .email-body-preview {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-weight: 300;
  }
`;

export const AutomatedEmailsPage = createFragmentContainer(_AutomatedEmailsPage, {
  study: graphql`
    fragment AutomatedEmailsPage_study on StudyNode {
      id
      type
      tenant {
        voxpopmeGlobal
      }
      automatedEmails {
        edges {
          node {
            id
            type
            enabled
            replyTo
            subject
            message
            ctaLabel
            ctaUrl
            assets {
              edges {
                node {
                  id
                  name
                  sizeBytes
                  url
                }
              }
            }
          }
        }
      }
      ...AutomatedEmailEditor_study
    }
  `,
});
