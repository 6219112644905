/**
 * @generated SignedSource<<26b6b1e53d15fdad1aa97c6931619698>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteConfigQuery$variables = {};
export type RouteConfigQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"Authenticated_user">;
    } | null;
  };
};
export type RouteConfigQuery = {
  response: RouteConfigQuery$data;
  variables: RouteConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Authenticated_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vpmUserId",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPanelist",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNode",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vpmAccountId",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v2/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enablePanelView",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hideBillingPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableHubuxPanel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableBillingTab",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableCustomCommunityTab",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileNodeConnection",
                "kind": "LinkedField",
                "name": "profiles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "tenant",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PanelistNode",
                "kind": "LinkedField",
                "name": "panelist",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantNodeConnection",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v1/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomPortalNode",
                    "kind": "LinkedField",
                    "name": "customPanelistPortal",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hidePointsAndRedemption",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accentColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accentColorLighter",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logoImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "headerFontColor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalIncentives",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rewardsBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "convertedRewardsBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonNode",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "180e24e9b6d7af721964db4d384814bf",
    "id": null,
    "metadata": {},
    "name": "RouteConfigQuery",
    "operationKind": "query",
    "text": "query RouteConfigQuery {\n  viewer {\n    user {\n      ...Authenticated_user\n      id\n    }\n  }\n}\n\nfragment Authenticated_LDUser_user on UserNode {\n  dId\n  email\n  fullName\n  isPanelist\n}\n\nfragment Authenticated_user on UserNode {\n  vpmUserId\n  dId\n  email\n  fullName\n  isPanelist\n  profile {\n    id\n    tenant {\n      vpmAccountId\n      dId\n      name\n      id\n    }\n  }\n  profiles {\n    edges {\n      node {\n        id\n        tenant {\n          name\n          id\n        }\n      }\n    }\n  }\n  panelist {\n    dId\n    tenants {\n      edges {\n        node {\n          name\n          id\n        }\n      }\n    }\n    customPanelistPortal {\n      hidePointsAndRedemption\n      id\n    }\n    id\n  }\n  ...Authenticated_LDUser_user\n  ...PanelistLayout_user\n  ...StaffLayout_user\n}\n\nfragment PanelistLayout_user on UserNode {\n  fullName\n  email\n  profile {\n    tenant {\n      name\n      id\n    }\n    id\n  }\n  panelist {\n    customPanelistPortal {\n      accentColor\n      accentColorLighter\n      logoImageUrl\n      customColor\n      headerFontColor\n      hidePointsAndRedemption\n      id\n    }\n    totalIncentives\n    rewardsBalance\n    convertedRewardsBalance\n    person {\n      country\n      id\n    }\n    id\n  }\n}\n\nfragment StaffLayout_user on UserNode {\n  dId\n  email\n  fullName\n  panelist {\n    dId\n    tenants {\n      edges {\n        node {\n          dId\n          name\n          id\n        }\n      }\n    }\n    id\n  }\n  profile {\n    tenant {\n      dId\n      enablePanelView\n      hideBillingPage\n      name\n      enableHubuxPanel\n      enableBillingTab\n      enableCustomCommunityTab\n      id\n    }\n    id\n  }\n  profiles {\n    edges {\n      node {\n        id\n        tenant {\n          name\n          id\n        }\n      }\n    }\n  }\n  vpmUserId\n}\n"
  }
};
})();

(node as any).hash = "6d2e65c632b866a1f40e34d02fbd0287";

export default node;
