import { App, Button, Checkbox, Form, Input, Typography } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Link, useRouter } from "found";
import { useEffect, useReducer, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { commitMutation, createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { LoggedOutPage } from "..";
import { environment } from "../../relay";
import {
  checkAuth,
  flattenEdges,
  getJwt,
  getUserContext,
  GraphQLError,
  identify,
  setJwt,
  trackEvent,
} from "../../utils";
import { getFaviconEl } from "../../utils/misc";
import type { PortalRegistrationPageLoginMutation } from "../../__generated__/PortalRegistrationPageLoginMutation.graphql";
import type {
  PortalRegistrationPageMutation,
  PortalRegistrationPageMutation$variables,
} from "../../__generated__/PortalRegistrationPageMutation.graphql";
import type { PortalRegistrationPage_customPortal$data } from "../../__generated__/PortalRegistrationPage_customPortal.graphql";

const { Link: A } = Typography;

const login = (variables: { email: string; password: string }) =>
  new Promise((resolve, reject) => {
    commitMutation<PortalRegistrationPageLoginMutation>(environment, {
      mutation: graphql`
        mutation PortalRegistrationPageLoginMutation($email: String!, $password: String!) {
          tokenAuth(email: $email, password: $password) {
            token
            user {
              dId
              email
              fullName
              panelist {
                dId
                tenants {
                  edges {
                    node {
                      dId
                      name
                    }
                  }
                }
              }
              profile {
                tenant {
                  id
                  dId
                  name
                  enablePanelView
                }
              }
              dId
              vpmUserId
              panelist {
                dId
              }
            }
          }
        }
      `,
      variables,
      onCompleted: ({ tokenAuth }, errors) => {
        if (errors) reject(new Error(errors[0]?.message));
        else if (!tokenAuth?.token) reject(new Error("token not in response"));
        else {
          setJwt(tokenAuth.token);
          trackEvent("Register Panelist", {
            ...getUserContext(tokenAuth.user as any),
            Tenants: tokenAuth?.user?.panelist?.tenants?.edges.map((tenant: any) => tenant.node.name).join(", "),
          });
          identify(tokenAuth?.user as any);
          resolve(tokenAuth);
        }
      },
      onError: err => {
        const _message: string | undefined = (err as any)?.errors?.[0]?.message;
        reject(new Error(_message));
      },
    });
  });

const register = (variables: Omit<PortalRegistrationPageMutation$variables, "customLandingHostname">) =>
  new Promise((resolve, reject) =>
    commitMutation<PortalRegistrationPageMutation>(environment, {
      mutation: graphql`
        mutation PortalRegistrationPageMutation(
          $agreements: [String!]!
          $customLandingHostname: String!
          $email: String!
          $firstName: String!
          $lastName: String!
          $otpValue: String
          $password: String!
        ) {
          registerPanelist(
            input: {
              agreements: $agreements
              customLandingHostname: $customLandingHostname
              email: $email
              firstName: $firstName
              lastName: $lastName
              otpValue: $otpValue
              password: $password
            }
          ) {
            user {
              id
              email
            }
          }
        }
      `,
      variables: {
        ...variables,
        customLandingHostname: window.location.origin,
      },
      onCompleted: ({ registerPanelist }, errors) => {
        if (errors?.[0]) reject(new GraphQLError(errors[0]));
        else if (!registerPanelist?.user?.id) reject(new Error("User already exists with that email address"));
        else resolve(registerPanelist.user.id);
      },
      onError: e => reject(new GraphQLError((e as any)?.errors?.[0])),
    })
  );

const PortalRegistrationPage = ({ customPortal }: { customPortal?: PortalRegistrationPage_customPortal$data }) => {
  const { message } = App.useApp();
  const { match, router } = useRouter();

  useEffect(() => {
    // On mount we check for a token. If found we check the token is valid.
    // If valid, we forward the user to the app
    const { jwt: token } = getJwt();
    if (token === null) return;

    checkAuth().then(user => {
      if (!user.email) return;
      else if (user.isTenant) router.push("/projects");
      else if (user.isPanelist) router.push("/portal");
    });
  }, [router]);

  useEffect(() => {
    // Set the favicon and page title for whitelabeled sites
    const faviconEl = getFaviconEl();
    if (faviconEl && customPortal?.faviconUrl) faviconEl.href = customPortal?.faviconUrl;
    if (customPortal?.pageTitle) document.title = customPortal?.pageTitle;
  }, [customPortal]);

  type AgreementsState = Record<string, { agreed: boolean; text: string }>;
  const [agreements, dispatchAgreements] = useReducer(
    (state: AgreementsState, { id, agreed }: { id: string; agreed: boolean }): AgreementsState => ({
      ...state,
      [id]: { ...state[id]!, agreed },
    }),
    flattenEdges(customPortal?.agreements).reduce(
      (acc, { id, text }) => ({ ...acc, [id]: { text, agreed: false } }),
      {}
    )
  );
  const { first: firstName, last: lastName, email, otp: otpValue } = match.location.query;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const submitForm = () =>
    form
      .validateFields()
      .then(values => {
        setLoading(true);
        const { email, firstName, lastName, otpValue, password } = values;
        return register({
          agreements: Object.entries(agreements)
            .filter(([, x]) => x.agreed)
            .map(([id]) => id),
          email,
          firstName,
          lastName,
          otpValue,
          password,
        });
      })
      .then(() => {
        login(form.getFieldsValue(["email", "password"]))
          .then(() => router.push("/portal"))
          .catch(err => {
            message.error(err.message);
            if (err.message !== "Please enter valid credentials") console.error(err);
            setLoading(false);
          });
      })
      .catch(e => {
        if (e instanceof GraphQLError && e.payload.extensions?.to === "token_auth") {
          const dismiss = message.error(
            <>
              You're already signed up.{" "}
              <Link to="/login">
                {({ onClick, ...props }) => (
                  <A
                    {...props}
                    onClick={e => {
                      dismiss();
                      onClick(e);
                    }}
                  >
                    Please log in or reset your password.
                  </A>
                )}
              </Link>
            </>,
            10
          );
        } else {
          message.error(e.message);
          setLoading(false);
        }
      });

  const { termsLabel, termsUrl } = customPortal ?? {};
  const termsVisible = !!termsLabel && !!termsUrl;

  const { t } = useTranslation();

  return (
    <LoggedOutPage
      customLogoUrl={customPortal?.logoImageUrl}
      customColor={customPortal?.customColor}
      customHeaderFontColor={customPortal?.headerFontColor}
      customFooterUrl={customPortal?.footerImageUrl}
      contactEmail={customPortal?.contactEmail}
    >
      <Styled>
        <div className="title">
          <Trans i18nKey="portal.registration-page.form.cta">
            Register to be the first to know about upcoming studies.
          </Trans>
        </div>
        <Form
          layout="vertical"
          form={form}
          validateTrigger="onSubmit"
          onFinish={() => submitForm()}
          initialValues={{ email, firstName, lastName, otpValue }}
        >
          <Form.Item
            name="firstName"
            label={t("portal-registration-page.first-name-label", "First name")}
            rules={[
              {
                required: true,
                message: t("portal.registration-page.form.first-name-required", "Please provide your first name."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t("portal-registration-page.last-name-label", "Last name")}
            rules={[
              {
                required: true,
                message: t("portal.registration-page.form.last-name-required", "Please provide your last name."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("portal-registration-page.email-name-label", "Email")}
            rules={[
              {
                type: "email",
                message: t("portal.registration-page.form.valid-email-required", "Please provide a valid email"),
              },
              {
                required: true,
                message: t("portal.registration-page.form.email-required", "Please provide your email"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="otpValue" hidden />
          <Form.Item
            name="password"
            label={t("portal-registration-page.password-label", "Password")}
            rules={[
              {
                required: true,
                message: t("portal.registration-page.form.password-required", "Please provide a password"),
              },
              {
                min: 8,
                message: t(
                  "portal.registration-page.form.password-required-length",
                  "Passwords should be min. 8 characters"
                ),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={t("portal.registration-page.form.confirm-password-label", "Confirm password")}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: t("portal.registration-page.form.confirm-password", "Please confirm your password"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t(
                      "portal.registration-page.form.passwords-mismatch",
                      "The two passwords that you entered do not match"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="permission"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        t("portal.registration-page.form.accept-privacy-policy", "Please accept our privacy policy")
                      ),
              },
            ]}
          >
            <Checkbox>
              {termsVisible ? (
                <Trans i18nKey="portal.registration-page.form.privacy-policy-with-terms" values={{ termsLabel }}>
                  I agree to the{" "}
                  <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                    {"{{ termsLabel }}"}
                  </a>
                  ,{" "}
                  <a
                    href={
                      customPortal?.privacyPolicyUrl ||
                      "https://www.voxpopme.com/privacy-and-legal/voxpopme_privacy-policy/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  , and to be contacted via email about potential studies.
                </Trans>
              ) : (
                <Trans i18nKey="portal.registration-page.form.privacy-policy">
                  I agree to the{" "}
                  <a
                    href={
                      customPortal?.privacyPolicyUrl ||
                      "https://www.voxpopme.com/privacy-and-legal/voxpopme_privacy-policy/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and to be contacted via email about potential studies.
                </Trans>
              )}
            </Checkbox>
          </Form.Item>
          {Object.entries(agreements).map(([id, x]) => (
            <Form.Item
              className="hub-form-item-agreement"
              name={`agreement_${id}`}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          t("portal.registration-page.form.accept-privacy-policy", "Please agree to these terms")
                        ),
                },
              ]}
            >
              <Checkbox checked={x.agreed} onChange={e => dispatchAgreements({ id, agreed: e.target.checked })}>
                {x.text}
              </Checkbox>
            </Form.Item>
          ))}
          <Form.Item className="complete">
            <Button style={{ marginLeft: 12 }} loading={loading} type="primary" htmlType="submit">
              <Trans i18nKey="portal.registration-page.form.sign-up">Sign Up</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Styled>
    </LoggedOutPage>
  );
};

const Styled = styled.div`
  .title {
    font-size: 16px;
    margin-bottom: 2rem;
  }

  .complete .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .hub-form-item-agreement {
    margin-top: -16px;
  }
`;

export default createFragmentContainer(PortalRegistrationPage, {
  customPortal: graphql`
    fragment PortalRegistrationPage_customPortal on CustomPortalNode {
      agreements {
        edges {
          node {
            id
            text
          }
        }
      }
      contactEmail
      customColor
      headerFontColor
      faviconUrl
      footerImageUrl
      logoImageUrl
      pageTitle
      privacyPolicyUrl
      termsLabel
      termsUrl
    }
  `,
});
