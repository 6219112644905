import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

export const PortalFooter = ({
  contactEmail,
  customFooterUrl,
  privacyPolicyLink,
  termsLabel,
  termsUrl,
}: {
  contactEmail: string;
  customFooterUrl?: string | null;
  privacyPolicyLink?: string | null;
  termsLabel?: string;
  termsUrl?: string | null;
}) => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <div className="info">
        <Trans i18nKey="portal.footer.powered-by-hubux">
          <span>Powered by </span>
          <a target="__blank" href="https://hubux.com" rel="noopener noreferrer">
            HubUX
          </a>
        </Trans>
        ·
        {!!termsLabel && !!termsUrl && (
          <>
            <a target="__blank" href={termsUrl} rel="noopener noreferrer">
              {termsLabel}
            </a>
            ·
          </>
        )}
        <a
          target="__blank"
          href={privacyPolicyLink || "https://www.voxpopme.com/privacy-and-legal/voxpopme_privacy-policy/"}
          rel="noopener noreferrer"
        >
          {t("portal.footer.privacy-policy", "Privacy Policy")}
        </a>
        ·
        <a target="__blank" href="https://hubux.com/ccpa/" rel="noopener noreferrer">
          {t("portal.footer.california-notice", "California Consumer Privacy Act Notice")}
        </a>
        ·<a href={`mailto:${contactEmail}`}>{t("portal.footer.contact-us", "Contact Us")}</a>
      </div>
      {customFooterUrl && (
        <FooterImageWrapper>
          <img className="footer-image" src={customFooterUrl} alt="" />
        </FooterImageWrapper>
      )}
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  width: 100vw;
  margin: 24px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bfbfbf;
  a {
    padding: 0 5px;
  }
  .info {
    width: 90%;
    @media (min-width: 600px) {
      width: auto;
    }
  }
`;

const FooterImageWrapper = styled.div`
  height: auto;
  width: 400px;
  max-width: 90vw;

  .footer-image {
    margin-top: 25px;
    width: 100%;
  }
`;
