import { PlusOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Button, Input, message, Modal, Popover, Space, Table, Tabs, TabsProps } from "antd";
import axios from "axios";
import classNames, { type Argument } from "classnames";
import { debounce } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getAuthorizationHeader } from "../../utils/auth";
import { getApiBaseUrl } from "../../utils/misc";

// Types
type ProjectData = {
  campaign_name: string;
  created: string;
  created_by_email: string;
  id: number;
};

type InterviewRoomData = {
  id: number;
  name: string;
  project_name: string;
  project_link?: string;
  status: string;
  links?: {
    moderator?: string;
  };
};

type SearchInterviewRoomsProjectsProps = {
  className?: Argument;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
  studyId?: string;
  onSelectLink?: (link: string) => void;
};

export const SearchInterviewRoomsProjects = ({
  className,
  disabled = false,
  style,
  onClose,
  studyId,
  onSelectLink,
}: SearchInterviewRoomsProjectsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("2");
  const [loading, setLoading] = useState(false);

  const [currentProjectsSearch, setCurrentProjectsSearch] = useState("");
  const [currentInterviewRoomsSearch, setCurrentInterviewRoomsSearch] = useState("");

  const [projectsData, setProjectsData] = useState<ProjectData[]>([]);
  const [interviewRoomsData, setInterviewRoomsData] = useState<InterviewRoomData[]>([]);

  const [roomNames, setRoomNames] = useState<Record<string, string>>({});
  const [popoverVisible, setPopoverVisible] = useState<Record<string, boolean>>({});
  const [interviewRoomsPage, setInterviewRoomsPage] = useState(1);
  const [interviewRoomsTotal, setInterviewRoomsTotal] = useState(0);

  const [projectsPage, setProjectsPage] = useState(1);
  const [projectsTotal, setProjectsTotal] = useState(0);

  const fetchInterviewRooms = useCallback(
    async (searchValue: string, pageOverride?: number) => {
      if (!studyId) return;

      setLoading(true);
      try {
        // Use the pageOverride parameter if provided, otherwise use the state
        const pageToUse = pageOverride !== undefined ? pageOverride : interviewRoomsPage;
        const url = `${getApiBaseUrl()}/interviewroom_list?study_id=${studyId}&page=${pageToUse}&per_page=10`;
        const fullUrl = searchValue ? `${url}&name=${encodeURIComponent(searchValue)}` : url;

        setCurrentInterviewRoomsSearch(searchValue);

        const response = await axios.get(fullUrl, { headers: getAuthorizationHeader() });

        let roomsArray = [];
        if (response.data && typeof response.data === "object") {
          if (Array.isArray(response.data)) {
            roomsArray = response.data;
          } else if (response.data.data && Array.isArray(response.data.data)) {
            roomsArray = response.data.data;
          } else if (response.data.items && Array.isArray(response.data.items)) {
            roomsArray = response.data.items;
          } else if (response.data.results && Array.isArray(response.data.results)) {
            roomsArray = response.data.results;
          } else {
            console.log("Unexpected API response structure:", response.data);
          }
        }

        setInterviewRoomsTotal(response.data.total);

        setInterviewRoomsData(roomsArray);
        return roomsArray;
      } catch (error) {
        console.error("Error fetching interview rooms:", error);
        setInterviewRoomsData([]);
      } finally {
        setLoading(false);
      }
    },
    [studyId, interviewRoomsPage]
  );

  const fetchInterviewRoomProjects = useCallback(
    async (searchValue: string, pageOverride?: number) => {
      setLoading(true);
      try {
        // Use the pageOverride parameter if provided, otherwise use the state
        const pageToUse = pageOverride !== undefined ? pageOverride : projectsPage;
        const url = `${getApiBaseUrl()}/interviewroom_projects_list?page=${pageToUse}&per_page=10`;
        const fullUrl = searchValue ? `${url}&project_name=${encodeURIComponent(searchValue)}` : url;

        setCurrentProjectsSearch(searchValue);

        const response = await axios.get(fullUrl, { headers: getAuthorizationHeader() });

        let projectsArray = [];
        if (response.data && typeof response.data === "object") {
          if (Array.isArray(response.data)) {
            projectsArray = response.data;
          } else if (response.data.data && Array.isArray(response.data.data)) {
            projectsArray = response.data.data;
          } else if (response.data.items && Array.isArray(response.data.items)) {
            projectsArray = response.data.items;
          } else if (response.data.results && Array.isArray(response.data.results)) {
            projectsArray = response.data.results;
          } else {
            console.log("Unexpected API response structure:", response.data);
          }
        }

        setProjectsTotal(response.data.total);

        setProjectsData(projectsArray);
        return projectsArray;
      } catch (error) {
        console.error("Error fetching projects:", error);
        setProjectsData([]);
      } finally {
        setLoading(false);
      }
    },
    [projectsPage]
  );

  const debouncedFetchRooms = useMemo(() => {
    return debounce((searchValue: string) => {
      setInterviewRoomsPage(1);
      fetchInterviewRooms(searchValue, 1);
    }, 500);
  }, [fetchInterviewRooms]);

  const debouncedFetchProjects = useMemo(() => {
    return debounce((searchValue: string) => {
      setProjectsPage(1);
      fetchInterviewRoomProjects(searchValue, 1);
    }, 500);
  }, [fetchInterviewRoomProjects]);

  const handleModalOpen = () => {
    setIsModalOpen(true);

    setCurrentProjectsSearch("");
    setCurrentInterviewRoomsSearch("");
    setProjectsPage(1);
    setInterviewRoomsPage(1);

    if (studyId) {
      fetchInterviewRooms("");
    }
    fetchInterviewRoomProjects("");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);

    setCurrentProjectsSearch("");
    setCurrentInterviewRoomsSearch("");
    setProjectsPage(1);
    setInterviewRoomsPage(1);
    if (onClose) onClose();
  };

  const onChange = (key: string) => setActiveTab(key);

  const handleProjectsSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      fetchInterviewRoomProjects("");
    } else {
      debouncedFetchProjects(value);
    }
  };

  const handleInterviewRoomsSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      if (studyId) {
        fetchInterviewRooms("");
      }
    } else {
      debouncedFetchRooms(value);
    }
  };

  const handleInterviewRoomsPaginationChange = (page: number) => {
    setInterviewRoomsPage(page);
    fetchInterviewRooms(currentInterviewRoomsSearch, page);
  };

  const handleProjectsPaginationChange = (page: number) => {
    console.log("handleProjectsPaginationChange", page);
    setProjectsPage(page);
    fetchInterviewRoomProjects(currentProjectsSearch, page);
  };

  useEffect(() => {
    return () => {
      debouncedFetchRooms.cancel();
      debouncedFetchProjects.cancel();
    };
  }, [debouncedFetchRooms, debouncedFetchProjects]);

  const InterviewRoomsTable = React.memo(() => {
    const columns = [
      {
        title: "Room Name",
        dataIndex: "name",
        key: "name",
        sorter: (a: InterviewRoomData, b: InterviewRoomData) => a.name.localeCompare(b.name),
      },
      {
        title: "Project Name",
        dataIndex: "project_name",
        key: "project_name",
        render: (text: string, record: InterviewRoomData) => {
          return record.project_link ? (
            <a href={record.project_link} target="_blank" rel="noreferrer noopener">
              {text}
            </a>
          ) : (
            text || "No project name"
          );
        },
        sorter: (a: InterviewRoomData, b: InterviewRoomData) =>
          (a.project_name || "").localeCompare(b.project_name || ""),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Actions",
        key: "actions",
        render: (_: any, record: InterviewRoomData) => (
          <Button
            key={`select-link-${record.id}`}
            type="primary"
            size="small"
            disabled={!record.links?.moderator}
            onClick={() => {
              if (record.links?.moderator && onSelectLink) {
                onSelectLink(record.links.moderator);
                handleModalClose();
              } else {
                message.warning("No link available for this room");
              }
            }}
          >
            {record.links?.moderator ? "Select Link" : "No Link"}
          </Button>
        ),
      },
    ];

    return (
      <>
        <div
          style={{
            marginBottom: 24,
            padding: 16,
            border: "1px solid #f0f0f0",
            borderRadius: 4,
            backgroundColor: "#f2f3f5",
          }}
        >
          <h4 style={{ marginTop: 0, marginBottom: 10 }}>Search for an existing interview room</h4>
          <div className="search-container" style={{ marginBottom: 0 }}>
            <Input
              placeholder="Search interview rooms..."
              onChange={handleInterviewRoomsSearch}
              defaultValue={currentInterviewRoomsSearch}
              prefix={<Icon icon="mdi:magnify" width={24} height={24} />}
              allowClear
            />
          </div>
        </div>
        <Table
          dataSource={interviewRoomsData}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            total: interviewRoomsTotal,
            current: interviewRoomsPage,
            onChange: handleInterviewRoomsPaginationChange,
          }}
          locale={{
            emptyText: currentInterviewRoomsSearch ? "No interview rooms found" : "Search for interview rooms above",
          }}
        />
      </>
    );
  });

  InterviewRoomsTable.displayName = "InterviewRoomsTable";

  const ProjectsTable = () => {
    const [newProjectName, setNewProjectName] = useState("");
    const [creatingProject, setCreatingProject] = useState(false);

    const handleCreateProject = async () => {
      if (!newProjectName.trim()) {
        message.error("Project name cannot be empty");
        return;
      }

      setCreatingProject(true);
      try {
        const response = await axios.post(
          `${getApiBaseUrl()}/interviewroom_create_project`,
          {
            project_name: newProjectName,
            rooms: [{ name: newProjectName }],
          },
          { headers: getAuthorizationHeader() }
        );

        message.success("Project and interview room created successfully");

        // Store the current project name before clearing input
        const createdProjectName = newProjectName;
        setNewProjectName("");

        // Get the moderator link from the response
        const responseData = response.data;
        const moderatorLink = responseData?.rooms?.[0]?.links?.moderator || null;

        if (moderatorLink && onSelectLink) {
          // Pass the moderator link to parent component and close modal
          onSelectLink(moderatorLink);
          handleModalClose();
        } else {
          // If no moderator link or no onSelectLink, just refresh the projects list
          setCurrentProjectsSearch(createdProjectName);
          fetchInterviewRoomProjects(createdProjectName);

          if (!moderatorLink) {
            console.warn("Moderator link not found in response:", responseData);
          }
        }
      } catch (error) {
        console.error("Error creating project:", error);
        message.error("Failed to create project");
      } finally {
        setCreatingProject(false);
      }
    };

    const columns = [
      {
        title: "Project Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        sorter: (a: ProjectData, b: ProjectData) => a.campaign_name.localeCompare(b.campaign_name),
      },
      {
        title: "Created Date",
        dataIndex: "created",
        key: "created",
        render: (text: string) => {
          const date = new Date(text);
          return date.toLocaleDateString() + " " + date.toLocaleTimeString();
        },
        sorter: (a: ProjectData, b: ProjectData) => new Date(a.created).getTime() - new Date(b.created).getTime(),
      },
      {
        title: "Created By",
        dataIndex: "created_by_email",
        key: "created_by_email",
        sorter: (a: ProjectData, b: ProjectData) => a.created_by_email.localeCompare(b.created_by_email),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_: any, record: ProjectData) => {
          const defaultRoomName = `${record.campaign_name} Room`;

          const togglePopover = (visible: boolean) => {
            if (popoverVisible[record.id] !== visible) {
              setPopoverVisible(prev => ({
                ...prev,
                [record.id]: visible,
              }));

              if (visible && !roomNames[record.id]) {
                setRoomNames(prev => ({
                  ...prev,
                  [record.id]: defaultRoomName,
                }));
              }
            }
          };

          const PopoverContent = React.memo(() => {
            const [localName, setLocalName] = useState(roomNames[record.id] || defaultRoomName);

            const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              setLocalName(e.target.value);
            };

            const handleCreateRoom = () => {
              setRoomNames(prev => ({
                ...prev,
                [record.id]: localName,
              }));
              createRoom(record.id, localName || defaultRoomName);
              togglePopover(false);
            };

            return (
              <div key={`popover-content-${record.id}`} style={{ padding: "8px 0" }} onClick={e => e.stopPropagation()}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Input
                    key={`room-name-input-${record.id}`}
                    placeholder="Enter room name"
                    value={localName}
                    onChange={handleInputChange}
                    style={{ width: 240 }}
                    onPressEnter={handleCreateRoom}
                    autoFocus
                  />
                  <Space>
                    <Button key="create" type="primary" size="small" onClick={handleCreateRoom}>
                      Create
                    </Button>
                    <Button key="cancel" size="small" onClick={() => togglePopover(false)}>
                      Cancel
                    </Button>
                  </Space>
                </Space>
              </div>
            );
          });

          PopoverContent.displayName = `PopoverContent-${record.id}`;

          return (
            <Popover
              content={<PopoverContent />}
              title="Enter Room Name"
              trigger="click"
              open={popoverVisible[record.id]}
              onOpenChange={togglePopover}
              destroyTooltipOnHide={false}
              getPopupContainer={triggerNode => triggerNode.parentElement || document.body}
            >
              <Button
                key={`create-room-${record.id}`}
                type="primary"
                icon={<PlusOutlined />}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  togglePopover(true);
                }}
              >
                Create Room
              </Button>
            </Popover>
          );
        },
      },
    ];

    return (
      <>
        <div
          className="create-project-container"
          style={{
            marginBottom: 24,
            padding: 16,
            border: "1px solid #f0f0f0",
            borderRadius: 4,
            backgroundColor: "#f2f3f5",
          }}
        >
          <h4 style={{ marginTop: 0, marginBottom: 10 }}>Create New Project</h4>
          <div style={{ display: "flex", width: "100%" }}>
            <Input
              placeholder="Project Name"
              value={newProjectName}
              onChange={e => setNewProjectName(e.target.value)}
              disabled={creatingProject}
              style={{ flex: "1 1 auto", marginRight: 8 }}
              onPressEnter={handleCreateProject}
            />
            <Button
              type="primary"
              onClick={handleCreateProject}
              loading={creatingProject}
              disabled={!newProjectName.trim()}
            >
              Create Project
            </Button>
          </div>
        </div>

        <div
          style={{
            marginBottom: 24,
            padding: 16,
            border: "1px solid #f0f0f0",
            borderRadius: 4,
            backgroundColor: "#f2f3f5",
          }}
        >
          <h4 style={{ marginTop: 0, marginBottom: 10 }}>Add to an existing project</h4>
          <div className="search-container" style={{ marginBottom: 0 }}>
            <Input
              placeholder="Search projects..."
              onChange={handleProjectsSearch}
              defaultValue={currentProjectsSearch}
              prefix={<Icon icon="mdi:magnify" width={24} height={24} />}
              allowClear
            />
          </div>
        </div>
        <Table
          dataSource={projectsData}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            total: projectsTotal,
            current: projectsPage,
            onChange: handleProjectsPaginationChange,
          }}
          locale={{
            emptyText: currentProjectsSearch ? "No projects found" : "Search for projects above",
          }}
        />
      </>
    );
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "2",
      label: "Projects",
      children: (
        <div className="tab-content">
          <ProjectsTable />
        </div>
      ),
    },
    {
      key: "1",
      label: "Interview Rooms",
      children: (
        <div className="tab-content">
          <InterviewRoomsTable />
        </div>
      ),
    },
  ];

  const createRoom = async (projectId: number, roomName: string) => {
    if (!roomName.trim()) {
      message.error("Room name cannot be empty");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(
        `${getApiBaseUrl()}/interviewroom_add_rooms_to_project`,
        {
          question_id: projectId,
          rooms: [{ name: roomName }],
        },
        { headers: getAuthorizationHeader() }
      );

      message.success("Interview room created successfully");

      const responseData = response.data;
      let moderatorLink = extractModeratorLink(responseData);

      if (moderatorLink && onSelectLink) {
        onSelectLink(moderatorLink);
        handleModalClose();
      } else {
        console.warn("Moderator link not found in response:", responseData);

        if (studyId) {
          fetchInterviewRooms("");
        }
        setActiveTab("2");
      }

      return response.data;
    } catch (error) {
      console.error("Error creating interview room:", error);
      message.error("Failed to create interview room");
    } finally {
      setLoading(false);
    }
  };

  const extractModeratorLink = (responseData: any): string | null => {
    if (responseData?.rooms?.[0]?.links?.moderator) {
      return responseData.rooms[0].links.moderator;
    } else if (responseData?.data?.rooms?.[0]?.links?.moderator) {
      return responseData.data.rooms[0].links.moderator;
    } else if (responseData?.links?.moderator) {
      return responseData.links.moderator;
    }
    return null;
  };

  return (
    <StyledContainer className={classNames("search-interview-rooms-projects", className)} style={style}>
      <Button
        type="link"
        onClick={handleModalOpen}
        disabled={disabled}
        style={{ padding: 0, marginBottom: 10, height: "auto" }}
      >
        Host with Voxpopme
      </Button>

      <Modal
        title="Search Interview Rooms & Projects"
        open={isModalOpen}
        onCancel={handleModalClose}
        width={1000}
        footer={null}
      >
        <Tabs defaultActiveKey="2" activeKey={activeTab} onChange={onChange} items={tabItems} />
      </Modal>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .tab-content {
    padding: 16px;
    min-height: 300px;
  }

  .ant-tabs-nav {
    margin-bottom: 16px;
  }

  .search-container {
    margin-bottom: 16px;
  }
`;

export default SearchInterviewRoomsProjects;
