/**
 * @generated SignedSource<<003c0dc295bc0b64c455fbfa54c436ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenersStudyEventsTypeChoices = "SESSIONS" | "SINGLE" | "%future added value";
export type ScreenersStudyStatusChoices = "CM" | "DR" | "LV" | "%future added value";
export type ScreenersStudyTypeChoices = "OO" | "SS" | "SY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SessionsForm_study$data = {
  readonly approvedCount: number | null;
  readonly canHaveMultipleSessions: boolean | null;
  readonly dId: any | null;
  readonly eventsType: ScreenersStudyEventsTypeChoices;
  readonly id: string;
  readonly sessions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly availabilitySlots: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly availabilityBuffer: number | null;
              readonly duration: number | null;
              readonly end: any;
              readonly id: string;
              readonly inPerson: boolean;
              readonly interviewer: {
                readonly email: string;
              } | null;
              readonly meetingDetails: string | null;
              readonly meetingLink: string | null;
              readonly meetingLocation: string | null;
              readonly placesLimit: number | null;
              readonly start: any;
              readonly " $fragmentSpreads": FragmentRefs<"SessionsForm_FocusGroupSlotEditor_slot" | "SessionsForm_InterviewSlotEditor_slot">;
            } | null;
          } | null>;
        };
        readonly countOfBookedSlots: number | null;
        readonly duration: number | null;
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly status: ScreenersStudyStatusChoices;
  readonly tenant: {
    readonly vpmAccountId: number | null;
  };
  readonly type: ScreenersStudyTypeChoices;
  readonly voxpopmeRecorderEnabled: boolean;
  readonly " $fragmentType": "SessionsForm_study";
};
export type SessionsForm_study$key = {
  readonly " $data"?: SessionsForm_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"SessionsForm_study">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SessionsForm_study",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventsType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vpmAccountId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canHaveMultipleSessions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudySessionNodeConnection",
      "kind": "LinkedField",
      "name": "sessions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudySessionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StudySessionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "countOfBookedSlots",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StudyAvailabilitySlotNodeConnection",
                  "kind": "LinkedField",
                  "name": "availabilitySlots",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudyAvailabilitySlotNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StudyAvailabilitySlotNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "start",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "end",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "availabilityBuffer",
                              "storageKey": null
                            },
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "inPerson",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "meetingLink",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "meetingLocation",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "meetingDetails",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "placesLimit",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "InterviewerNode",
                              "kind": "LinkedField",
                              "name": "interviewer",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "email",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "SessionsForm_FocusGroupSlotEditor_slot"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "SessionsForm_InterviewSlotEditor_slot"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voxpopmeRecorderEnabled",
      "storageKey": null
    }
  ],
  "type": "StudyNode",
  "abstractKey": null
};
})();

(node as any).hash = "2a1d765fbbb48f6c1489a24db9d22cae";

export default node;
